import { Icon } from "@chakra-ui/react";

function EducationIcon(props) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        d="M5 13.18V17.18L12 21L19 17.18V13.18L12 17L5 13.18ZM12 3L1 9L12 15L21 10.09V17H23V9L12 3Z"
        fill="black"
        fillOpacity="0.8"
      />
    </Icon>
  );
}
export default EducationIcon;
import { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Button,
  Text,
  Textarea,
  useToast,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";

import {db} from '../../../../firebase'
import {doc,updateDoc,increment,setDoc, deleteField} from "firebase/firestore"
import { amplitude } from "../../../../analytics/analytics";

function Upcomingcancel({ open, setopen, data, user }) {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [value, setvalue] = useState("");
  const [error, seterror] = useState(false);
  const [loading, setloading] = useState(false)
  useEffect(() => {
    if (open) {
      onOpen(true);
    }
  }, [open, onOpen]);

  function handleInputChange(e) {
    const regex = /[a-zA-Z]/;
    var inputValue = e.target.value;
    const doesItHaveLetter = regex.test(inputValue);
    if (doesItHaveLetter) {
      seterror(false);
      setvalue(inputValue);
    } else {
      setvalue("");
    }
  }

  function cancelEvent(){
    
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        eventId: data.meetingId,
      }),
    };
    fetch(
      "https://us-central1-pearmock-f75c3.cloudfunctions.net/addEventToCalendar/deleteEventFromCalendar",
      requestOptions
    )
      .then((response) => {})
      .then(() => {
        const cancelInfo = {
          eventStatus: 'past',
          past: 'cancelled',
          canceledAt: new Date(),
          cancellationNote: value,
          canceledBy: (data.creatorUid === user.uid)?'creator':'partner'
        }
        updateDoc(doc(db,'users',user.uid),{
          cancelledMocks: increment(1),
          [`upcomingMocks.${data.eventId}`]: deleteField()
        }).then(()=>{
          const cancel = (data.creatorUid === user.uid)?'creator':'partner'
          const uid = (cancel === 'creator')?data.partnerUid:data.creatorUid
          updateDoc(doc(db,'users',uid),{
            [`upcomingMocks.${data.eventId}`]: deleteField()
          }).then(()=>{
            setDoc(doc(db,'events',data.eventId),{
              ...cancelInfo
            },{merge:true}).then(()=>{
              amplitude.track('upcoming mock cancelled',data.eventId)
              onClose(true);
              toast({
                title: 'Event Cancelled',
                status: 'success',
                duration: 2000,
                isClosable: true,
              })
            })
          })
        })
      });
  }

  return (
    <>
      <Modal onClose={onClose} isOpen={isOpen} isCentered size={"lg"}>
        <ModalOverlay />
        <ModalContent borderRadius={'16px'}>
          <ModalHeader>Cancel upcoming mock</ModalHeader>
          <ModalBody>
            <Text
              mt={"-15px"}
              fontWeight={"400"}
              fontSize={"14px"}
              lineHeight={"20px"}
              color={"gray.500"}
            >
              Cancelling a scheduled mock interview disturbs your partner’s
              practice schedule and reduces the reliability on your profile.
            </Text>
            <Text
              mt={"16px"}
              mb={"8px"}
              fontWeight={"500"}
              fontSize={"14px"}
              lineHeight={"20px"}
              color={"black"}
            >
              Cancellation reason
            </Text>
            <FormControl isInvalid={error}>
              <Textarea
                value={value}
                onChange={handleInputChange}
                placeholder="Why are you cancelling this mock interview?"
                size="md"
              />
              {error ? (
                <FormErrorMessage>
                  Please enter a cancellation reason
                </FormErrorMessage>
              ) : null}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                onClose(true);
                setopen(false);
              }}
              variant={"ghost"}
              colorScheme={"gray"}
              size={"sm"}
              border={"1px solid #E2E8F0"}
              mr={"12px"}
              display={loading?'none':'block'}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (value === "") {
                  seterror(true);
                } else {
                  setloading(true)
                  cancelEvent()
                }
              }}
              size={"sm"}
              isLoading={loading}
            >
              Cancel mock inteview
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      
    </>
  );
}

export default Upcomingcancel;

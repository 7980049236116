import {Menu,MenuButton,Avatar,MenuList,Button} from '@chakra-ui/react'
function ProfileObj({children,imageUrl,name}) {
  return (
    <Menu>
      <MenuButton
        as={Button}
        rounded={"full"}
        variant={"link"}
        cursor={"pointer"}
        minW={0}
        mr={5}
      >
        <Avatar size={"md"} src={imageUrl} name={name}/>
      </MenuButton>
      <MenuList p={0} minW="0" w={"154px"}>
        {children}
      </MenuList>
    </Menu>
  );
}

export default ProfileObj;

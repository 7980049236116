import React from 'react';
import { Box, VStack, HStack, Text, Container, Circle, SimpleGrid, Image, Heading, Flex } from '@chakra-ui/react';
import Tata from '../../static/images/tata1mg.svg';
import Accenture from '../../static/images/accenture.svg';
import Growtomation from '../../static/images/growtomation.svg';
import Blinkit from '../../static/images/blinkit.svg';
import Limechat from '../../static/images/limechat.svg';
import Magicbricks from '../../static/images/magicbricks.png';

const stepsData = [
    { number: '1', title: 'Make it real', description: 'Find a partner and join a 90 minute mock interview' },
    { number: '2', title: 'Practice skills', description: 'Spend the first 40 minutes solving a problem' },
    { number: '3', title: 'Learn and grow', description: 'Discuss and receive feedback on your solution' },
    { number: '4', title: 'Your turn to ask', description: 'Your partner solves a problem in the next 40 minutes' },
    { number: '5', title: 'Give feedback', description: 'Provide feedback on your partner’s solution' },
];
const companiesList = [
    { name: 'Tata', img: Tata },
    { name: 'Accenture', img: Accenture },
    { name: 'Growtomation', img: Growtomation },
    { name: 'Blinkit', img: Blinkit },
    { name: 'Limechat', img: Limechat },
    { name: 'Magicbricks', img: Magicbricks },

]
const Step = ({ number, title, description }) => {
    return (
        <Flex
            bg="#FFFBAD"
            p={'20px 32px'}
            borderRadius="16px"
            maxW={{ base: "100%", md: "360px" }}
            minW={{ base: "100%", md: "360px" }}
            minHeight="100px"
            shadow="md"
            fontFamily={`'Manrope', sans-serif`}
            flexDir={'column'}
        >
            <HStack
                alignItems={'center'}
                gap={2}
                w={'100%'}
            >
                <Circle
                    bg={'#CBE81B'}
                    w={'40px'}
                    h={'40px'}

                >

                    <Text
                        fontSize="xl"
                        fontWeight="bold"
                        color={'#525C0C'}
                    >
                        {number}
                    </Text>
                </Circle>
                <Text

                    fontSize={{ base: '28px', md: '32px' }}
                    color={'#4D570D'}
                    fontWeight={500}
                >
                    {title}

                </Text>

            </HStack>
            <Text
                color='#515A19'
                fontSize='20px'
                fontWeight={500}
            >
                {description}
            </Text>
        </Flex>
    );
};

const CompainesSection = () => (
    <Box
        flex={1}
        px={{ base: '20px', md: 4 }}
    >
        <Heading
            fontSize={{ base: '32px', md: '52px' }}
            fontWeight={700}
            textAlign={{base:'left',md:'center'}}
            p={0}
            mb={'40px'}
        >
            Join mock interview partners from top companies
        </Heading>
        <Container maxW="container.xl" centerContent >
            <Box py="4"  >
                <SimpleGrid
                    columns={{ base: 2, md: 3, lg: 6 }}
                    spacing="8"
                    py="4"
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    {
                        companiesList.map((company, index) => (
                            <Image
                                key={`${company.name}-${index}`}
                                src={company.img}
                                alt={company.name}
                            />
                        ))
                    }
                </SimpleGrid>
            </Box>
        </Container>
    </Box>
)

const FindPartnerSection = () => {


    return (
        <Flex
            direction={'column'}
            minH={'100vh'}
            my={{base:'90px',md:'136px'}}
            gap={{base:'90px', md:'130px'}}
        >
            <CompainesSection />
            <Box
                bg={'#E06060'}
                flex={3}
                py={'76px'}
                mx={{ base: 0, md: '39px' }}
                borderRadius={'24px'}
            >
                <Box p={{ base: 2, md: 4 }} w="full" textAlign={{base:"left", md:"center" }} mb={'60px'} px={'20px'}>
                    <Text fontSize={{ base: '30px', md: '48px' }} color="white" fontWeight={700} >
                        Find partners, schedule interviews, and start practising!
                    </Text>
                </Box>
                <Container
                    maxW="container.xl"
                    centerContent
                >

                        <Flex
                            wrap="wrap"
                            justifyContent="center"
                            alignItems={'flex-start'}
                            gap={5}
                        >
                            {stepsData.map((step) => (
                                <Step key={step.number} {...step} />
                            ))}
                        </Flex>
                </Container>
            </Box>
        </Flex>

    );
};

export default FindPartnerSection;

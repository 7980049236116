import React from 'react'
import { Flex, Image } from '@chakra-ui/react'
import OverViewLaptop from '../../static/images/overviewLaptop.svg'
export default function Overview() {
  return (
    <Flex
        p={10}
        alignItems={'center'}
        justifyContent={'center'}
    >
        <Image
            src={OverViewLaptop}
            alt="overview laptop"
        />

    </Flex>
  )
}

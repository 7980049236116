import { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  FormControl,
  Text,
  useCheckboxGroup
} from "@chakra-ui/react";
import CheckboxComponent from '../../common/CheckboxComponent'
import Joinconfirm from "./Joinconfirm";
import { useNavigate } from "react-router-dom";


function Joinmodal({ IsOpenfunc, IsOpen, data ,user, userdata}) {
  const navigate = useNavigate()
  const { value, getCheckboxProps } = useCheckboxGroup();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isOpen2, setisOpen2] = useState(false);
  const isInvalid = value.length === 0
  useEffect(() => {
    if (IsOpen) {
      onOpen(true);
    }
  }, [IsOpen, onOpen]);

  const youTake = {
    caseType: data.creatorCaseType
  }

  const info = {
    modalClose: onClose,
    isOpenfunc: setisOpen2,
    isOpen2: isOpen2,
    youPractice: {
      caseType: value,
    },
    youTake: youTake,
    datetime: data.eventDateTime.toDate(),
    name: `${data.creatorFirstName} ${data.creatorLastName}`,
    data: data,
    user: user,
    userdata: userdata
  };

  function create() {
    setisOpen2(true);
  }

  function creatorCaseString(){
    const string = data.creatorCaseType.map((casetype,index)=>{
      if(index+1 === data.creatorCaseType.length){
        return `${casetype}`
      }
      else if(index+2 === data.creatorCaseType.length){
        return `${casetype},`
      }
      else{
        return `${casetype},`
      }
    })

    return string.join(' ')
  }

  function PartnerCase({willTakeType}){    
      return <CheckboxComponent cases={willTakeType} func={getCheckboxProps} size={"sm"}/>
  }

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size={"lg"}
        boxShadow={
          "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
        }
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent borderRadius={"16px"}>
          <ModalHeader>
            <FormControl
              fontSize={"24px"}
              lineHeight={"32px"}
              fontWeight={700}
              color={"gray.900"}
            >
              Join mock interview
            </FormControl>
          </ModalHeader>

          <ModalCloseButton onClick={() => IsOpenfunc(false)} />
          <ModalBody>
            {data.creatorCaseType.length === 1 ? (
              <Text
                fontSize={"14px"}
                fontWeight={"400"}
                lineHeight={"20px"}
                color={"gray.600"}
              >
                Your partner wants to practice a{" "}
                <Text as={"b"}>{data.creatorCaseType} case.</Text> You need to
                prepare a problem statement for this case type before the mock interview.{" "}
              </Text>
            ) : (
              <Text
                fontSize={"14px"}
                fontWeight={"400"}
                lineHeight={"20px"}
                color={"gray.600"}
              >
                Your partner wants to practice any one out of{" "}
                <Text as={"b"}>{creatorCaseString()} cases.</Text> You need to
                prepare any one out of these cases before the mock interview.{" "}
              </Text>
            )}

            <Text
              mt={"10px"}
              mb={"18px"}
              fontSize={"14px"}
              fontWeight={"400"}
              lineHeight={"20px"}
              color={"gray.600"}
            >
              As an interviewer, your partner can take any one of the following
              cases with you. Please select all the cases you want to practice:
            </Text>
            <PartnerCase willTakeType={data.willTakeType} />
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                if (data.referred == true) {
                  navigate("/dashboard", { replace: true });
                  navigate(0);
                }
                onClose(true);
                IsOpenfunc(false);
              }}
              mr={"12px"}
              size={"sm"}
              variant={"ghost"}
              color={"black"}
              border={"1px solid #E2E8F0"}
              w={"72px"}
              h={"32px"}
            >
              Cancel
            </Button>
            <Button
              onClick={create}
              size={"sm"}
              w={"111px"}
              h={"32px"}
              isDisabled={isInvalid}
            >
              Continue
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isOpen2 && <Joinconfirm {...info} />}
    </>
  );
}

export default Joinmodal;

import { useEffect, useState } from "react";
import { CheckIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  HStack,
  Circle,
  VStack,
  Box,
  Button,
  useDisclosure,
  Checkbox,
  Spacer
} from "@chakra-ui/react";

import Cratemock from "./Cratemock";

function InfoModal({userdata,userUid,isOpen, onClose}) {

  const { isOpen:createisOpen, onOpen:createonOpen, onClose:createonClose } = useDisclosure()
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const createTnC = localStorage.getItem("createTnC")
    if(createTnC == null){
        localStorage.setItem("createTnC",false)
    }
    else{
        if(createTnC == "true"){
            createonOpen(true)
            onClose(true)
        }
    }
  }, [isOpen])

  useEffect(() => {
    const storedValue = localStorage.getItem('createTnC');
    if (storedValue === 'true') {
      setIsChecked(true);
    }
  }, []);
  
  const handleChange = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked);
    localStorage.setItem('createTnC', checked);
  };

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        blockScrollOnMount={true}
        closeOnOverlayClick={false}
        size={"lg"}
        boxShadow={
          "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
        }
      >
        <ModalOverlay />
        <ModalContent borderRadius={"16px"}>
          <ModalHeader>
            <Text
              color={"gray.900"}
              fontSize={"24px"}
              lineHeight={"32px"}
              fontWeight={"700"}
            >
              Welcome to the Pearmock party!
            </Text>
          </ModalHeader>
          <ModalCloseButton size={"sm"} />
          <ModalBody>
            <Box mb={"24px"}>
              <HStack>
                <Circle
                  w={"24px"}
                  h={"24px"}
                  bg={"green.500"}
                  color={"white"}
                  mr={"12px"}
                >
                  <CheckIcon />
                </Circle>
                <Text
                  fontSize={"16px"}
                  lineHeight={"24px"}
                  fontWeight={"500"}
                  color={"black"}
                >
                  Create or Join mock interview slots for free
                </Text>
              </HStack>
              <HStack mt={"18px"}>
                <Circle
                  w={"24px"}
                  h={"24px"}
                  bg={"green.500"}
                  color={"white"}
                  mr={"12px"}
                >
                  <CheckIcon />
                </Circle>
                <Text
                  fontSize={"16px"}
                  lineHeight={"24px"}
                  fontWeight={"500"}
                  color={"black"}
                >
                  Each interview is of 90 minutes
                </Text>
              </HStack>
              <HStack mt={"18px"}>
                <Circle
                  w={"24px"}
                  h={"24px"}
                  bg={"green.500"}
                  color={"white"}
                  mr={"12px"}
                >
                  <CheckIcon />
                </Circle>
                <Text
                  fontSize={"16px"}
                  lineHeight={"24px"}
                  fontWeight={"500"}
                  color={"black"}
                >
                  Partners switch roles and take 40 minute interview followed by
                  5 minute analysis (45 x 2 = 90 min)
                </Text>
              </HStack>
              <HStack mt={"18px"}>
                <Circle
                  w={"24px"}
                  h={"24px"}
                  bg={"green.500"}
                  color={"white"}
                  mr={"12px"}
                >
                  <CheckIcon />
                </Circle>
                <Text
                  fontSize={"16px"}
                  lineHeight={"24px"}
                  fontWeight={"500"}
                  color={"black"}
                >
                  Use our resources or find cases online and come prepared with
                  1 case to ask your partner.
                </Text>
              </HStack>
              <HStack mt={"18px"}>
                <Circle
                  w={"24px"}
                  h={"24px"}
                  bg={"green.500"}
                  color={"white"}
                  mr={"12px"}
                >
                  <CheckIcon />
                </Circle>
                <Text
                  fontSize={"16px"}
                  lineHeight={"24px"}
                  fontWeight={"500"}
                  color={"black"}
                >
                  You{" "}
                  <Text as={"span"} color={"#E06060"}>
                    earn 20 points
                  </Text>{" "}
                  for each interview you complete. Earn points, level up & land
                  interviews at top global companies!
                </Text>
              </HStack>
            </Box>
          </ModalBody>
          <ModalFooter>
            <HStack w={"full"}>
              <Checkbox isChecked={isChecked} onChange={handleChange}>
                Don't show this again
              </Checkbox>
              <Spacer/>
              <Button
                size="sm"
                onClick={() => {
                  createonOpen(true);
                  onClose(true);
                }}
              >
                Understood
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/*create modal open*/}
      <Cratemock
        userdata={userdata}
        userUid={userUid}
        onOpen={createonOpen}
        onClose={createonClose}
        isOpen={createisOpen}
      />
    </>
  );
}

export default InfoModal
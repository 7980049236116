import {useState} from "react";
import { Input } from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
function Rating({rating,setrating}) {

  const [hover, sethover] = useState(null);
  return (
    <div>
      {[...Array(5)].map((star , i) => {
        const ratingValue = i+1;
        return (
          <label key={i}>
            <Input
              type={"radio"}
              display={"none"}
              value={ratingValue}
              onClick={() => setrating(ratingValue)}
            />
            <StarIcon
              color={ratingValue <= (hover || rating) ? "yellow.300" : "gray.300"}
              mr={"5px"}
              cursor={"pointer"}
              onMouseEnter={()=>sethover(ratingValue)}
              onMouseLeave={()=>sethover(null)}
            />
          </label>
        );
      })}
    </div>
  );
}

export default Rating;

import { useEffect } from "react";
import { CheckIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Circle,
  HStack,
  Text,
  Box,
} from "@chakra-ui/react";
import Time from "../common/Time";
function ConfirmModal({
  isOpen2,
  youPractice,
  youTake,
  datetime,
  setfirstcasetype,
  setsecondcasetype,
  setdate,
  settime,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    if (isOpen2) {
      onOpen(true);
    }
  }, [isOpen2, onOpen]);

  const timeprops = {
    datetime: datetime,
  };

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        blockScrollOnMount={true}
        closeOnOverlayClick={false}
        size={"md"}
        borderRadius={'16px'}
        boxShadow={
          "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
        }
      >
        <ModalOverlay />
        <ModalContent borderRadius={'16px'}>
          <ModalHeader>
            <HStack>
              <Circle w={10} h={10} bg={"green.500"} color={"white"} mr={'8px'}>
                <CheckIcon />
              </Circle>
              <Text fontSize={"24px"} lineHeight={"32px"} fontWeight={700}>
                Mock created!
              </Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Text fontSize={"20px"} lineHeight={"28px"} fontWeight={600}>
                Interviewee cases
              </Text>
              <Box>
                {youPractice.caseType.map((doc, index) => {
                  return index + 1 === youPractice.caseType.length ? (
                    <Text
                      as={"span"}
                      key={doc}
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      fontWeight={"400"}
                      color={"gray.800"}
                    >
                      {doc}
                    </Text>
                  ) : (
                    <Text
                      as={"span"}
                      key={doc}
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      fontWeight={"400"}
                      color={"gray.800"}
                    >
                      {doc},{" "}
                    </Text>
                  );
                })}
              </Box>
            </Box>
            {/*Yoou will practice box*/}
            <Box my={"24px"}>
              <Text fontSize={"20px"} lineHeight={"28px"} fontWeight={600}>
                Interviewer cases
              </Text>
              <Box>
                {youTake.caseType.map((doc, index) => {
                  return index + 1 === youTake.caseType.length ? (
                    <Text
                      as={"span"}
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      key={doc}
                      fontWeight={"400"}
                      color={"gray.800"}
                    >
                      {doc}
                    </Text>
                  ) : (
                    <Text
                      as={"span"}
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      key={doc}
                      fontWeight={"400"}
                      color={"gray.800"}
                    >
                      {doc},{" "}
                    </Text>
                  );
                })}
              </Box>
            </Box>
            <HStack>
              <Time {...timeprops} />
            </HStack>

            <Box my={"24px"}>
              <Text fontSize={"14px"} lineHeight={"20px"}>
                You will receive a calendar invitation as soon as a partner
                joins this mock session. Please be on time for the session and{" "}
                <Text as={"u"}>don’t forget to prepare a case </Text> for your
                partner before the session! All the best and we hope you have an
                awesome mock interview. 🚀
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                onClose(true);
                setfirstcasetype([]);
                setsecondcasetype([]);
                setdate("");
                settime("");
              }}
            >
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ConfirmModal;

import { Icon } from "@chakra-ui/react";

export const ClockIcon = (props) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      d="M10.0001 18.3337C5.39758 18.3337 1.66675 14.6028 1.66675 10.0003C1.66675 5.39783 5.39758 1.66699 10.0001 1.66699C14.6026 1.66699 18.3334 5.39783 18.3334 10.0003C18.3334 14.6028 14.6026 18.3337 10.0001 18.3337ZM10.8334 10.0003V5.83366H9.16675V11.667H14.1667V10.0003H10.8334Z"
      fill="#2D3748"
    />
  </Icon>
);

import {useState,useEffect} from "react";
import Authenticated from "../components/AppBar/Authenticated";
import {useAuth} from '../context/AuthContext'
import Loader from "../static/Loader";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Text,
  Skeleton,
  Box,
  useToast,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import baseUrl from "../constants/urls";
import {useNavigate} from 'react-router-dom'
import {db} from '../firebase'
import {collection, doc, setDoc,onSnapshot,query,orderBy} from 'firebase/firestore'
import NoteCard from "../components/notes/NoteCard";
import draftToHtml from 'draftjs-to-html';
import Editor from "cuvette-text-editor";

function Notes() {
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {currentUser,user,userStatus} = useAuth()
  const [loading, setloading] = useState(true);
  const [buttonLoad, setbuttonLoad] = useState(false)
  const [editorValue, setEditorValue] = useState();
  const [desc, setdesc] = useState("")
  const [notesData, setnotesData] = useState([])
  const [noteload, setnoteload] = useState(false)
  const [noteTitle, setnoteTitle] = useState("")
  const maxchar = 2000
  const [charNum, setcharNum] = useState(maxchar)
  const toast = useToast() 

  useEffect(() => {
    if (currentUser !== null && user !== null && userStatus === 'verified') {
      setloading(false);
      const noteRef = collection(db,'users',currentUser.uid,'notes')
      const q = query(noteRef,orderBy('updatedAt','desc'))
      onSnapshot(q, (querySnap) => {
        setnotesData(
          querySnap.docs.map((doc) => {
            return doc.data();
          })
        );
        setnoteload(true)
      });
    }
    else if(!localStorage.getItem('user')){
      navigate(`${baseUrl}`,{replace:true})
    }

  }, [currentUser, user, userStatus]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Authenticated setloading={setloading} />
          <Button
            size={"sm"}
            mt={"64px"}
            ml={{base:"32px",md:"100px",lg:"200px"}}
            mb={"32px"}
            onClick={onOpen}
          >
            Add a new note
          </Button>
          {notesData.length ? (
            <Wrap
              spacing={"24px"}
              ml={{base:"32px",md:"100px",lg:"200px"}}
              mb={"200px"}
            >
              {notesData.map((data) => {
                return (
                  <WrapItem key={data.id}>
                  <NoteCard
                    data={data}
                    key={data.id}
                    currentUser={currentUser}
                  />
                  </WrapItem>
                );
              })}
            </Wrap>
          ) : (
            <>
              <Box
                w={"266px"}
                h={"180px"}
                border={"1px solid #E2E8F0"}
                borderRadius={"8px"}
                p={"16px"}
                display={noteload ? "none" : "block"}
                ml={{base:"32px",md:"100px",lg:"200px"}}
              >
                <Skeleton w={"81px"} h={"20px"} mb={"4px"} />
                <Skeleton w={"234px"} h={"56px"} mb={"8px"} />
                <Skeleton w={"234px"} h={"60px"} />
              </Box>
              {noteload ? (
                <Text ml={{base:"32px",md:"100px",lg:"200px"}}>
                  Start adding notes and Track your progress
                </Text>
              ) : null}
            </>
          )}
          <Modal
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
            isCentered
            size={"xl"}
          >
            <ModalOverlay />
            <ModalContent borderRadius={'16px'}>
              <ModalHeader
                as={Input}
                placeholder={"Title"}
                variant={"unstyled"}
                maxLength={"200"}
                maxW={"532px"}
                onChange={(e) => {
                  setnoteTitle(e.target.value);
                }}
              />
              <ModalCloseButton onClick={()=>{
                setcharNum(20)
                onClose(true)
              }}/>
              <ModalBody maxH={"512px"} overflowY={"scroll"}>
                <Editor
                  onChange={(value) => {
                    if(value !== ""){
                      const html = draftToHtml(JSON.parse(value))
                      const text = html.replace(/<(?:.|\n)*?>/gm, "").replace(/(\r\n|\n|\r)/gm, "").replaceAll("&nbsp;", " ").trim();
                      if(text.length <= maxchar){
                        setcharNum(maxchar-text.length)
                        setEditorValue(value)
                        setdesc(text)
                      }
                      else{
                        setcharNum(maxchar-text.length)
                      }
                    }
                    
                  }}
                  placeholder="Details"
                  hideSeparator={true}
                  className="Editor"
                  hiddenOptions ={["header-one","header-two","code","highlight"]}
                  colors={{primary:"rgba(0, 0, 0, 0.8)"}}
                />
                <Text>{charNum<0?<>Character exceded. This data won't be saved</>:null}</Text>
              </ModalBody>

              <ModalFooter>
                <Button
                  onClick={()=>{
                    setcharNum(20)
                    onClose(true)
                  }}
                  variant={"outline"}
                  colorScheme={"gray"}
                  size={"sm"}
                  mr={"12px"}
                >
                  Cancel
                </Button>
                <Button
                  isLoading={buttonLoad}
                  onClick={() => {
                    setbuttonLoad(true);
                    if(noteTitle.trim() === "" || charNum === maxchar){
                      toast({
                        title: 'No Title or Description added..',
                        status: 'error',
                        isClosable: true,
                        duration: 1000
                      })
                      setbuttonLoad(false);
                    }
                    else if(charNum < 0){
                      toast({
                        title: 'Character Limit exceded',
                        status: 'error',
                        isClosable: true,
                        duration: 1000
                      })
                      setbuttonLoad(false);
                    }
                    else{
                      const notesRef = doc(
                        collection(db, "users", currentUser.uid, "notes")
                      );
                      setDoc(
                        notesRef,
                        {
                          updatedAt: new Date(),
                          createdAt: new Date(),
                          title: noteTitle,
                          html: editorValue,
                          text: desc,
                          id: notesRef.id,
                        },
                        { merge: true }
                      ).then(() => {
                        setbuttonLoad(false);
                        onClose(true);
                      });
                    }
                  }}
                  size={"sm"}
                >
                  Add
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
}

export default Notes;

import { Flex } from '@chakra-ui/react'
import SingeUserSkeleton from './SingleUserSkeleton'
export default function LoadingUsersSkeleton() {
    return (
        <Flex
            gap={'24px'}
            flexWrap={'wrap'}
        >
            <SingeUserSkeleton />
            <SingeUserSkeleton />
            <SingeUserSkeleton />
            <SingeUserSkeleton />
            <SingeUserSkeleton />
            <SingeUserSkeleton />
            <SingeUserSkeleton />
            <SingeUserSkeleton />
        </Flex>
    )
}

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css'
import { ChakraProvider,CSSReset } from '@chakra-ui/react'
import theme from './theme/theme'
import {Global,css} from '@emotion/react'
import 'focus-visible/dist/focus-visible';
import AuthContextProvider from './context/AuthContext';
import LogRocket from 'logrocket';
LogRocket.init('kuberanix/pearmock',{
  release: '0.1.0 - Beta Version',
  rootHostname: 'pearmock.com',
  shouldDebugLog: false,
});

const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
     outline: none;
     box-shadow: none;
   }
`;

ReactDOM.render(
  <>
    <CSSReset />
    <ChakraProvider theme={theme}>
      <Global styles={GlobalStyles} />
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </ChakraProvider>
  </>,
  document.getElementById("root")
);


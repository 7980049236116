import {useState} from "react";
import {
  Box,
  Text,
  Button,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Radio,
  RadioGroup,
  FormControl,
  FormErrorMessage,
  HStack,
  Tooltip,
  Avatar
} from "@chakra-ui/react";
import {db,functions} from '../../../firebase'
import {httpsCallable} from 'firebase/functions'
import {doc,setDoc,updateDoc,increment, getDoc} from 'firebase/firestore'
import Rating from '../rightColumn/past/Rating'
import recentmock from "../../../static/images/recentmock.svg";

function PastRecent({ data, user }) {
  const partnerName = data.creatorUid !== user.uid? data.creatorFirstName:data.partnerFirstName;
  const partnerLastName = data.creatorUid !== user.uid ? data.creatorLastName : data.partnerLastName;
  const partnerImage = data.creatorUid === user.uid ? data.partnerAvatarUrl : data.creatorAvatarUrl;
  const partnerEmail = (data.creatorUid === user.uid)?(data.partnerEmail):(data.creatorEmail);
  const datetime = data.eventDateTime.toDate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [completedBy, setcompletedBy] = useState(null);
  const { isOpen:isOpenComplete, onOpen:onOpenComplete, onClose:onCloseComplete } = useDisclosure();
  const { isOpen:isOpenReview, onOpen:onOpenReview, onClose:onCloseReview } = useDisclosure();
  const [value, setValue] = useState(null);
  const [error, seterror] = useState(false);
  const [feedback, setfeedback] = useState(null);
  const [behavior, setbehavior] = useState(null);
  const [punctual, setpunctual] = useState(null);
  const [completeError, setcompleteError] = useState(false);
  const [loading, setloading] = useState(false);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  function getTime(time) {
    const t = time;
    const hour = t.getHours();
    const minute = t.getMinutes();
    if (hour <= 12) {
      if (hour < 10) {
        if (minute < 10) {
          return `0${hour}:0${minute} ${hour === 12 ? "PM" : "AM"}`;
        } else {
          return `0${hour}:${minute} ${hour === 12 ? "PM" : "AM"}`;
        }
      } else {
        if (minute < 10) {
          return `${hour}:0${minute} ${hour === 12 ? "PM" : "AM"}`;
        } else {
          return `${hour}:${minute} ${hour === 12 ? "PM" : "AM"}`;
        }
      }
    } else {
      if (hour - 12 < 10) {
        if (minute < 10) {
          return `0${hour - 12}:0${minute} PM`;
        } else {
          return `0${hour - 12}:${minute} PM`;
        }
      } else {
        if (minute < 10) {
          return `${hour - 12}:0${minute} PM`;
        } else {
          return `${hour - 12}:${minute} PM`;
        }
      }
    }
  }

  function getTagObject(level){
    var obj;
    if(level === 0 || level === 1){
      obj = {
        T1: true,
        T2: true,
        T3: true,
        T4: true,
        T5: true
      }
    }
    else if(level === 2){
      obj = {
        T1: false,
        T2: true,
        T3: true,
        T4: true,
        T5: true
      }
    }
    else if(level === 3){
      obj = {
        T1: false,
        T2: false,
        T3: true,
        T4: true,
        T5: true
      }
    }
    else if(level === 4){
      obj = {
        T1: false,
        T2: false,
        T3: false,
        T4: true,
        T5: true
      }
    }
    else if(level === 5){
      obj = {
        T1: false,
        T2: false,
        T3: false,
        T4: false,
        T5: true
      }
    }

    return obj
  }

  function updateLevel(levelMock){
    if(levelMock === 0){
      return 0;
    }
    else if(levelMock >= 1 && levelMock <= 3){
      return 1;
    }
    else if(levelMock >= 4 && levelMock <= 10){
      return 2;
    }
    else if(levelMock >= 11 && levelMock <= 15){
      return 3;
    }
    else if(levelMock >= 16 && levelMock <= 25){
      return 4;
    }
    else{
      return 5;
    }
  }

  return (
    <>
    <Box
      borderRadius={"24px"}
      w={"328px"}
      h={"264px"}
      p={"24px"}
      backgroundImage={recentmock}
    >
      <Text
        fontSize={"30px"}
        lineHeight={"36px"}
        fontWeight={"700"}
        color={"#4F590B"}
      >
        Review past mock
      </Text>
      <HStack mt={"28px"}>
        <Avatar size={"md"} name={partnerName} src={partnerImage} mr={"16px"} />
        <Box>
          <Text
            fontSize={"18px"}
            lineHeight={"28px"}
            fontWeight={"600"}
            color={"#72820D"}
          >
            {partnerName} {partnerLastName}
          </Text>
          <Text
            fontSize={"18px"}
            lineHeight={"28px"}
            fontWeight={"600"}
            color={"#72820D"}
          >
            {monthNames[datetime.getMonth()]} {datetime.getDate()} @{" "}
            {getTime(datetime)}
          </Text>
        </Box>
      </HStack>
      <HStack h={"32px"} mt={"68px"}>
        <Button size="sm" color={"#EFF8B5"} bg={"#83950F"} mr={"12px"} onClick={onOpenComplete} colorScheme={'secondary'}>
          Mark Complete
        </Button>
        <Button size="sm" variant="unstyled" color={"#83950F"} onClick={onOpen}>
          Mark Incomplete
        </Button>
      </HStack>
    </Box>
    {/* Incomplete Mock Modal */}
    <Modal onClose={onClose} isOpen={isOpen} isCentered size={"lg"}>
        <ModalOverlay />
        <ModalContent borderRadius={'16px'}>
          <ModalHeader>
            Report incomplete mock
            <Text
              fontSize={"14px"}
              lineHeight={"20px"}
              fontWeight={"400"}
              color={"gray.500"}
              mt={"12px"}
            >
              We are sorry to hear your mock interview could not happen. Help us
              in improving your experience on Pearmock by selecting one of the
              following reasons for your mock interview getting canceled.
            </Text>
          </ModalHeader>
          <ModalCloseButton mt={"8px"} />
          <ModalBody>
            <FormControl isInvalid={error}>
              <RadioGroup onChange={setValue} value={value}>
                <Stack direction="column">
                  <Radio
                    value="I could not show up for the mock interview"
                    size={"lg"}
                  >
                    <Text
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      fontWeight={"400"}
                      color={"gray.600"}
                    >
                      I could not show up for the mock interview
                    </Text>
                  </Radio>
                  <Radio
                    value="My partner did not show up for the mock interview"
                    size={"lg"}
                  >
                    <Text
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      fontWeight={"400"}
                      color={"gray.600"}
                    >
                      My partner did not show up for the mock interview
                    </Text>
                  </Radio>
                  <Radio
                    value="We had technical problems during the meeting"
                    size={"lg"}
                  >
                    <Text
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      fontWeight={"400"}
                      color={"gray.600"}
                    >
                      We had technical problems during the meeting
                    </Text>
                  </Radio>
                  <Radio
                    value="We started but the 90 minute meeting duration was not sufficient"
                    size={"lg"}
                  >
                    <Text
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      fontWeight={"400"}
                      color={"gray.600"}
                    >
                      We started but the 90 minute meeting duration was not
                      sufficient
                    </Text>
                  </Radio>
                  <Radio value="Other" size={"lg"}>
                    <Text
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      fontWeight={"400"}
                      color={"gray.600"}
                    >
                      Other
                    </Text>
                  </Radio>
                </Stack>
              </RadioGroup>
              {error ? (
                <FormErrorMessage>
                  Please select a reason for incomplete mock.
                </FormErrorMessage>
              ) : null}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              size={"sm"}
              variant={"outline"}
              colorScheme={"gray"}
              mr={"12px"}
            >
              Cancel
            </Button>
            <Button
              isLoading={loading}
              onClick={() => {
                if (value === null) {
                  seterror(true);
                } else {
                  setloading(true);
                  var obj;
                  if (data.creatorUid === user.uid) {
                    obj = {
                      actionBy: data.creatorUid,
                      past: "notComplete",
                      incompleteNote: value,
                    };
                  } else {
                    obj = {
                      actionBy: data.partnerUid,
                      past: "notComplete",
                      incompleteNote: value,
                    };
                  }
                  setDoc(doc(db, "events", data.eventId), obj, {
                    merge: true,
                  }).then(() => {
                    const sendemail = httpsCallable(functions, "sendemail");
                    sendemail({
                      to: `${partnerEmail}`,
                      subject: "Your Recent Pearmock Interview - Important Update",
                      text: "Your Partner has marked the mock In Complete. You can review the reason and Report if you disagree with the reason",
                      html: `Hey there,
                      <br/>
                      We hope you're doing well. We're reaching out to let you know that your recent mock interview on Pearmock has been marked as incomplete.
                      <br/>
                      We understand that things don't always go as planned. But don't worry, we've got your back. Here's what you can do next:
                      <br/>
                      1. <b>Reach Out:</b> If possible, try reaching out to your partner to understand if there was a misunderstanding or a technical issue.
                      <br/>
                      2. <b>Reschedule:</b> If your partner is unable to complete the session, feel free to schedule a new mock interview with a different partner.
                      <br/>
                      Remember, every bump in the road is a chance to learn and grow. Keep practicing, keep improving, and keep rocking!
                      <br/>
                      If you have any questions or need further assistance, feel free to reply to this email. We're here to help!
                      <br/>
                      Best,
                      <br/>
                      Patrice
                      <br/>
                      Pearmock`,
                    });
                    onClose(true);
                  });
                }
              }}
              size={"sm"}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Complete Mock Modal*/}
      <Modal
        onClose={onCloseComplete}
        isOpen={isOpenComplete}
        isCentered
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent borderRadius={'16px'}>
          <ModalHeader
            borderBottom={"1px solid #E2E8F0"}
          >
            You are practicing hard. That’s great!
            <Text
              fontSize={"14px"}
              lineHeight={"20px"}
              fontWeight={"400"}
              color={"black"}
              mt={"12px"}
            >
              Glad to hear you completed your mock interview. Please rate your
              experience with your peer in order to mark this session as
              complete.
            </Text>
          </ModalHeader>
          <ModalCloseButton mt={"8px"} />
          <ModalBody>
            <Text
              fontSize={"18px"}
              lineHeight={"28px"}
              fontWeight={"600"}
              color={"gray.700"}
              mt={"12px"}
            >
              Rate your interview experience with your peer
            </Text>
            <FormControl isInvalid={completeError}>
              <HStack mt={"12px"}>
                <Tooltip
                  hasArrow
                  label="How meaningful, specific and actionable was the feedback provided by the interviewer?"
                  bg="black"
                  color="white"
                  textAlign={"center"}
                  p={"8px"}
                  w={"320px"}
                  borderRadius={"4px"}
                >
                  <Text
                    as={"u"}
                    fontSize={"16px"}
                    lineHeight={"24px"}
                    fontWeight={"500"}
                    mr={"37px"}
                  >
                    Feedback quality
                  </Text>
                </Tooltip>
                <Rating rating={feedback} setrating={setfeedback} />
              </HStack>
              <HStack mt={"12px"}>
                <Tooltip
                  hasArrow
                  label="How was your peer’s behavior during the mock interview session? Were you respectful?"
                  bg="black"
                  color="white"
                  textAlign={"center"}
                  p={"8px"}
                  w={"320px"}
                  borderRadius={"4px"}
                >
                  <Text
                    as={"u"}
                    fontSize={"16px"}
                    lineHeight={"24px"}
                    fontWeight={"500"}
                    mr={"102px"}
                  >
                    Behavior
                  </Text>
                </Tooltip>

                <Rating rating={behavior} setrating={setbehavior} />
              </HStack>
              <HStack mt={"12px"}>
                <Tooltip
                  hasArrow
                  label="Did your peer join the meeting on time?"
                  bg="black"
                  color="white"
                  textAlign={"center"}
                  p={"8px"}
                  w={"320px"}
                  borderRadius={"4px"}
                >
                  <Text
                    as={"u"}
                    fontSize={"16px"}
                    lineHeight={"24px"}
                    fontWeight={"500"}
                    mr={"84px"}
                  >
                    Punctuality
                  </Text>
                </Tooltip>

                <Rating rating={punctual} setrating={setpunctual} />
              </HStack>
              <Text
                fontSize={"12px"}
                lineHeight={"16px"}
                color={"blackAlpha.400"}
                mt={"32px"}
              >
                ⚠ This rating is visible to the interviewer.
              </Text>
              {completeError ? (
                <FormErrorMessage>
                  Please select a rating for the peer.
                </FormErrorMessage>
              ) : null}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                setcompleteError(false);
                setfeedback(null);
                setbehavior(null);
                setpunctual(null);
                onCloseComplete(true);
              }}
              size={"sm"}
              variant={"outline"}
              colorScheme={"gray"}
              mr={"12px"}
            >
              Cancel
            </Button>
            <Button
              isLoading={loading}
              onClick={() => {
                if (
                  feedback === null ||
                  behavior === null ||
                  punctual === null
                ) {
                  setcompleteError(true);
                } else {
                  setloading(true);
                  var obj;
                  var peer;
                  if (data.actionBy === undefined) {
                    peer = "peer1";
                    setcompletedBy(peer);
                  } else {
                    peer = "peer2";
                    setcompletedBy(peer);
                  }
                  const pastStatus = peer === "peer1" ? "inReview" : "Complete";
                  if (data.creatorUid === user.uid) {
                    obj = {
                      actionBy: data.creatorUid,
                      past: pastStatus,
                      partnerFeedback: feedback,
                      partnerBehavior: behavior,
                      partnerPunctual: punctual,
                    };
                  } else {
                    obj = {
                      actionBy: data.partnerUid,
                      past: pastStatus,
                      creatorFeedback: feedback,
                      creatorBehavior: behavior,
                      creatorPunctual: punctual,
                    };
                  }
                  setDoc(doc(db, "events", data.eventId), obj, {
                    merge: true,
                  }).then(() => {
                    const sendemail = httpsCallable(functions, "sendemail");
                    const html =
                      peer === "peer2"
                        ? `Hey there,
                        <br/>
                        Well done! 👏 You've just completed a mock interview on Pearmock. We're super proud of you for taking this step towards acing your case interviews.
                        <br/>
                        And guess what? You've earned <b>20 points</b> for this session! Keep up the good work and you'll be leveling up in no time.
                        <br/>
                        Remember, every interview is a learning opportunity. Keep creating, keep practicing, and keep growing. You're doing an amazing job!
                        <br/>
                        Ready for the next one? <a href="https://pearmock.com">Log in to your account</a> and schedule your next mock interview. Let's keep this momentum going!
                        <br/>
                        Keep rocking 🚀
                        <br/>
                        Best,
                        <br/>
                        Patrice`
                        :  `Hey there,
                        <br/>
                        Well done! 👏 Your partner have just marked Complete the mock interview on Pearmock. We're super proud of you for taking this case interviews.
                        <br/>
                        You can earn <b>20 points</b> for this session! after you mark the mock Complete from your end.
                        <br/>
                        Remember, every interview is a learning opportunity. Keep creating, keep practicing, and keep growing. You're doing an amazing job!
                        <br/>
                        Ready to gain points? <a href="https://pearmock.com">Log in to your account</a> and mark the mock interview Complete. Let's keep this momentum going!
                        <br/>
                        Keep rocking 🚀
                        <br/>
                        Best,
                        <br/>
                        Patrice`;
                    sendemail({
                      to: `${partnerEmail}`,
                      subject: "Your Recent Pearmock Interview - Important Update",
                      text: "Your Partner has marked the mock Complete. You can review and mark it complete or report",
                      html: html,
                    });
                  });
                  if (peer === "peer2") {
                    var peer1;
                    var peer2;
                    getDoc(doc(db, "users", data.creatorUid)).then(
                      (docSnap) => {
                        peer1 = docSnap.data();

                        getDoc(doc(db, "users", data.partnerUid)).then(
                          (docSnap) => {
                            peer2 = docSnap.data();
                            const peer1mocklevel = peer1.levelMock + 1;
                            const peer2mocklevel = peer2.levelMock + 1;
                            const peer1created = Object.keys(
                              peer1.createdMocks
                            );
                            const peer2created = Object.keys(
                              peer2.createdMocks
                            );
                            const peer1level = updateLevel(peer1mocklevel);
                            const peer2level = updateLevel(peer2mocklevel);
                            const peer1tag = getTagObject(peer1level);
                            const peer2tag = getTagObject(peer2level);
                            updateDoc(doc(db, "users", data.creatorUid), {
                              mocksCompleted: increment(1),
                              levelMock: increment(1),
                              level: peer1level,
                            })
                              .then(() => {
                                updateDoc(doc(db, "users", data.partnerUid), {
                                  mocksCompleted: increment(1),
                                  levelMock: increment(1),
                                  level: peer2level,
                                });
                              })
                              .then(() => {
                                if (peer1created.length !== 0) {
                                  peer1created.forEach((id) => {
                                    console.log("updated peer 1: ", id);
                                    updateDoc(doc(db, "events", id), {
                                      creatorMocksCompleted: increment(1),
                                      creatorLevel: peer1level,
                                      ...peer1tag,
                                    });
                                  });
                                }
                                if (peer2created.length !== 0) {
                                  peer2created.forEach((id) => {
                                    console.log("updated peer 2: ", id);
                                    updateDoc(doc(db, "events", id), {
                                      creatorMocksCompleted: increment(1),
                                      creatorLevel: peer2level,
                                      ...peer2tag,
                                    });
                                  });
                                }
                              })
                              .then(() => {
                                onCloseComplete(true);
                                onOpenReview(true);
                              });
                          }
                        );
                      }
                    );
                  } else {
                    onCloseComplete(true);
                    onOpenReview(true);
                  }
                }
              }}
              size={"sm"}
            >
              Submit & Mark complete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default PastRecent;

import { Flex, Skeleton, SkeletonCircle } from "@chakra-ui/react"

export default function SingleUserSkeleton() {
    return (
        <Flex
            p={'24px'}
            flexDirection={'column'}
            gap={'16px'}
            borderRadius={'12px'}
            bg={'#EAF6A1'}
            w={{ base: '100%', md: '240px' }}
            h={'fit-content'}
        >
            <Flex
                gap={2}
            >
                <SkeletonCircle
                    size={'48px'}
                    flexShrink={0}
                    startColor='#CCE820'
                    endColor="#80921A"
                />
                <Skeleton
                    w={'100%'}
                    h={'48px'}
                    borderRadius={'12px'}
                    startColor='#CCE820'
                    endColor="#80921A"
                />
            </Flex>
            <Skeleton
                width={'100%'}
                h={'20px'}
                borderRadius={'12px'}
                startColor='#CCE820'
                endColor="#80921A"
            />
            <Skeleton
                width={'100%'}
                h={'20px'}
                borderRadius={'12px'}
                startColor='#CCE820'
                endColor="#80921A"
            />
            <Skeleton
                width={'100%'}
                h={'20px'}
                borderRadius={'12px'}
                startColor='#CCE820'
                endColor="#80921A"
            />
            <Skeleton
                width={'100%'}
                h={'20px'}
                borderRadius={'12px'}
                startColor='#CCE820'
                endColor="#80921A"
            />
            <Flex gap={2}>
                <SkeletonCircle
                    size={'48px'}
                    flexShrink={0}
                    startColor='#CCE820'
                    endColor="#80921A"
                />
                <SkeletonCircle
                    size={'48px'}
                    flexShrink={0}
                    startColor='#CCE820'
                    endColor="#80921A"
                />
                <Skeleton
                    width={'100%'}
                    h={'48px'}
                    borderRadius={'12px'}
                    startColor='#CCE820'
                    endColor="#80921A"
                />
            </Flex>
        </Flex>
    )
}

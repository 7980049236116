import { useEffect, useState } from "react";
import { db } from "../firebase";
import { Text, Box, Center, VStack, useDisclosure, Button, Stack} from "@chakra-ui/react";
import Authenticated from "../components/AppBar/Authenticated";
import Createmock from "../components/dashboard/createmock/Cratemock";
import SliderCard from "../components/dashboard/PearmockBlogs/SliderCard";
import Right from "../components/dashboard/rightColumn/Right";
import Footer from "../components/footer/Footer";
import {useAuth} from '../context/AuthContext'
import {query,where,collection,orderBy,limit,onSnapshot} from 'firebase/firestore'
import Loader from '../static/Loader'
import { useNavigate } from "react-router-dom";
import baseUrl from "../constants/urls";
import LogRocket from "logrocket";
import { amplitude } from "../analytics/analytics";
import MostRecent from "../components/dashboard/Mostrecent/MostRecent";
import PastRecent from "../components/dashboard/Mostrecent/PastRecent";
import NoContentRecent2 from "../components/dashboard/Mostrecent/NoContentRecent2";
import NoContentRecent1 from "../components/dashboard/Mostrecent/NoContentRecent1";
import InfoModal from "../components/dashboard/createmock/InfoModal";


function Dashboard() {
  const navigate = useNavigate()
  const { currentUser, user, userStatus } = useAuth();
  const [data, setdata] = useState([]);
  const [pastData, setpastData] = useState([]);
  const [loading, setloading] = useState(true);
  const [timings, settimings] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    if (user !== null && currentUser !== null) {
      const timings = [];
      Object.entries(user.createdMocks).map((mock) => {
        const [key, value] = mock;
        timings.push(value.toDate());
      });
      Object.entries(user.upcomingMocks).map((mock) => {
        const [key, value] = mock;
        timings.push(value.toDate());
      });
      settimings(timings)
    }
    if (data.length === 0 && currentUser !== null) {
      const eventRef = collection(db, "events");
      const q = query(
        eventRef,
        where("ids", "array-contains", currentUser.uid),
        where("eventStatus","==","upcoming"),
        where("past","==",false),
        orderBy("eventDateTime"),
        limit(1)
      );
      onSnapshot(q, (querySnap) => {
        setdata(
          querySnap.docs.map(doc=>{
            return doc.data()
          })
        )
      });
    }
    if (pastData.length === 0 && currentUser !== null) {
      const eventRef = collection(db, "events");
      const q = query(
        eventRef,
        where("ids", "array-contains", currentUser.uid),
        where("eventStatus", "==", "past"),
        where("past", "==", true),
        orderBy("eventDateTime", "desc"),
        limit(1)
      );
      onSnapshot(q, (querySnap) => {
        setpastData(
          querySnap.docs.map((doc) => {
            return doc.data();
          })
        );
      });
    }
  }, [currentUser,user]);

  useEffect(() => {
    if (currentUser !== null && user !== null && userStatus === 'verified') {
      setloading(false);
      LogRocket.identify(`${currentUser.uid}`, {
        name: `${currentUser.displayName}`,
        email: `${currentUser.email}`
      });
      amplitude.track('Verified user in Dashboard');
      if (Object.keys(user.createdMocks).length === 0){
        onOpen(true);
      }
    }
    else if(userStatus != null && userStatus !== 'verified'){
      navigate(`${baseUrl}`,{replace:true})
    }
  }, [currentUser, user, userStatus]);
  
  

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Authenticated setloading={setloading} />
          <Center
            pb={["10px", "64px", "64px", "192px", "192px"]}
            pt={["10px", "10px", "10px", "48px", "48px"]}
            px={'32px'}
          >
            <VStack spacing={"40px"} align={'start'}>
              <Box alignItems={'center'}>
                <Text
                  fontWeight={"700"}
                  fontSize={{base: "32px", sm:"48px"}}
                  lineHeight={"40px"}
                  color={"gray.700"}
                  mb={"72px"}
                >
                  Practice makes you{" "}
                  <Text as={"span"} color={"secondary.600"}>
                    Pear
                  </Text>
                  fect!
                </Text>
                <Stack spacing={'32px'} direction={{base: "column", sm: "row"}} align={'center'}>
                  {data.length ? (
                    <MostRecent data={data[0]} user={currentUser} />
                  ) : null}
                  {pastData.length ? (
                    <PastRecent data={pastData[0]} user={currentUser} />
                  ) : null}
                  {data.length === 0 && pastData.length === 0? <NoContentRecent1/>:null}
                  {data.length === 0 && pastData.length === 0? <NoContentRecent2/>:null}

                  {/* add blogs here */}
                  <SliderCard />
                </Stack>
                <Text
                  fontWeight={"700"}
                  fontSize={{base: "32px", sm:"48px"}}
                  lineHeight={"40px"}
                  color={"gray.700"}
                  mt={"48px"}
                >
                  Mock interview hub
                </Text>
              </Box>
              <Button
                size={"lg"}
                borderRadius={"8px"}
                onClick={onOpen}
                display={{ base: "block", md: "none" }}
                alignSelf={"flex-start"}
              >
                Create a mock +
              </Button>
              <Box
                w={["343px", "726px", "726px", "726px", "1109px"]}
                position={"relative"}
              >
                <Button
                  size={"lg"}
                  position={"absolute"}
                  top={0}
                  right={0}
                  borderRadius={"8px"}
                  onClick={()=>{
                    amplitude.track('create button clicked from dashboard')
                    onOpen(true)
                  }}
                  display={{ base: "none", md: "block" }}
                >
                  Create a mock +
                </Button>
                <InfoModal
                  userdata={user}
                  userUid={currentUser.uid}
                  onOpen={onOpen}
                  onClose={onClose}
                  isOpen={isOpen}
                />
                <Right userdata={user} user={currentUser} timings={timings} />
              </Box>
            </VStack>
          </Center>
          <Footer />
        </>
      )}
    </>
  );
}

export default Dashboard;

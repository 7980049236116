import { useState, useEffect } from "react";
import Authenticated from "../components/AppBar/Authenticated";
import { useAuth } from '../context/AuthContext'
import {
  Center,
  Box,
  VStack,
  HStack,
  Avatar,
  Text,
  Spacer,
  Tag,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  FormErrorMessage,
  Input
} from "@chakra-ui/react";
import Loader from "../static/Loader";
import baseUrl from "../constants/urls";
import { useNavigate } from 'react-router-dom'
import EditIcon from '../static/icons/EditIcon'
import EmailIcon from '../static/icons/EmailIcon'
import EducationIcon from '../static/icons/EducationIcon'
import { db } from '../firebase'
import { updateDoc, getDoc, doc } from 'firebase/firestore'
import {
  graduationYears,
  Experience
} from "../constants/profileOptions";
import { Select, CreatableSelect } from "chakra-react-select";
function Profile() {
  const { isOpen: isbioOpen, onOpen: onbioOpen, onClose: onbioClose } = useDisclosure()
  const { isOpen: isworkexOpen, onOpen: onworkexOpen, onClose: onworkexClose } = useDisclosure()
  const { isOpen: iscollegeOpen, onOpen: oncollegeOpen, onClose: oncollegeClose } = useDisclosure()
  const { isOpen: isworkOpen, onOpen: onworkOpen, onClose: onworkClose } = useDisclosure()
  const { isOpen: istargetOpen, onOpen: ontargetOpen, onClose: ontargetClose } = useDisclosure()
  const { isOpen: phoneNumberEditOpen, onOpen: onphoneNumberEditOpen, onClose: onphoneNumberEditClose } = useDisclosure()
  const { isOpen: cityEditOpen, onOpen: onCityEditOpen, onClose: onCityEditClose } = useDisclosure()
  const [bio, setbio] = useState("");
  const [biostate, setbiostate] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [city, setCity] = useState('')
  const [cityError, setCityError] = useState(false);
  const [workExperience, setworkExperience] = useState('')
  const [experiencestate, setexperiencestate] = useState(false)
  const [graduationYear, setgraduationYear] = useState('')
  const [graduationstate, setgraduationstate] = useState(false)
  const [educationCollegeName, seteducationCollegeName] = useState('')
  const [collegestate, setcollegestate] = useState(false)
  const [currentJobRole, setcurrentJobRole] = useState('')
  const [currentComapny, setcurrentComapny] = useState('')
  const [workstate, setworkstate] = useState(false)
  const [targetCompanies, settargetCompanies] = useState([])
  const [companystate, setcompanystate] = useState(false)
  const navigate = useNavigate()
  const { currentUser, user, userStatus } = useAuth()
  const [loading, setloading] = useState(true);
  const [buttonLoad, setbuttonLoad] = useState(false);
  const [created, setcreated] = useState([]);
  const [upcoming, setupcoming] = useState([]);
  useEffect(() => {
    if (currentUser !== null && user !== null && userStatus === 'verified') {
      setloading(false);
      setcreated(Object.keys(user.createdMocks))
      setupcoming(Object.keys(user.upcomingMocks))
    }
    else if (userStatus !== 'verified') {
      navigate(`${baseUrl}`, { replace: true })
    }
  }, [currentUser, user, userStatus]);

  function targetcompanies(options) {
    var target = [];
    options.map((doc) => target.push(doc.value));
    settargetCompanies(target);
  }

  function WorkEx(exp) {
    if (exp === "3+ months" || exp === "1-3 months") {
      return `${exp} internship experience`
    }
    else if (exp === "no experience") {
      return "no experience"
    }
    else {
      return `${exp} job experience`
    }
  }

  function CurrentJOb(job, company) {
    if (job === "" && company === "") {
      return "Not added"
    }
    else if (job === "" && company !== "") {
      return `${company}`
    }
    else if (job !== "" && company === "") {
      return `${job}`
    }
    else {
      return `${job}, ${company}`
    }
  }

  function updatebio() {
    if (bio === "" || !/\S/.test(bio)) {
      setbiostate(true);
    } else {
      setbiostate(false);
      setbuttonLoad(true)
      if (created.length !== 0) {
        created.forEach((id) => {
          updateDoc(doc(db, 'events', id), {
            creatorBio: bio.trim()
          })
        })
      }
      if (upcoming.length !== 0) {
        upcoming.forEach((id) => {
          var event;
          getDoc(doc(db, 'events', id)).then((docSnap) => {
            event = docSnap.data();
            if (event.creatorUid === currentUser.uid) {
              updateDoc(doc(db, 'events', id), {
                creatorBio: bio.trim()
              })
            }
            else {
              updateDoc(doc(db, 'events', id), {
                partnerBio: bio.trim()
              })
            }
          })
        })
      }
      updateDoc(doc(db, 'users', currentUser.uid), {
        bio: bio.trim()
      }).then(() => {
        setbuttonLoad(false)
        onbioClose(true)
      })
    }
  }

  function updateworkex() {
    if (workExperience === "") {
      setexperiencestate(true);
    } else {
      setexperiencestate(false);
      setbuttonLoad(true)
      if (created.length !== 0) {
        created.forEach((id) => {
          updateDoc(doc(db, 'events', id), {
            creatorWorkExperience: workExperience.value
          })
        })
      }
      if (upcoming.length !== 0) {
        upcoming.forEach((id) => {
          var event;
          getDoc(doc(db, 'events', id)).then((docSnap) => {
            event = docSnap.data();
            if (event.creatorUid === currentUser.uid) {
              updateDoc(doc(db, 'events', id), {
                creatorWorkExperience: workExperience.value
              })
            }
            else {
              updateDoc(doc(db, 'events', id), {
                partnerWorkExperience: workExperience.value
              })
            }
          })
        })
      }
      updateDoc(doc(db, 'users', currentUser.uid), {
        workExperience: workExperience.value
      }).then(() => {
        setbuttonLoad(false)
        onworkexClose(true)
      })
    }
  }
  //! city update ######################################## city update ######################

  function updateCity() {

    if (city === "" || city.trim().length === 0) {
      setCityError(true);
      return;
    }
    setCityError(false);
    updateDoc(doc(db, 'users', currentUser.uid), {
      city: city.trim()
    }).then(() => {
      setbuttonLoad(false)
      onCityEditClose(true)
    })
  }
  //! phone number ######################################## Phone number ######################
  function updatephoneNumber() {

    if (phoneNumber === "" || phoneNumber.trim().length < 10) {
      setPhoneNumberError(true);
      return;
    }
    setPhoneNumberError(false);
    setbuttonLoad(true)
    if (created.length !== 0) {
      created.forEach((id) => {
        updateDoc(doc(db, 'events', id), {
          creatorPhoneNumber: phoneNumber
        })
      })
    }
    if (upcoming.length !== 0) {
      upcoming.forEach((id) => {
        var event;
        getDoc(doc(db, 'events', id)).then((docSnap) => {
          event = docSnap.data();
          if (event.creatorUid === currentUser.uid) {
            updateDoc(doc(db, 'events', id), {
              creatorPhoneNumber: phoneNumber
            })
          }
          else {
            updateDoc(doc(db, 'events', id), {
              partnerPhoneNumber: phoneNumber
            })
          }
        })
      })
    }
    updateDoc(doc(db, 'users', currentUser.uid), {
      phoneNumber: phoneNumber
    }).then(() => {
      setbuttonLoad(false)
      onphoneNumberEditClose(true)
    })

  }

  function updatecollege() {
    if (educationCollegeName === "") {
      setcollegestate(true);
    }
    if (graduationYear === "") {
      setgraduationstate(true);
    }
    if (educationCollegeName !== "") {
      setcollegestate(false);
    }
    if (graduationYear !== "") {
      setgraduationstate(false);
    }
    if (educationCollegeName !== "" && graduationYear !== "") {
      setbuttonLoad(true)
      if (created.length !== 0) {
        created.forEach((id) => {
          updateDoc(doc(db, 'events', id), {
            creatorEducationCollegeName: educationCollegeName.value,
            creatorGraduationYear: graduationYear.value
          })
        })
      }
      if (upcoming.length !== 0) {
        upcoming.forEach((id) => {
          var event;
          getDoc(doc(db, 'events', id)).then((docSnap) => {
            event = docSnap.data();
            if (event.creatorUid === currentUser.uid) {
              updateDoc(doc(db, 'events', id), {
                creatorEducationCollegeName: educationCollegeName.value,
                creatorGraduationYear: graduationYear.value
              })
            }
            else {
              updateDoc(doc(db, 'events', id), {
                partnerEducationCollegeName: educationCollegeName.value,
                partnerGraduationYear: graduationYear.value
              })
            }
          })
        })
      }
      updateDoc(doc(db, 'users', currentUser.uid), {
        educationCollegeName: educationCollegeName.value,
        graduationYear: graduationYear.value
      }).then(() => {
        setbuttonLoad(false)
        oncollegeClose(true)
      })
    }
  }

  function updatework() {
    if (currentComapny === '' && currentJobRole === '') {
      setworkstate(true)
    }
    else {
      setworkstate(false)
      setbuttonLoad(true)
      if (created.length !== 0) {
        created.forEach((id) => {
          updateDoc(doc(db, 'events', id), {
            creatorCurrentCompany: currentComapny,
            creatorCurrentJobRole: currentJobRole
          })
        })
      }
      if (upcoming.length !== 0) {
        upcoming.forEach((id) => {
          var event;
          getDoc(doc(db, 'events', id)).then((docSnap) => {
            event = docSnap.data();
            if (event.creatorUid === currentUser.uid) {
              updateDoc(doc(db, 'events', id), {
                creatorCurrentCompany: currentComapny,
                creatorCurrentJobRole: currentJobRole
              })
            }
            else {
              updateDoc(doc(db, 'events', id), {
                partnerCurrentCompany: currentComapny,
                partnerCurrentJobRole: currentJobRole
              })
            }
          })
        })
      }
      updateDoc(doc(db, 'users', currentUser.uid), {
        currentCompany: currentComapny,
        currentJobRole: currentJobRole
      }).then(() => {
        setbuttonLoad(false)
        onworkClose(true)
      })
    }
  }

  function updatetarget() {
    var creator, partner, user
    if (targetCompanies.length === 0) {
      setcompanystate(true)
    }
    else if (targetCompanies.length !== 0) {
      setcompanystate(false)
      creator = {
        creatorTargetCompanies: targetCompanies
      }
      partner = {
        partnerTargetCompanies: targetCompanies
      }
      user = {
        targetCompanies: targetCompanies
      }
    }
    if (creator !== undefined && partner !== undefined && user !== undefined) {
      setbuttonLoad(true)
      if (created.length !== 0) {
        created.forEach((id) => {
          updateDoc(doc(db, 'events', id), {
            ...creator
          })
        })
      }
      if (upcoming.length !== 0) {
        upcoming.forEach((id) => {
          var event;
          getDoc(doc(db, 'events', id)).then((docSnap) => {
            event = docSnap.data();
            if (event.creatorUid === currentUser.uid) {
              updateDoc(doc(db, 'events', id), {
                ...creator
              })
            }
            else {
              updateDoc(doc(db, 'events', id), {
                ...partner
              })
            }
          })
        })
      }
      updateDoc(doc(db, 'users', currentUser.uid), {
        ...user
      }).then(() => {
        setbuttonLoad(false)
        ontargetClose(true)
      })
    }

  }


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Authenticated setloading={setloading} />
          <Center w={"full"} mt={'48px'} px={{base:4, md:0}}>
            <VStack w={"500px"} spacing={"24px"}>
              <HStack w={"full"}>
                <Avatar
                  src={user.avatarUrl}
                  name={user.firstName}
                  size={"lg"}
                  mr={"8px"}
                />
                <VStack alignItems={"start"} spacing={0}>
                  <Text
                    fontSize={"30px"}
                    lineHeight={"36px"}
                    fontWeight={"600"}
                  >
                    {user.firstName} {user.lastName}
                  </Text>
                  {/* <Text fontSize={"18px"} lineHeight={"28px"}>
                    {user.bio}
                  </Text> */}
                </VStack>
                {/* <Spacer />
                <EditIcon
                  w={"24px"}
                  h={"24px"}
                  cursor={"pointer"}
                  alignSelf={"end"}
                  onClick={onbioOpen}
                /> */}
              </HStack>
              {user.mocksCompleted !== 0 ? (
                <Box w={"full"} bg={"pink.50"} p={"12px"}>
                  <Tag bg={"primary.500"} color={"white"} mb={"4px"}>
                    Level {user.level}
                  </Tag>
                  <Text>
                    <Text as={"b"}>
                      {user.mocksCompleted}{" "}
                      {user.mocksCompleted === 1 ? <>mock</> : <>mocks</>}
                    </Text>{" "}
                    completed so far.{" "}
                    {user.level === 1 ? (
                      <>
                        Best candidates generally solve 20+ cases in their
                        preparation!
                      </>
                    ) : user.level === 2 ? (
                      <>Good job, {user.firstName}!🔥Practice harder!</>
                    ) : (
                      <>Great job, {user.firstName}! 🎉</>
                    )}
                  </Text>
                </Box>
              ) : null}
              <Box w={"full"}>
                <HStack>
                  <Box>
                    <Text>
                      <EmailIcon
                        w={"24px"}
                        h={"24px"}
                        mr={"8px"}
                        mt={"-2px"}
                        mb={"8px"}
                      />{" "}
                      {user.email}
                    </Text>
                    <Text>
                      <EducationIcon
                        w={"24px"}
                        h={"24px"}
                        mr={"8px"}
                        mt={"-2px"}
                      />{" "}
                      {user.educationCollegeName}
                    </Text>
                    <Text
                      ml={"36px"}
                    >{`(Graduation year, ${user.graduationYear})`}</Text>
                  </Box>
                  <Spacer />
                  <EditIcon
                    w={"24px"}
                    h={"24px"}
                    cursor={"pointer"}
                    alignSelf={"Center"}
                    onClick={oncollegeOpen}
                  />
                </HStack>
              </Box>
              <Box w={"full"}>
                <HStack>
                  <Box>
                    <Text as={"b"} fontSize={"16px"} lineHeight={"24px"}>
                      WORK EXPERIENCE
                    </Text>
                    <Text mt={"4px"}>{WorkEx(user.workExperience)}</Text>
                  </Box>
                  <Spacer />
                  <EditIcon
                    w={"24px"}
                    h={"24px"}
                    cursor={"pointer"}
                    alignSelf={"start"}
                    onClick={onworkexOpen}
                  />
                </HStack>
              </Box>
              <Box w={"full"}>
                <HStack>
                  <Box>
                    <Text as={"b"} fontSize={"16px"} lineHeight={"24px"}>
                      Phone Number
                    </Text>
                    <Text mt={"4px"}>{user.phoneNumber ? user.phoneNumber : 'Add your phone number'}</Text>
                  </Box>
                  <Spacer />
                  <EditIcon
                    w={"24px"}
                    h={"24px"}
                    cursor={"pointer"}
                    alignSelf={"start"}
                    onClick={onphoneNumberEditOpen}
                  />
                </HStack>
              </Box>
              {/*###################3 City ############################# */}
              <Box w={"full"}>
                <HStack>
                  <Box>
                    <Text as={"b"} fontSize={"16px"} lineHeight={"24px"}>
                      City
                    </Text>
                    <Text mt={"4px"}>{user.city ? user.city : 'Add your city'}</Text>
                  </Box>
                  <Spacer />
                  <EditIcon
                    w={"24px"}
                    h={"24px"}
                    cursor={"pointer"}
                    alignSelf={"start"}
                    onClick={onCityEditOpen}
                  />
                </HStack>
              </Box>
              <Box w={"full"}>
                <HStack>
                  <Box>
                    <Text as={"b"} fontSize={"16px"} lineHeight={"24px"}>
                      CURRENT WORK
                    </Text>
                    <Text mt={"4px"}>
                      {CurrentJOb(user.currentJobRole, user.currentCompany) ===
                        "Not added" ? (
                        <Text color={"blackAlpha.300"}>Not added</Text>
                      ) : (
                        <>
                          {CurrentJOb(user.currentJobRole, user.currentCompany)}
                        </>
                      )}
                    </Text>
                  </Box>
                  <Spacer />
                  <EditIcon
                    w={"24px"}
                    h={"24px"}
                    cursor={"pointer"}
                    alignSelf={"start"}
                    onClick={onworkOpen}
                  />
                </HStack>
              </Box>
              {/* <Box w={"full"}>
                <HStack>
                  <Box>
                    <Text as={"b"} fontSize={"16px"} lineHeight={"24px"}>
                      TARGET COMPANIES
                    </Text>
                    <Text>
                      {user.targetCompanies.map((doc, index) => {
                        return index + 1 === user.targetCompanies.length ? (
                          <Text as={"span"} key={doc}>
                            {doc}
                          </Text>
                        ) : (
                          <Text as={"span"} key={doc}>
                            {doc},{" "}
                          </Text>
                        );
                      })}
                    </Text>
                  </Box>
                  <Spacer />
                  <EditIcon
                    w={"24px"}
                    h={"24px"}
                    cursor={"pointer"}
                    alignSelf={"start"}
                    onClick={ontargetOpen}
                  />
                </HStack>
              </Box> */}
            </VStack>
          </Center>
          {/*bio modal*/}
          <Modal
            onClose={onbioClose}
            isOpen={isbioOpen}
            isCentered
            closeOnOverlayClick={false}
          >
            <ModalOverlay />
            <ModalContent borderRadius={'16px'}>
              <ModalHeader>Update your bio</ModalHeader>
              <ModalCloseButton
                onClick={() => {
                  setbio("");
                  setbiostate(false);
                  onbioClose(true);
                }}
              />
              <ModalBody>
                <FormControl isInvalid={biostate}>
                  <Input
                    type={"text"}
                    placeholder={"Add your new bio"}
                    onChange={(e) => setbio(e.target.value)}
                    isInvalid={biostate}
                  />
                  {biostate ? (
                    <FormErrorMessage>
                      Please enter a bio which helps us know about you
                    </FormErrorMessage>
                  ) : null}
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={() => {
                    setbio("");
                    setbiostate(false);
                    onbioClose(true);
                  }}
                  variant={"outline"}
                  colorScheme={"gray"}
                  size={"sm"}
                  mr={"12px"}
                  display={buttonLoad ? "none" : "block"}
                >
                  Cancel
                </Button>
                <Button onClick={updatebio} size={"sm"} isLoading={buttonLoad}>
                  Update
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          {/* phone number modal */}
          <Modal
            onClose={onphoneNumberEditClose}
            isOpen={phoneNumberEditOpen}
            isCentered
            closeOnOverlayClick={false}
          >
            <ModalOverlay />
            <ModalContent borderRadius={'16px'}>
              <ModalHeader>Update your phone number</ModalHeader>
              <ModalCloseButton
                onClick={() => {
                  setPhoneNumber("");
                  setPhoneNumberError(false);
                  onphoneNumberEditClose(true);
                }}
              />
              <ModalBody>
                <FormControl isInvalid={phoneNumberError}>
                  <Input
                    type="number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Enter your phone number"
                  />

                  {phoneNumberError && <FormErrorMessage>
                    Please enter a non-empty correct number
                  </FormErrorMessage>}
                </FormControl>

              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={() => {
                    setPhoneNumber("");
                    setPhoneNumberError(false);
                    onphoneNumberEditClose(true);
                  }}
                  variant={"outline"}
                  colorScheme={"gray"}
                  size={"sm"}
                  mr={"12px"}
                  display={buttonLoad ? "none" : "block"}
                >
                  Cancel
                </Button>
                <Button
                  onClick={updatephoneNumber}
                  size={"sm"}
                  isLoading={buttonLoad}
                >
                  Update
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          {/* city update modal */}
          <Modal
            onClose={onCityEditClose}
            isOpen={cityEditOpen}
            isCentered
            closeOnOverlayClick={false}
          >
            <ModalOverlay />
            <ModalContent borderRadius={'16px'}>
              <ModalHeader>Update your city</ModalHeader>
              <ModalCloseButton
                onClick={() => {
                  setCity("");
                  setCityError(false);
                  onCityEditClose(true);
                }}
              />
              <ModalBody>
                <FormControl isInvalid={cityError}>
                  <Input
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    placeholder="Enter your city"
                    type="text"
                  />
                  {cityError && <FormErrorMessage>
                    Please enter a non-empty city
                  </FormErrorMessage>}
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={() => {
                    setCity("");
                    setCityError(false);
                    onCityEditClose(true);
                  }}
                  variant={"outline"}
                  colorScheme={"gray"}
                  size={"sm"}
                  mr={"12px"}
                  display={buttonLoad ? "none" : "block"}
                >
                  Cancel
                </Button>
                <Button
                  onClick={updateCity}
                  size={"sm"}
                  isLoading={buttonLoad}
                >
                  Update
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          {/*work experience modal*/}
          <Modal
            onClose={onworkexClose}
            isOpen={isworkexOpen}
            isCentered
            closeOnOverlayClick={false}
          >
            <ModalOverlay />
            <ModalContent borderRadius={'16px'}>
              <ModalHeader>Update your work experience</ModalHeader>
              <ModalCloseButton
                onClick={() => {
                  setworkExperience("");
                  setexperiencestate(false);
                  onworkexClose(true);
                }}
              />
              <ModalBody>
                <FormControl isInvalid={experiencestate}>
                  <Select
                    options={Experience}
                    placeholder={"Work experience"}
                    tabSelectsValue={false}
                    onChange={setworkExperience}
                    isInvalid={experiencestate}
                  />
                  {experiencestate ? (
                    <FormErrorMessage>
                      Please select your work experience
                    </FormErrorMessage>
                  ) : null}
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={() => {
                    setworkExperience("");
                    setexperiencestate(false);
                    onworkexClose(true);
                  }}
                  variant={"outline"}
                  colorScheme={"gray"}
                  size={"sm"}
                  mr={"12px"}
                  display={buttonLoad ? "none" : "block"}
                >
                  Cancel
                </Button>
                <Button
                  onClick={updateworkex}
                  size={"sm"}
                  isLoading={buttonLoad}
                >
                  Update
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          {/*University updation modal*/}
          <Modal
            onClose={oncollegeClose}
            isOpen={iscollegeOpen}
            isCentered
            closeOnOverlayClick={false}
            size={"lg"}
          >
            <ModalOverlay />
            <ModalContent borderRadius={'16px'}>
              <ModalHeader>Update your university / college</ModalHeader>
              <ModalCloseButton
                onClick={() => {
                  setgraduationYear("");
                  seteducationCollegeName("");
                  setgraduationstate(false);
                  setcollegestate(false);
                  oncollegeClose(true);
                }}
              />
              <ModalBody>
                <FormControl isInvalid={collegestate || graduationstate}>
                  <Box>
                    <Box>
                      <CreatableSelect
                        placeholder={"Enter your University/College name"}
                        tabSelectsValue={false}
                        onChange={seteducationCollegeName}
                        isInvalid={collegestate}
                      />
                      {collegestate ? (
                        <FormErrorMessage>
                          Please select your college
                        </FormErrorMessage>
                      ) : null}
                    </Box>
                    <Box mt={"16px"}>
                      <Select
                        options={graduationYears}
                        placeholder={"Graduation year"}
                        tabSelectsValue={false}
                        onChange={setgraduationYear}
                        isInvalid={graduationstate}
                      />
                      {graduationstate ? (
                        <FormErrorMessage>
                          Please select your graduation year
                        </FormErrorMessage>
                      ) : null}
                    </Box>
                  </Box>
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={() => {
                    setgraduationYear("");
                    seteducationCollegeName("");
                    setgraduationstate(false);
                    setcollegestate(false);
                    oncollegeClose(true);
                  }}
                  variant={"outline"}
                  colorScheme={"gray"}
                  size={"sm"}
                  mr={"12px"}
                  display={buttonLoad ? "none" : "block"}
                >
                  Cancel
                </Button>
                <Button
                  onClick={updatecollege}
                  size={"sm"}
                  isLoading={buttonLoad}
                >
                  Update
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          {/*Current Work updation modal*/}
          <Modal
            onClose={onworkClose}
            isOpen={isworkOpen}
            isCentered
            closeOnOverlayClick={false}
            size={"lg"}
          >
            <ModalOverlay />
            <ModalContent borderRadius={'16px'}>
              <ModalHeader>
                {CurrentJOb(user.currentJobRole, user.currentCompany) ===
                  "Not added" ? (
                  <>Add your current work</>
                ) : (
                  <>Update your current work</>
                )}
              </ModalHeader>
              <ModalCloseButton
                onClick={() => {
                  setcurrentComapny("");
                  setcurrentJobRole("");
                  setworkstate(false);
                  onworkClose(true);
                }}
              />
              <ModalBody>
                <FormControl isInvalid={workstate}>
                  <Input
                    type={"text"}
                    placeholder={"Current job role"}
                    onChange={(e) => setcurrentJobRole(e.target.value)}
                    isInvalid={workstate}
                  />
                  <Input
                    type={"text"}
                    placeholder={"Current company"}
                    mt={"16px"}
                    onChange={(e) => setcurrentComapny(e.target.value)}
                    isInvalid={workstate}
                  />
                  {workstate ? (
                    <FormErrorMessage>
                      Enter data on atleast one field for updation
                    </FormErrorMessage>
                  ) : null}
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={() => {
                    setcurrentComapny("");
                    setcurrentJobRole("");
                    setworkstate(false);
                    onworkClose(true);
                  }}
                  variant={"outline"}
                  colorScheme={"gray"}
                  size={"sm"}
                  mr={"12px"}
                  display={buttonLoad ? "none" : "block"}
                >
                  Cancel
                </Button>
                <Button onClick={updatework} size={"sm"} isLoading={buttonLoad}>
                  Update
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          {/*Target Profiles and companies updation modal*/}
          <Modal
            onClose={ontargetClose}
            isOpen={istargetOpen}
            isCentered
            closeOnOverlayClick={false}
            size={"lg"}
          >
            <ModalOverlay />
            <ModalContent borderRadius={'16px'}>
              <ModalHeader>Update your target companies</ModalHeader>
              <ModalCloseButton
                onClick={() => {
                  settargetCompanies([])
                  setcompanystate(false)
                  ontargetClose(true);
                }}
              />
              <ModalBody>
                <FormControl isInvalid={companystate}>
                  <Box>
                    <Box mt={"16px"}>
                      <CreatableSelect
                        placeholder={"Enter your Target company(ies)"}
                        tabSelectsValue={false}
                        onChange={targetcompanies}
                        closeMenuOnSelect={false}
                        isInvalid={companystate}
                      />
                      {companystate ? (
                        <FormErrorMessage>
                          Please select some company(ies)
                        </FormErrorMessage>
                      ) : null}
                    </Box>
                  </Box>
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={() => {
                    settargetCompanies([])
                    setcompanystate(false)
                    ontargetClose(true);
                  }}
                  variant={"outline"}
                  colorScheme={"gray"}
                  size={"sm"}
                  mr={"12px"}
                  display={buttonLoad ? "none" : "block"}
                >
                  Cancel
                </Button>
                <Button
                  onClick={updatetarget}
                  size={"sm"}
                  isLoading={buttonLoad}
                >
                  Update
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
}

export default Profile;

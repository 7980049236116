import { Center, Text } from "@chakra-ui/react";
function Time({ datetime }) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  function getTime(time) {
    const t = time;
    const hour = t.getHours();
    const minute = t.getMinutes();
    if (hour <= 12) {
      if (hour < 10) {
        if (minute < 10) {
          return `0${hour}:0${minute} ${hour === 12 ? "PM" : "AM"}`;
        } else {
          return `0${hour}:${minute} ${hour === 12 ? "PM" : "AM"}`;
        }
      } else {
        if (minute < 10) {
          return `${hour}:0${minute} ${hour === 12 ? "PM" : "AM"}`;
        } else {
          return `${hour}:${minute} ${hour === 12 ? "PM" : "AM"}`;
        }
      }
    } else {
      if (hour - 12 < 10) {
        if (minute < 10) {
          return `0${hour - 12}:0${minute} PM`;
        } else {
          return `0${hour - 12}:${minute} PM`;
        }
      } else {
        if (minute < 10) {
          return `${hour - 12}:0${minute} PM`;
        } else {
          return `${hour - 12}:${minute} PM`;
        }
      }
    }
  }
  return (
    <Center
      p={"8px"}
      h={"37px"}
      borderRadius={"8px"}
      backgroundColor={"#DBE0B9"}
    >
      <Text
        fontSize={"16px"}
        fontWeight={"700"}
        lineHeight={"22px"}
        color={"#72820D"}
      >
        {getTime(datetime)}, {datetime.getDate()}{" "}
        {monthNames[datetime.getMonth()]} {datetime.getFullYear()}
      </Text>
    </Center>
  );
}

export default Time;

import React from 'react'
import { Box, Flex, Heading, VStack, Text, SimpleGrid } from '@chakra-ui/react';
import InterviewSvg from './svg/InterviewSvg';
import MasterySvg from './svg/MasterySvg';
import OpportunitesSvg from './svg/OpportunitesSvg';
import SessionSvg from './svg/SessionSvg';
import ShoesSvg from './svg/ShoesSvg';
import ProgressSvg from './svg/ProgressSvg';
const reasons = [
  {
    title:'Join or Create Your Own Interview', 
    reason:'Join an existing mock interview or create your own. Customize your practice to meet your unique case prep needs.',
    SvgLogo: InterviewSvg
  },
  {
    title:"Step into the Interviewer's Shoes", 
    reason:"Gain a deeper understanding of the interview process by experiencing both sides of the table.",
    SvgLogo: ShoesSvg
  },
  {
    title:"Stay updated and never miss a session", 
    reason:"With our seamless Google Calendar integration, you'll receive timely notifications about your upcoming mock interviews and action items.",
    SvgLogo: SessionSvg
  },
  {
    title:"Consistency is the key to mastery", 
    reason:"Regularly scheduled mock interviews help you steadily build confidence and refine your skills.",
    SvgLogo: MasterySvg
  },
  {
    title:"Progress you can see", 
    reason:"Earn points for each mock interview you participate in. Track your progress and see your improvement over time.",
    SvgLogo: ProgressSvg
  },
  {
    title:"Turn Practice into Opportunities", 
    reason:"Stand out in our community through consistent performance and dedication. Top performers stand a chance to land interview opportunities.",
    SvgLogo: OpportunitesSvg
  }
]
const CustomGrid = ({ children }) => {
  return (
    <SimpleGrid
      columns={{ base: 1, md: 2 }}
      gap={'75px'}
      justifyContent={'center'}
      alignItems={'flex-start'}
    >
      {children}
    </SimpleGrid>
  )
}
const ReasonBox = ({ SvgLogo, title, reason }) => {
  return (
    <CustomGrid>
      <Flex
        width={{ base: '100%', md: '500px' }}
        textAlign={{ base: 'center', md: 'left' }}
        alignItems={'center'}
        justifyContent={'center'}
        flexDir={{ base: 'column', md: 'row' }}
        gap={'28px'}
        px={'20px'}
     
      >
        <Flex
          justifyContent={'center'}
          alignItems={'center'}
        >
          <SvgLogo />
        </Flex>
        <VStack
          gap={2}
          flex={2}
        >
          <Text
            fontSize={'26px'}
            fontWeight={800}
          >
            {title}
          </Text>
          <Text
            fontSize={'20px'}
            fontWeight={500}
            inlineHeight={'140%'}
          >
            {reason}
          </Text>
        </VStack>
      </Flex>
    </CustomGrid>

  )
}
export default function WhyJoin() {
  return (
    <Box
      p={{ base: '100px 10px', md: '120px 150px' }}
      fontFamily={`'Manrope', sans-serif`}
    >
      <Heading
        fontSize={{ base: '52px', md: '72px' }}
        fontWeight={700}
        textAlign={'center'}
        mb={'100px'}
      >
        Why join Pearmock?
      </Heading>
      <CustomGrid>
        {
          reasons.map((reason, index) => (
            <ReasonBox
              key={`${reason.title}-${index}`}
              SvgLogo={reason.SvgLogo}
              title={reason.title}
              reason={reason.reason}
            />
          ))
        }
      </CustomGrid>
    </Box>
  )
}

import {Flex} from '@chakra-ui/react'

function NavbarContainer({children, ...props}) {
  return (
    <Flex
      align="center"
      justify="flex-start"
      w="100%"
      h="72px"
      bg={"white"}
      color={"primary.700"}
      position={'sticky'}
      top={0}
      zIndex={200}
      {...props}
    >
      {children}
    </Flex>
  );
}

export default NavbarContainer;

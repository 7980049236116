import { Box, Image, Text } from '@chakra-ui/react'
import source from '../../../static/images/7 1.svg'

function NoContentRecent1() {
  return (
    <Box
    borderRadius={"24px"}
    w={"328px"}
    h={"264px"}
    p={"24px"}
    bg={'blue.50'}
  >
    <Image src={source} w={'100px'} h={'82px'}/>
    <Text fontSize={'20px'} lineHeight={'120%'} fontWeight={'700'} color={'blue.700'} mt={'23px'} mb={'12px'}>Start your journey by joining a mock</Text>
    <Text fontSize={'14px'} lineHeight={'125%'} fontWeight={'500'} color={'gray.700'}>Join a mock interview and then prepare the cases for yourself and your partner’s interview.</Text>
  </Box>
  )
}

export default NoContentRecent1
const productmodal = [
  { value: "Product Design", label: "Product Design" },
  { value: "Product Improvement", label: "Product Improvement" },
  { value: "Strategy", label: "Strategy" },
  { value: "Product Metrices", label: "Product Metrices" },
  { value: "Root cause analysis", label: "Root cause analysis" },
  { value: "Guesstimates", label: "Guesstimates" },
  { value: "Behavorial", label: "Behavorial" },
];

const productcase = ["Product Design","Product Improvement","Strategy","Product Metrices","Root cause analysis","Guesstimates","Behavorial"]

export {
  productmodal,
  productcase
};

import Partner from "../dashboard/common/Partner";
import { Box, Button } from "@chakra-ui/react";
import {doc,setDoc,updateDoc} from 'firebase/firestore'
import { level_point_map } from "../../constants/level";
import {httpsCallable} from 'firebase/functions'
import {db,functions} from '../../firebase'

function AdminCard({ data }) {
  const partnerprops = {
    partnerName: `${data.firstName} ${data.lastName}`,
    partnerEmail: data.email,
    partnerLinkedin: data.linkedinUrl,
    partnerMocksCompleted: data.mocksCompleted,
    partnerwork: data.bio,
    partnerImage: data.avatarUrl,
    partnerEducation: data.educationCollegeName,
    partnerCurrentJobRole: data.currentJobRole,
    partnerCurrentCompany: data.currentCompany,
    partnerTargetCompanies: data.targetCompanies,
    partnerWorkExperience: data.workExperience,
  };

  function updateLevel(levelMock){
    var level;
    for(var [key,value] of level_point_map){
      if(levelMock >= value){
        level = key;
      }
    }
    return level;
  }

  function levelMock(exp) {
    if (exp === "1-3 months" || exp === "3+ months") {
      return 0;
    } else if (exp === "no experience") {
      return 0;
    } else {
      return 60;
    }
  }

  function verify(){
      setDoc(doc(db,'users',data.id),{
          userStatus: 'verified',
          verifiedAt: new Date(),
          level: updateLevel(levelMock(data.workExperience)),
          levelMock: levelMock(data.workExperience)
      },{merge:true}).then(() => {
        const sendemail = httpsCallable(functions, "sendemail");
        sendemail({
          to: `${data.email}`,
          subject: `Welcome to Pearmock ${data.firstName} ${data.lastName}`,
          text: "We welcome you to Pearmock",
          html: `Hello ${data.firstName},
          <br/>
          <br/>
          Welcome to <b>Pearmock</b>! We're thrilled to have you join our vibrant community of ambitious professionals and students. You've taken the first step towards acing your case interviews, and we're here to support you every step of the way.
          <br/>
          <br/>
          Remember, the key to success is consistency. Schedule regular practice sessions, actively participate in interviews, and don't shy away from giving or receiving feedback. Every interview is a learning opportunity.
          <br/>
          <br/>
          If you have any questions or need any help, feel free to reach out to us. We're here to support you on your journey.
          <br/>
          <br/>
          Now, we'd love to get to know you better. Reply to this email and tell us something interesting about yourself. It could be a fun fact, a hobby, or even your favorite book. This will help us ensure that our conversation is recognized as a genuine human interaction by the email gods.
          <br/>
          <br/>
          Ready to get started? <a href="https://peramock.com">Log in to your account</a> and schedule your first mock interview today!
          <br/>
          <br/>
          Happy practicing,
          <br/>
          <br/>
          Krishna
          <br/>
          Pearmock team`,
        });
      });
  }

  function makeAdmin() {
    updateDoc(doc(db, "users", data.id),{
        isAdmin: true
      },{merge:true});
  }

  return (
    <Box
      border={"1px solid #E2E8F0"}
      boxShadow={
        "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
      }
      borderRadius={"4px"}
      px={"16px"}
      my={"24px"}
      display={"inline-flex"}
    >
      <Partner {...partnerprops} />
      <Button size={"sm"} mt={"32px"} ml={"150px"} onClick={verify}>
        Verify
      </Button>
      <Button size={"sm"} mt={"32px"} ml={"20px"} onClick={makeAdmin}>
        Make Admin
      </Button>
    </Box>
  );
}

export default AdminCard;

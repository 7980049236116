import { useState } from "react";
import {
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  IconButton,
  MenuList,
  HStack,
  Spacer,
  useToast
} from "@chakra-ui/react";

import { HamburgerIcon } from "../../static/icons/HamburgerIcon";
import { doc, deleteDoc, updateDoc } from "firebase/firestore";
import {db} from '../../firebase'
import draftToHtml from 'draftjs-to-html';
import Editor from "cuvette-text-editor";

function NoteCard({ data , currentUser }) {
  const [date] = useState(data.updatedAt.toDate().toDateString());
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen:isDeleteOpen, onOpen:onDeleteOpen, onClose:onDeleteClose } = useDisclosure();
  const [update, setupdate] = useState(false)
  const [buttonLoad, setbuttonLoad] = useState(false)
  const [updateTitle, setupdateTitle] = useState(data.title)
  const [editorValue, seteditorValue] = useState(data.html)
  const [desc, setdesc] = useState(data.text)
  const toast = useToast()
  const maxchar = 2000
  const [charNum, setcharNum] = useState(maxchar-data.text.length)

  return (
    <>
      <Box
        w={"266px"}
        h={"180px"}
        border={"1px solid #E2E8F0"}
        borderRadius={"8px"}
        p={"16px"}
        onClick={onOpen}
      >
        <Box h={"20px"} mb={"4px"}>
          <Text
            fontSize={"14px"}
            lineHeight={"20px"}
            color={"blackAlpha.600"}
            fontWeight={"400"}
          >
            {date}
          </Text>
        </Box>
        <Box w={"234px"} h={"56px"} mb={"8px"}>
          <Text
            fontSize={"18px"}
            lineHeight={"28px"}
            color={"blackAlpha.800"}
            noOfLines={2}
            fontWeight={"500"}
          >
            {data.title}
          </Text>
        </Box>
        <Box w={"234px"} h={"60px"}>
          <Text
            fontSize={"14px"}
            lineHeight={"20px"}
            color={"blackAlpha.800"}
            noOfLines={3}
            fontWeight={"400"}
          >
            {data.text}
          </Text>
        </Box>
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        isCentered
        size={"xl"}
      >
        <ModalOverlay />
        <ModalContent borderRadius={'16px'}>
          <ModalHeader>
            <HStack>
              <Box>
                <Text
                  fontSize={"14px"}
                  lineHeight={"20px"}
                  color={"blackAlpha.600"}
                  fontWeight={"400"}
                >
                  {date}
                </Text>
                {update?
                <Input
                  w={'500px'}
                  fontSize={'24px'}
                  lineHeight={'32px'}
                  fontWeight={'500'}
                  color={'blackAlpha.800'}
                  defaultValue={updateTitle}
                  maxLength={200}
                  variant={"unstyled"}
                  isReadOnly={!update}
                  onChange={(e) => {
                    setupdateTitle(e.target.value);
                  }}
                />:<Text fontSize={'24px'}
                lineHeight={'32px'}
                fontWeight={'500'}
                color={'blackAlpha.800'}>{updateTitle}</Text>}
              </Box>
              <Spacer />
              {update?null:
              <Menu offset={[-40, 0]}>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<HamburgerIcon w={"24px"} h={"24px"} />}
                  variant="ghost"
                  colorScheme={"gray"}
                  alignSelf={"baseline"}
                  size={"sm"}
                  mr={"-10px"}
                />
                <MenuList
                  minW={0}
                  minH={0}
                  p={0}
                  fontSize={"14px"}
                  lineHeight={"20px"}
                >
                  <MenuItem
                    minW={0}
                    minH={0}
                    onClick={() => {
                      setupdate(true);
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem minW={0} minH={0} onClick={onDeleteOpen}>
                    Delete
                  </MenuItem>
                </MenuList>
              </Menu>}
            </HStack>
          </ModalHeader>
          <ModalBody maxH={"512px"} overflowY={"scroll"}>
            <Editor
              defaultState={editorValue}
              readOnly={!update}
              onChange={(value) => {
                if (value !== "") {
                  const html = draftToHtml(JSON.parse(value));
                  const text = html
                    .replace(/<(?:.|\n)*?>/gm, "")
                    .replace(/(\r\n|\n|\r)/gm, "")
                    .replaceAll("&nbsp;", " ")
                    .trim();
                  if (text.length <= maxchar) {
                    setcharNum(maxchar - text.length);
                    seteditorValue(value);
                    setdesc(text);
                  } else {
                    setcharNum(maxchar - text.length);
                  }
                }
              }}
              placeholder="Details"
              hideSeparator={true}
              className="Editor"
              hiddenOptions={["header-one", "header-two", "code", "highlight"]}
              colors={{ primary: "#F64B4B" }}
            />
            {update?<Text>
              {charNum < 0 ? (
                <>Character exceded. This data won't be saved</>
              ) : null}
            </Text>:null}
          </ModalBody>

          <ModalFooter>
            {update ? (
              <>
                <Button
                  onClick={()=>{setupdate(false)}}
                  variant={"outline"}
                  colorScheme={"gray"}
                  size={"sm"}
                  mr={"12px"}
                >
                  Cancel
                </Button>
                <Button
                  isLoading={buttonLoad}
                  onClick={() => {
                    setbuttonLoad(true);
                    if(updateTitle.trim() === "" || charNum === maxchar){
                      toast({
                        title: 'No Title or Description added..',
                        status: 'error',
                        isClosable: true,
                        duration: 1000
                      })
                      setbuttonLoad(false);
                    }
                    else if(charNum < 0){
                      toast({
                        title: 'Character Limit exceded',
                        status: 'error',
                        isClosable: true,
                        duration: 1000
                      })
                      setbuttonLoad(false);
                    }
                    else{
                      const notesRef = doc(db, "users", currentUser.uid, "notes",data.id);
                      updateDoc(
                        notesRef,
                        {
                          updatedAt: new Date(),
                          title: updateTitle,
                          html: editorValue,
                          text: desc,
                        }
                      ).then(() => {
                        setbuttonLoad(false);
                        setupdate(false)
                      });
                    }
                  }}
                  size={"sm"}
                >
                  Update
                </Button>
              </>
            ) : (
              <Button
                onClick={onClose}
                variant={"outline"}
                colorScheme={"gray"}
                size={"sm"}
              >
                Close
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        closeOnOverlayClick={false}
        isCentered
      >
        <ModalOverlay />
        <ModalContent borderRadius={'16px'}>
          <ModalHeader>Delete note</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Heads up! You can not undo this action. Are you sure you want to
            delete this note?
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onDeleteClose}
              variant={"outline"}
              colorScheme={"gray"}
              size={"sm"}
              mr={"12px"}
            >
              Cancel
            </Button>
            <Button
              isLoading={buttonLoad}
              onClick={() => {
                setbuttonLoad(true);
                deleteDoc(doc(db, "users", currentUser.uid, "notes", data.id));
              }}
              size={"sm"}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default NoteCard;

import { BrowserRouter as Router, Route , Routes} from "react-router-dom"
import Landingpage from './pages/Landingpage';
import Dashboard from './pages/Dashboard';
import Notes from './pages/Notes';
import Profile from './pages/Profile';
import Profileinfo from "./pages/Profileinfo";
import Submitted from "./pages/Submitted";
import baseUrl from './constants/urls'
import Admin from "./pages/Admin";
import LeaderBoard from "./pages/Leaderboard";
import { useAuth } from "./context/AuthContext";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path={`${baseUrl}`} element={<Landingpage />} />
        <Route path={`${baseUrl}profileinfo`} element={<Profileinfo />} />
        <Route path={`${baseUrl}submitted`} element={<Submitted />} />
        <Route path={`${baseUrl}dashboard`} element={<Dashboard />} />
        <Route path={`${baseUrl}notes`} element={<Notes />} />
        <Route path={`${baseUrl}profile`} element={<Profile />} />
        <Route path={`${baseUrl}admin`} element={<Admin />}/>
        <Route path={`${baseUrl}leaderboard`} element={<LeaderBoard />}/>

        <Route path={`${baseUrl}*`} element={<p>404</p>} />
      </Routes>
    </Router>
  );
}

export default App;

import { useState,useEffect } from "react";
import {
  Box,
  Text,
  Button,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  Spacer
} from "@chakra-ui/react";
import Joinmodal from "./Joinmodal";
import Time from "../../common/Time";
import Partner from "../../common/Partner";
import Mockinfo from "../../common/Mockinfo";

function Joincard({ data,slot,user,userdata}) {
  const [isOpen2, setisOpen2] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if(data.referred){
      setisOpen2(true)
    }
  }, [data])
  

  const info = {
    IsOpenfunc: setisOpen2,
    IsOpen: isOpen2,
    data: data,
    user:user,
    userdata: userdata
  };

  function joinmock() {
    setisOpen2(true)
  }

  const timeprops = {
    datetime: data.eventDateTime.toDate(),
  };

  const partnerprops = {
    partnerName: `${data.creatorFirstName}`,
    partnerEmail: data.creatorEmail,
    partnerLinkedin: data.creatorLinkedin,
    partnerMocksCompleted: data.creatorMocksCompleted,
    partnerImage: data.creatorAvatarUrl,
    partnerEducation: data.creatorEducationCollegeName,
    partnerCurrentJobRole: data.creatorCurrentJobRole,
    partnerCurrentCompany: data.creatorCurrentCompany,
    partnerWorkExperience: data.creatorWorkExperience,
    partnerLevel: data.creatorLevel
  };

  const mockinfo = {
    topHeading: "Wants to practice",
    creatorCaseType: data.creatorCaseType,
    bottomHeading: "Will take",
    willTakeType: data.willTakeType,
  }

  return (
    <>
      <Box
        w={"343px"}
        h={"421px"}
        borderRadius={'24px'}
        px={'24px'}
        py={'28px'}
        backgroundColor={"#F2F7D9"}
      >
        <Partner {...partnerprops} />
        <Divider borderColor={"white"} borderWidth={"2px"} my={'20px'}/>
        <Mockinfo {...mockinfo}/>
        <Divider borderColor={"white"} borderWidth={"2px"} my={'20px'}/>
        <Flex>
          <Time {...timeprops} />
          <Spacer/>
          {slot ? (
            <Button h={"37px"} onClick={joinmock} w={"86px"} disabled={data.past}>
              {data.past? `Expired`: `Join`}
            </Button>
          ) : (
            <Button bg={"primary.200"} color={"primary.600"} onClick={onOpen} variant={'ghost'} w={"86px"}>
              Slot Clashing
            </Button>
          )}
        </Flex>
      </Box>
      <Joinmodal {...info} />
      {/*Slot clash modal*/}
      <Modal onClose={onClose} isOpen={isOpen} isCentered size={'lg'} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent borderRadius={'16px'}>
          <ModalHeader>Slot clashing</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize={'14px'} fontWeight={'400'} lineHeight={'20px'} color={'gray.500'} mb={'24px'}>
            This slot is clashing with one of your created or upcoming mock
            interviews. Please review the list of created and upcoming mocks and
            delete / cancel the interview in order to join this one.
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Joincard;

import { useState } from "react";
import { Box, Text, useBoolean, Popover, PopoverContent, PopoverTrigger, PopoverBody } from "@chakra-ui/react";

function Casepopover({open, setopen, content}) {
  return (
    <Popover isOpen={open}>
      <PopoverTrigger>
        <Text
          as={"u"}
          color={"black"}
          fontWeight={"600"}
          onClick={setopen.toggle}
          cursor={"pointer"}
        >
          {open ? <>...see less</> : <>...see more</>}
        </Text>
      </PopoverTrigger>
      <PopoverContent borderRadius={"8px"} w={"280px"}>
        <PopoverBody
          borderRadius={"8px"}
          boxShadow={"0px 0px 8px 1px rgba(0, 0, 0, 0.3)"}
        >
          {content}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

function Mockinfo({
  topHeading,
  creatorCaseType,
  bottomHeading,
  willTakeType,
}) {
  const [creator, setcreator] = useState(creatorCaseType.join(", "));
  const [partner, setpartner] = useState(willTakeType.join(", "));
  const [opentop, setopentop] = useBoolean()
  const [openbottom, setopenbottom] = useBoolean()
  const noOfCh = 50;
  return (
    <Box align={'left'} h={'184px'}>
      <Box mb={"24px"}>
        <Text
          fontWeight={"800"}
          fontSize={"20px"}
          lineHeight={"26px"}
          color={"gray.800"}
          mb={"12px"}
        >
          {topHeading}
        </Text>
        <Box maxH={"42px"}>
        <Text
          fontWeight={"500"}
          fontSize={"16px"}
          lineHeight={"21px"}
          color={"gray.700"}
        >
          {creator.slice(0, noOfCh)}
          {creator.length > noOfCh ? (
            <Casepopover open={opentop} setopen={setopentop} content={creator}/>
          ) : null}
        </Text>
        </Box>
      </Box>
      <Box>
        <Text
          fontWeight={"800"}
          fontSize={"20px"}
          lineHeight={"26px"}
          color={"gray.800"}
          mb={"12px"}
        >
          {bottomHeading}
        </Text>
        <Box maxH={"42px"}>
        <Text
          fontWeight={"500"}
          fontSize={"16px"}
          lineHeight={"21px"}
          color={"gray.700"}
        >
          {partner.slice(0, noOfCh)}
          {partner.length > noOfCh ? (
            <Casepopover open={openbottom} setopen={setopenbottom} content={partner}/>
          ) : null}
        </Text>
        </Box>
      </Box>
    </Box>
  );
}

export default Mockinfo;

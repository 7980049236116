import { useState, useEffect } from 'react'
import Anonymous from '../components/AppBar/Anonymous'
import {
  Button,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Text,
  Stack,
  Image,
  Box,
  VStack,
  Flex,
} from "@chakra-ui/react";
import FindPartnerSection from '../components/LandingPage/FindPartnerSection';
import Overview from '../components/LandingPage/Overview';
import WhyJoin from '../components/LandingPage/WhyJoin';
import LevelSection from '../components/LandingPage/LevelSection';
import JoinedBy from '../components/LandingPage/JoinedBy';
import baseUrl from '../constants/urls'
import { useNavigate } from 'react-router-dom'
import Loader from '../static/Loader';
import { useAuth } from '../context/AuthContext';
import { amplitude } from "../analytics/analytics";
import source from '../static/images/Landing page image foreground.jpeg'
import Footer from '../components/footer/Footer'
import landing_last from '../static/images/landing_last.svg'
import freeBox from '../static/images/Frame 54 1.svg'
import GiftBox from '../static/images/giftBox.svg'

function Landingpage() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { currentUser, signInwithGoogle, userStatus } = useAuth()
  const navigate = useNavigate()
  const [loading, setloading] = useState(true)
  useEffect(() => {
    if (currentUser !== null && userStatus !== null) {
      if (userStatus === "submitted") {
        navigate(`${baseUrl}submitted`, { replace: true });
        amplitude.track('Log In by submitted user');
      } else if (userStatus === "verified") {
        navigate(`${baseUrl}dashboard`, { replace: true });
        amplitude.track('Log In by verified user');
      } else if (userStatus === "unverified") {
        setloading(false);
        amplitude.track('Log In by unverified user');
      }
    }
    else{
      amplitude.track('Someone landed');
    }
  }, [currentUser, userStatus]);

  useEffect(() => {

    if (!localStorage.getItem('user')) {
      setloading(false)
    }

  }, []);


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Anonymous signUp={onOpen} setloading={setloading} />
          <Center
            
            pb={'74px'}
          >
            <Stack
              direction={["column", "column", "column", "column", "row"]}
            >
              <VStack w={["295px", "295px", "688px", "800px", "600px", "800px"]} gap={'40px'}>
                <Text
                  fontSize={{ base: "28px", sm: "58px" }}
                  lineHeight={"132%"}
                  fontWeight={"800"}
                  color={"#2D3748"}
                  mt={["72px", "72px", "112px", "112px", "69px", "69px"]}
                >
                  Ace your product management career.
                </Text>
                {currentUser ? (
                  <Image src={freeBox} w={"310px"} h={"160px"} my={"60px"} />
                ) : (
                  <Text
                    fontSize={{ base: "20px", sm: "28px" }}
                    lineHeight={"130%"}
                    fontWeight={"500"}
                    // my={["36px", "36px", "65px", "65px", "65px", "65px"]}
                    color={"gray.600"}
                  >
                    Find peers, practice problem-solving, earn badges, and
                    rise to monthly challenges!
                  </Text>
                )}

                <Flex
                  align={'flex-start'}
                  
                  gap={5}
                  flexDir={'column'}
                  alignSelf={'flex-start'}
                  w={{base:'100%', md:'480px'}}
                >
                  {currentUser ? (
                    <Center w={"130px"}>
                      <Button
                        size={"lg"}
                        onClick={() => {
                          navigate(`${baseUrl}profileinfo`);
                        }}
                      >
                        Continue
                      </Button>
                    </Center>
                  ) : (
                    <Center w={"252px"}>
                      <Button size={"lg"} onClick={onOpen}>
                        Start practicing. It’s free!
                      </Button>
                    </Center>
                  )}
                  <JoinedBy />
                  {currentUser ? null : (
                    <Flex
                      gap={'12px'}
                      p={'8px 12px'}
                      bg={'#F3FCBB'}
                      alignItems={'center'}
                      borderRadius={'8px'}
                    >
                     
                        <Image
                          src={GiftBox}
                          w={'32px'}
                          h={'44px'}
                        />
                     
                      <Text
                        fontSize={{ base: "14px", sm: "14px" }}
                        // w={["295px", "295px", "484px", "484px", "484px", "484px"]}
                        lineHeight={"130%"}
                        fontWeight={"500"}
                        color={"gray.600"}
                      
                      >
                        Submit your profile to unlock a free case study on “How can one improve Google Play Store’s Revenues”
                      </Text>
                    </Flex>
                  )}
                </Flex>

              </VStack>
              <Center pt={["66px", "66px", "100px", "100px", "28px", "28px"]}>
                <Image
                  src={source}
                  w={["306px", "306px", "618px", "618px", "618px", "618px"]}
                  h={["298px", "298px", "618px", "610px", "610px", "610px"]}
                />
              </Center>
            </Stack>
          </Center>

          {/* Changes Start from here done on 14-11-2023 by Rakesh Dhariwal */}

          {/*level section its a game */}
          <LevelSection />
          {/* Find partner and companies section */}
          <FindPartnerSection />
          {/* Overview section */}
          <Overview />
          {/* Why join section */}
          <WhyJoin />

          {/* Changes end here done on 14-11-2023 by Rakesh Dhariwal */}

          <Center
            mb={["112px", "112px", "112px", "112px", "160px", "160px"]}
            mt={["112px", "112px", "112px", "112px", "180px", "180px"]}
          >
            <Center
              w={["295px", "295px", "728px", "823px", "1188px", "1188px"]}
              h={["492px", "492px", "713px", "713px", "488px", "488px"]}
              bg={"orange.50"}
              borderRadius={"32px"}
            >
              <Stack
                direction={[
                  "column-reverse",
                  "column-reverse",
                  "column-reverse",
                  "column-reverse",
                  "row",
                  "row",
                ]}
                spacing={["56px", "56px", "65px", "65px", "0px", "0px"]}
              >
                <Box>
                  <Text
                    fontSize={[
                      "24px",
                      "24px",
                      "48px",
                      "48px",
                      "48px",
                      "48px",
                    ]}
                    lineHeight={"120%"}
                    fontWeight={"700"}
                    color={"yellow.600"}
                    w={["231px", "231px", "502px", "502px", "502px", "502px"]}
                    mb={"68px"}
                  >
                    Done with courses and webinars? Start practicing now!
                  </Text>
                  {currentUser ? (
                    <Button
                      size={"lg"}
                      bgColor={"yellow.600"}
                      onClick={() => {
                        navigate(`${baseUrl}profileinfo`);
                      }}
                    >
                      Continue
                    </Button>
                  ) : (
                    <Button
                      size={"lg"}
                      onClick={onOpen}
                      bgColor={"yellow.600"}
                    >
                      Start practicing. It’s free!
                    </Button>
                  )}
                </Box>
                <Box>
                  <Image
                    src={landing_last}
                    w={["227px", "227px", "431px", "431px", "547px", "547px"]}
                    h={["117px", "117px", "222px", "222px", "282px", "282px"]}
                  />
                </Box>
              </Stack>
            </Center>
          </Center>
          <Footer />
          <Modal size={"lg"} onClose={onClose} isOpen={isOpen} isCentered>
            <ModalOverlay />
            <ModalContent px={"24px"} py={"32px"} borderRadius={"24px"}>
              <ModalBody>
                <Stack
                  direction={"column"}
                  alignItems={"center"}
                  spacing={"24px"}
                >
                  <Text
                    fontSize={"30px"}
                    lineHeight={"36px"}
                    fontWeight={"700"}
                    color={"gray.900"}
                  >
                    Get free access to Pearmock
                  </Text>
                  <Image src={freeBox} w={"310px"} h={"160px"} />
                  <Button
                    onClick={() => {
                      signInwithGoogle()
                        .then(() => {
                          setloading(true);
                          localStorage.setItem("user", true);
                          navigate(`${baseUrl}profileinfo`);
                        })
                        .catch((err) => {
                          alert(err.message.split("/")[1].slice(0, -2));
                        });
                    }}
                  >
                    Sign Up with Google
                  </Button>
                </Stack>
                <Center>
                  <Text
                    fontSize={"12px"}
                    lineHeight={"16px"}
                    fontWeight={"400"}
                    color={"gray.500"}
                    pt={"8px"}
                  >
                    By signing up, you agree with the{" "}
                    <Text as={"span"} color={"red"}>
                      Terms and Conditions
                    </Text>
                  </Text>
                </Center>
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  );
}

export default Landingpage

import React from 'react'

export default function Level2SVG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M29.998 7.7807C42.7557 7.7807 53.098 18.2396 53.098 31.1413C53.098 44.043 42.7557 54.502 29.998 54.502C17.2403 54.502 6.89801 44.0431 6.89801 31.1413C6.89801 18.2395 17.24 7.7807 29.998 7.7807Z" fill="#B5D209"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M30.0823 5.40149C42.7934 5.40149 53.0978 15.8205 53.0978 28.6729C53.0978 41.5253 42.7934 51.9443 30.0823 51.9443C17.3712 51.9443 7.06683 41.5253 7.06683 28.6729C7.06683 15.8205 17.3712 5.40149 30.0823 5.40149Z" fill="#CBE81B"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M30.2358 10.67C40.154 10.67 48.1943 18.7667 48.1943 28.7545C48.1943 38.7423 40.1543 46.8391 30.2358 46.8391C20.3173 46.8391 12.2774 38.742 12.2774 28.7541C12.2774 18.7662 20.3177 10.67 30.2358 10.67Z" fill="#B5D209"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M31.7476 17.8324C29.4104 17.2679 27.0745 21.0399 26.5592 23.0212C25.4006 27.4765 24.9283 27.4853 23.3474 28.9512C18.5914 33.3617 21.1697 42.6279 28.7828 44.0235C35.0787 45.1777 39.5562 38.73 39.16 34.3872C38.5839 28.0784 34.825 29.3842 33.8833 24.2151C33.1355 20.1126 34.302 18.4493 31.7476 17.8324Z" fill="#CBE81B"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M28.5358 44.0236C34.8316 45.1777 39.5558 38.7295 39.1596 34.3872C38.5835 28.0784 34.8246 29.6313 33.8829 24.4622C31.5335 23.679 26.5585 27.3467 31.7476 31.6692C34.0982 33.6273 35.33 39.8643 30.0182 41.8C29.0864 42.1395 24.3768 44.1061 21.3709 37.3524C22.7709 41.4292 25.1619 43.4051 28.5358 44.0236Z" fill="#A8C308"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M32.983 17.5853C32.983 17.5853 32.8471 14.3732 35.9477 14.3732C37.9613 14.3732 38.7767 13.3725 38.6655 14.1261C38.5543 14.8797 36.4419 19.8585 32.983 17.5853Z" fill="#A1BC00"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M32.4888 17.0911C32.4888 17.0911 33.193 15.0527 30.2652 14.8673C30.6482 17.5977 31.8959 17.19 32.4888 17.0911Z" fill="#A1BC00"/>
    <path opacity="0.25" fillRule="evenodd" clipRule="evenodd" d="M30.0823 5.40149C42.7934 5.40149 53.0978 15.8205 53.0978 28.6729C53.1047 31.7627 52.4977 34.8231 51.312 37.6764C50.1264 40.5296 48.3857 43.119 46.1911 45.294C13.9758 12.264 46.1986 45.2409 13.8881 12.1368C16.0097 10.0013 18.5328 8.30679 21.3122 7.15083C24.0915 5.99487 27.0722 5.40034 30.0823 5.40149Z" fill="#FFFFF0"/>
  </svg>
  )
}

import { Box, Image, Text } from '@chakra-ui/react'
import source from '../../../static/images/8 1.svg'

function NoContentRecent2() {
  return (
    <Box
    borderRadius={"24px"}
    w={"328px"}
    h={"264px"}
    p={"24px"}
    bg={'blue.50'}
  >
    <Image src={source} w={'100px'} h={'82px'}/>
    <Text fontSize={'20px'} lineHeight={'120%'} fontWeight={'700'} color={'blue.700'} mt={'23px'} mb={'12px'}>Earn badges by practicing regularly</Text>
    <Text fontSize={'14px'} lineHeight={'125%'} fontWeight={'500'} color={'gray.700'}>Earn <Text as={'b'}>20 points</Text> for every mock interview completed. Your first level is just 60 points away from you! Start practicing today.</Text>
  </Box>
  )
}

export default NoContentRecent2
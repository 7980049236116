import React from 'react';
import { Box, Flex, Heading, Progress, Text, SimpleGrid, VStack } from '@chakra-ui/react';
import LevelBG from '../../static/images/level_bg.png';
import Level1Svg from './svg/level/level1Svg';
import Level2Svg from './svg/level/level2Svg';
import Level3Svg from './svg/level/level3Svg';
import Level4Svg from './svg/level/level4Svg';
import Level5Svg from './svg/level/level5Svg';
const levels = [
    { id: '01', name: 'Budding Pear', description: 'Start practising and gain experience', progress: 5, Badge: Level1Svg },
    { id: '02', name: 'Green Pear', description: 'Keep practising, unlock user profiles start networking', progress: 25, Badge: Level2Svg },
    { id: '03', name: 'Ripening Pear', description: 'Start contributing content in the community and build your public profile', progress: 50, Badge: Level3Svg },
    { id: '04', name: 'Golden Pear', description: 'Monetise your profile & make a quick side income', progress: 75, Badge: Level4Svg },
    { id: '05', name: 'Pearfect', description: 'Get goodies and interview opportunities!', progress: 100, Badge: Level5Svg },
];

const LevelCard = ({ id, name, description, progress, Badge }) => (
    <Flex
        p={'32px 32px'}
        bg={'#2E3504'}
        maxW={{ base: "100%", md: "400px" }}
        height={{ base: 'auto', md: '214px' }}
        borderRadius='24px'
        direction={'column'}
        gap={'10px'}
    >
        <Flex
            gap={'12px'}
            height={'100%'}
        >
            <Box
                w='78px'
                h='100px'
            >
                <Badge />
            </Box>
            <VStack

                alignItems={'flex-start'}
               
            >
                <Text
                    fontSize={'14px'}
                    fontWeight={400}
                    
                >
                    LEVEL {id}
                </Text>
                <Heading
                    fontSize={'22px'}
                    my={2}
                >
                    {name}
                </Heading>
                <Text
                    fontSize={{ base: '14px', md: '14px' }}
                    fontWeight={400}
                    mb={4}
                >
                    {description}
                </Text>
            </VStack>

        </Flex>

        <Progress
            value={progress}
            borderRadius={'full'}
            bg={'#CBE81B'}
            colorScheme='red'
        />
    </Flex>
);

const LevelSection = () => (
    <Box
        // p={{ base: '100px 10px', md: '120px 150px' }}
        px={{ base: 0, md: '39px' }}

    >
        <Box
            bg="green.800"
            color="white"
            px={{ base: 3, md: 5 }}
            py={'74px'}
            bgImage={`url(${LevelBG})`}
            bgSize={{base:"unset", md:"cover"}}
            borderRadius={'24px'}
        >
            <Heading 
                mb={'74px'} 
                px={'20px'}
                fontSize={{ base: '32px', md: '54px' }} 
                fontWeight={700} 
                color={'#303603'}
            >
                Level up with your activity and get bonuses!
            </Heading>
            <Flex
                wrap="wrap"
                justifyContent="center"
                alignItems={'flex-start'}
                gap={'20px'}
            >

                {
                    levels.map((level, index) => {
                        return (
                            <LevelCard
                                key={`${index}-${level.id}`}
                                id={level.id}
                                level={level.name}
                                name={level.name}
                                description={level.description}
                                Badge={level.Badge}
                                progress={level.progress}
                            />
                        )
                    })
                }
            </Flex>
        </Box>
    </Box>

);

export default LevelSection;

import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase';
import { collection, query, getDocs, limit, orderBy, startAfter} from 'firebase/firestore';
import { Button, Flex, Spinner } from '@chakra-ui/react';
import SingleUserCard from './SingleUserCard';
import LoadingUsersSkeleton from './LoadingUsersSkeleton';
let last = null; 
export default function Users() {

  
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const fetchUsers = async () => {
    try {
      const usersRef = collection(db, 'users');
      let q;
      if(!last){
        // set first time loading true
        setIsLoading(true);
        q = query(
          usersRef,
          orderBy('levelMock', 'desc'),
          orderBy('verifiedAt', 'asc'),
          limit(12)
        );
      }
      else {
        setIsLoadingMore(true);
        q = query(
          usersRef,
          orderBy('levelMock', 'desc'),
          orderBy('verifiedAt', 'asc'),
          startAfter(last),
          limit(12)
        );
      }
      const querySnapshot = await getDocs(q);
      
      const usersData = querySnapshot.docs.map(doc =>{
        last = doc;
        return ({
          id: doc.id,
          ...doc.data()
        })
      } 
      );
      setIsLoadingMore(false);
      setIsLoading(false);
      if(usersData.length<12) setHasMore(false);
      setUsers([...users, ...usersData]);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }
  useEffect(() => {
    fetchUsers();
    return ()=>(last = null)
  }, [])
  return (
    <Flex
      flexDirection={'column'}
      gap={'60px'}
    >
      <Flex
        gap={'24px'}
        flexWrap={'wrap'}
        justifyContent={'center'}
      >
        {
          isLoading && <LoadingUsersSkeleton />
        }
        {
         !isLoading && users.map((user, index) => {
            return (
              <SingleUserCard
                user={user}
                key={`${user.id}-${index}`}
              />
            )
          })
        }
      </Flex>

      {hasMore && !isLoading && <Button
        fontWeight={700}
        color={'gray.700'}
        onClick={fetchUsers}
        variant='outline'
        colorScheme={"gray"}
        border={'2px solid #718096'}
        borderRadius={'6px'}
        maxW={'92px'}
        p={'24px'}
        h={'28px'}
        alignSelf={'center'}
        disabled={isLoadingMore}
      >
        {isLoadingMore?<Spinner/>:'Load More'}
      </Button>}
    </Flex>


  )
}

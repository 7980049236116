import { Flex, VStack, Box, Text} from '@chakra-ui/react';
const LevelContainer = ({ name, desc, Logo }) => {
    return (
        <Flex
            px={'25px'}
            py={'16px'}
            gap={'20px'}
            bg={'gray.200'}
            borderRadius={'16px'}
            border={'8px solid #EDF2F7'}
            alignItems={'center'}
            w={'100%'}
        >
            <Box
                w='60px'
                h='60px'
            >
                <Logo />
            </Box>
            <VStack
                gap={0}
                w={'100%'}
                alignItems={'flex-start'}
            >
                <Text
                    fontSize='24px'
                    fontWeight='700'
                >
                    {name}
                </Text>
                <Text
                    fontSize='16px'
                    fontWeight={400}
                >
                    {desc}
                </Text>
            </VStack>
        </Flex>
    )
}
export default LevelContainer;
import { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Stack,
  Input,
  Button,
  Center,
  Skeleton,
  SkeletonCircle,
  Divider,
  Wrap,
  WrapItem,
  useDisclosure,
  Collapse,
  HStack,
  useCheckboxGroup,
  useToast,
  Switch,
  FormControl,
  FormLabel
} from "@chakra-ui/react";
import Joincard from "./joinmock/Joincard";
import Created from "./created/Created";
import Upcomingcard from "./upcoming/Upcomingcard";
import Pastcard from "./past/Pastcard";
import { db } from "../../../firebase";
import Slotclash from "../../../utilFunctions/Slotclash";
import {FilterIcon} from '../../../static/icons/FilterIcon'
import {
  query,
  where,
  collection,
  orderBy,
  limit,
  onSnapshot,
  startAfter,
} from "firebase/firestore";
import CheckboxComponent from "../common/CheckboxComponent";
import {productcase} from '../../../constants/category'
import { useSearchParams } from "react-router-dom";

function Right({ userdata, user, timings}) {
  const toast = useToast()
  const [searchParams, setsearchParams] = useSearchParams();
  const { value:productFilter, setValue:setproductFilter, getCheckboxProps } = useCheckboxGroup();
  const [pastMocks, setpastMocks] = useState([]);
  const [createdMocks, setcreatedMocks] = useState([]);
  const [joinMocks, setjoinMocks] = useState([]);
  const [upcomingMocks, setupcomingMocks] = useState([]);
  const [lastDoc, setlastDoc] = useState(null);
  const [joinload, setjoinload] = useState(false);
  const [createdload, setcreatedload] = useState(false);
  const [upcomingload, setupcomingload] = useState(false);
  const [pastload, setpastload] = useState(false);
  const [filterCases, setfilteCases] = useState([])
  const [filterDate, setfilterDate] = useState('')
  const [isOpen, setisOpen] = useState(false)
  const [filterButtonText, setfilterButtonText] = useState('Filter')
  const { isOpen:isOpenFilter, onOpen, onClose } = useDisclosure()
  const [expired, setexpired] = useState(true)
 

  useEffect(() => {
    if(productFilter.length > 0){
      setfilteCases(productFilter)
    }
    else{
      setfilteCases([])
    }
  }, [productFilter])

  function checkLevel(level) {
    if (level === 0 || level === 1) {
      return "T1";
    } else if (level === 2) {
      return "T2";
    } else if (level === 3) {
      return "T3";
    } else if (level === 4) {
      return "T4";
    } else if (level === 5) {
      return "T5";
    } else {
      return "T3";
    }
  }

  useEffect(() => {
    if ([...searchParams].length) {
      const id = searchParams.get("id");
      const eventRef = collection(db, "events");
      const q = query(eventRef, where("eventId", "==", id),where("eventStatus","==","unpaired"));
      onSnapshot(q, (querySnap) => {
        const referredMock = querySnap.docs.map((doc) => {
          console.log(doc.data())
          return doc.data();
        });
        if(referredMock[0] !== undefined){
          setjoinMocks([{...referredMock[0], referred: true},...joinMocks])
        }
        else{
          toast({
            title: 'Mock already joined!',
            description: "This mock is already joined and is not available to pair now. Try joining other mocks from Join mock tab",
            status: 'error',
            duration: 4500,
            isClosable: true,
          })
        }
      });
    }
  }, []);

  function expiredArray(expired){
    if(expired == true){
      return [false, true]
    }
    else{
      return [false]
    }
  }


  function filter() {
    setfilterButtonText(filterCases.length == 0? `Filter`:`${filterCases.length} filter`)
    setisOpen(false)
    onClose(true)
    const eventsRef = collection(db, "events");
    if (filterCases.length !== 0 && filterDate === '') {
      console.log(filterCases);
      const q = query(
        eventsRef,
        where("eventStatus", "==", "unpaired"),
        where("past", "in", expiredArray(expired)),
        where(checkLevel(userdata.level), "==", true),
        where("willTakeType", "array-contains-any", filterCases),
        orderBy("eventDateTime","desc"),
        limit(10)
      );
      onSnapshot(q, (querySnap) => {
        setjoinMocks(
          querySnap.docs.map((doc) => {
            return doc.data();
          })
        );
        setlastDoc(querySnap.docs[querySnap.docs.length - 1]);
      });
    } else if (filterCases.length === 0 && filterDate !== '') {
      const startDate = new Date(`${filterDate}T00:00:00`);
      const endDate = new Date(`${filterDate}T00:00:00`);
      endDate.setDate(endDate.getDate() + 1);
      const q = query(
        eventsRef,
        where("eventStatus", "==", "unpaired"),
        where("past", "in", expiredArray(expired)),
        where(checkLevel(userdata.level), "==", true),
        where("eventDateTime", ">=", startDate),
        where("eventDateTime", "<", endDate),
        orderBy("eventDateTime","desc"),
        limit(10)
      );
      onSnapshot(q, (querySnap) => {
        setjoinMocks(
          querySnap.docs.map((doc) => {
            return doc.data();
          })
        );
        setlastDoc(querySnap.docs[querySnap.docs.length - 1]);
      });
    } else if (filterCases.length !== 0 && filterDate !== '') {
      console.log(filterCases);
      const startDate = new Date(`${filterDate}T00:00:00`);
      const endDate = new Date(`${filterDate}T00:00:00`);
      endDate.setDate(endDate.getDate() + 1);
      const q = query(
        eventsRef,
        where("eventStatus", "==", "unpaired"),
        where("past", "in", expiredArray(expired)),
        where(checkLevel(userdata.level), "==", true),
        where("willTakeType", "array-contains-any", filterCases),
        where("eventDateTime", ">=", startDate),
        where("eventDateTime", "<", endDate),
        orderBy("eventDateTime","desc"),
        limit(10)
      );
      onSnapshot(q, (querySnap) => {
        setjoinMocks(
          querySnap.docs.map((doc) => {
            return doc.data();
          })
        );
        setlastDoc(querySnap.docs[querySnap.docs.length - 1]);
      });
    }
    else{
      const q = query(
        eventsRef,
        where("eventStatus", "==", "unpaired"),
        where("past", "in", expiredArray(expired)),
        where(checkLevel(userdata.level), "==", true),
        orderBy("eventDateTime","desc")
      );
      onSnapshot(q, (querySnap) => {
        setjoinMocks(
          querySnap.docs.map((doc) => {
            return doc.data();
          })
        );
        setlastDoc(querySnap.docs[querySnap.docs.length - 1]);
      });
    }
    setjoinload(true)
  }

  function filterById(obj) {
    if (obj.creatorUid !== user.uid) {
      return true;
    } else {
      return false;
    }
  }

  function slotCheck(eventTime) {
    if (timings.length === 0) {
      return true;
    } else {
      var slot = true;
      timings.map((timevalue) => {
        if (!Slotclash(eventTime, timevalue)) {
          slot = false;
          return;
        }
      });
      return slot;
    }
  }

  function handleTabChange(index) {
    if (index === 1 && createdMocks.length === 0) {
      /* The query for fetching created mocks */
      const eventRef = collection(db, "events");
      const q = query(
        eventRef,
        where("creatorUid", "==", user.uid),
        where("eventStatus", "==", "unpaired"),
        where("past", "==", false),
        orderBy("eventDateTime")
      );
      onSnapshot(q, (querySnap) => {
        setcreatedMocks(
          querySnap.docs.map((doc) => {
            return doc.data();
          })
        );
        setcreatedload(true)
      });
    } else if (index === 2 && upcomingMocks.length === 0) {
      /* The query for fetching upcoming mocks */
      const eventRef = collection(db, "events");
      const q = query(
        eventRef,
        where("ids", "array-contains", user.uid),
        where("eventStatus", "==", "upcoming"),
        where("past", "==", false),
        orderBy("eventDateTime")
      );
      onSnapshot(q, (querySnap) => {
        setupcomingMocks(
          querySnap.docs.map((doc) => {
            return doc.data();
          })
        );
        setupcomingload(true)
      });
    } else if (index === 3 && pastMocks.length === 0) {
      /* The query for fetching past mocks */
      const eventRef = collection(db, "events");
      const q = query(
        eventRef,
        where("ids", "array-contains", user.uid),
        where("eventStatus", "==", "past"),
        where("past", "in", [
          true,
          "inReview",
          "notComplete",
          "Complete",
          "FlaggedComplete",
          "FlaggedNotComplete",
        ]),
        orderBy("eventDateTime", "desc")
      );
      onSnapshot(q, (querySnap) => {
        setpastMocks(
          querySnap.docs.map((doc) => {
            return doc.data();
          })
        );
        setpastload(true)
      });
    }
  }

  function paginate() {
    if (lastDoc !== undefined) {
      const eventsRef = collection(db, "events");
      if (filterCases.length !== 0 && filterDate === '') {
        const q = query(
          eventsRef,
          where("eventStatus", "==", "unpaired"),
          where("past", "in", expiredArray(expired)),
          where(checkLevel(userdata.level), "==", true),
          where("willTakeType", "array-contains-any", filterCases),
          orderBy("eventDateTime"),
          startAfter(lastDoc),
          limit(4)
        );
        onSnapshot(q, (querySnap) => {
          querySnap.docs.map((doc) => {
            setjoinMocks((arr) => arr.concat(doc.data()));
          });
          setlastDoc(querySnap.docs[querySnap.docs.length - 1]);
        });
      } else if (filterCases.length === 0 && filterDate !== '') {
        const startDate = new Date(`${filterDate}T00:00:00`);
        const endDate = new Date(`${filterDate}T00:00:00`);
        endDate.setDate(endDate.getDate() + 1);
        const q = query(
          eventsRef,
          where("eventStatus", "==", "unpaired"),
          where("past", "in", expiredArray(expired)),
          where(checkLevel(userdata.level), "==", true),
          where("eventDateTime", ">=", startDate),
          where("eventDateTime", "<", endDate),
          orderBy("eventDateTime"),
          startAfter(lastDoc),
          limit(4)
        );
        onSnapshot(q, (querySnap) => {
          querySnap.docs.map((doc) => {
            setjoinMocks((arr) => arr.concat(doc.data()));
          });
          setlastDoc(querySnap.docs[querySnap.docs.length - 1]);
        });
      } else if (filterCases.length !== 0 && filterDate !== '') {
        console.log(filterCases);
        const startDate = new Date(`${filterDate}T00:00:00`);
        const endDate = new Date(`${filterDate}T00:00:00`);
        endDate.setDate(endDate.getDate() + 1);
        const q = query(
          eventsRef,
          where("eventStatus", "==", "unpaired"),
          where("past", "in", expiredArray(expired)),
          where(checkLevel(userdata.level), "==", true),
          where("willTakeType", "array-contains-any", filterCases),
          where("eventDateTime", ">=", startDate),
          where("eventDateTime", "<", endDate),
          orderBy("eventDateTime"),
          startAfter(lastDoc),
          limit(4)
        );
        onSnapshot(q, (querySnap) => {
          querySnap.docs.map((doc) => {
            setjoinMocks((arr) => arr.concat(doc.data()));
          });
          setlastDoc(querySnap.docs[querySnap.docs.length - 1]);
        });
      }
      else {
        const q = query(
          eventsRef,
          where("eventStatus", "==", "unpaired"),
          where("past", "in", expiredArray(expired)),
          where(checkLevel(userdata.level), "==", true),
          orderBy("eventDateTime"),
          startAfter(lastDoc),
          limit(4)
        );
        onSnapshot(q, (querySnap) => {
          querySnap.docs.map((doc) => {
            setjoinMocks((arr) => arr.concat(doc.data()));
          });
          setlastDoc(querySnap.docs[querySnap.docs.length - 1]);
        });
      }
    }
  }

  function clearfilter(){
    setproductFilter([])
    setfilterDate('')
    setfilterButtonText('Filter')
    const eventsRef = collection(db, "events");
    const q = query(
      eventsRef,
      where("eventStatus", "==", "unpaired"),
      where("past", "in", expiredArray(expired)),
      where(checkLevel(userdata.level), "==", true),
      orderBy("eventDateTime","desc")
    );
    onSnapshot(q, (querySnap) => {
      setjoinMocks(
        querySnap.docs.map((doc) => {
          return doc.data();
        })
      );
      setlastDoc(querySnap.docs[querySnap.docs.length - 1]);
    });
    setisOpen(false)
    onClose(true)
    setjoinload(true)
  }

  function today() {
    const date = new Date();
    const datestring =
      date.toLocaleDateString(undefined, { year: "numeric" }) +
      "-" +
      date.toLocaleDateString(undefined, { month: "2-digit" }) +
      "-" +
      date.toLocaleDateString(undefined, { day: "2-digit" });
    return datestring;
  }

  function twoMonth(){
    const date = new Date();
    date.setMonth(date.getMonth() + 2);
    const datestring =
      date.toLocaleDateString(undefined, { year: "numeric" }) +
      "-" +
      date.toLocaleDateString(undefined, { month: "2-digit" }) +
      "-" +
      date.toLocaleDateString(undefined, { day: "2-digit" });
    return datestring;
  }

  useEffect(() => {
    filter();    
  }, [expired])
  

  return (
    <Box>
      <Tabs
        isLazy={true}
        onChange={(index) => handleTabChange(index)}
        variant={"unstyled"}
      >
        <TabList>
          <Tab
            _selected={{
              color: "black",
              backgroundColor: "gray.200",
              borderRadius: "8px",
            }}
          >
            <Text fontSize={"16px"} lineHeight={"24px"} fontWeight={"600"}>
              Join
            </Text>
          </Tab>
          <Tab
            _selected={{
              color: "black",
              backgroundColor: "gray.200",
              borderRadius: "8px",
            }}
          >
            <Text fontSize={"16px"} lineHeight={"24px"} fontWeight={"600"}>
              Created ({Object.keys(userdata.createdMocks).length})
            </Text>
          </Tab>
          <Tab
            _selected={{
              color: "black",
              backgroundColor: "gray.200",
              borderRadius: "8px",
            }}
          >
            <Text fontSize={"16px"} lineHeight={"24px"} fontWeight={"600"}>
              Upcoming ({Object.keys(userdata.upcomingMocks).length})
            </Text>
          </Tab>
          <Tab
            _selected={{
              color: "black",
              backgroundColor: "gray.200",
              borderRadius: "8px",
            }}
          >
            <Text fontSize={"16px"} lineHeight={"24px"} fontWeight={"600"}>
              Past ({userdata.pastMocks})
            </Text>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={0} pt={"24px"}>
            <HStack mb={"24px"} spacing={'12px'}>
              <Button
                leftIcon={<FilterIcon w={"24px"} h={"24px"} />}
                variant={"ghost"}
                colorScheme={"white"}
                bg={filterCases.length ? "gray.200" : "white"}
                px={"12px"}
                onClick={() => {
                  isOpen ? setisOpen(false) : setisOpen(true);
                }}
              >
                {filterButtonText}
              </Button>
              {filterCases.length ? (
                <Button
                  w={"83px"}
                  colorScheme={"gray"}
                  variant={"outline"}
                  onClick={clearfilter}
                >
                  Clear all
                </Button>
              ) : null}
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="expired" mb="0.5">
                  Show Expired Mocks
                </FormLabel>
                <Switch id="expired" isChecked={expired} onChange={(e)=>{setexpired(e.target.checked)}}/>
              </FormControl>
            </HStack>
            {/* Filter Box for big screens */}
            <Collapse in={isOpen} animateOpacity>
              <Stack
                direction={"column"}
                p={"16px"}
                bg={"gray.50"}
                spacing={"28px"}
                borderRadius={"12px"}
                mb={'24px'}
              >
                <Box>
                  <Text
                    fontSize={"20px"}
                    lineHeight={"24px"}
                    fontWeight={"500"}
                    color={"gray.700"}
                    mb={"12px"}
                  >
                    Case categories
                  </Text>
                  <CheckboxComponent
                    func={getCheckboxProps}
                    cases={productcase}
                    size={"lg"}
                  />
                </Box>
                <Box>
                  <Text
                    fontSize={"20px"}
                    lineHeight={"24px"}
                    fontWeight={"500"}
                    color={"gray.700"}
                    mb={"12px"}
                  >
                    Date
                  </Text>
                  <HStack spacing={"20px"}>
                    <Input
                      w={"164px"}
                      bg={"gray.200"}
                      type={"date"}
                      min={today()}
                      max={twoMonth()}
                      onChange={(e) => {
                        setfilterDate(e.target.value);
                      }}
                    />
                    <Button
                      size={"md"}
                      bgColor={"gray.700"}
                      w={"77px"}
                      color={"white"}
                      variant={"unstyled"}
                      onClick={filter}
                    >
                      Apply
                    </Button>
                  </HStack>
                </Box>
              </Stack>
            </Collapse>

            {joinMocks.filter(filterById).length ? (
              <>
                <Wrap spacing={"40px"}>
                  {joinMocks.filter(filterById).map((data) => {
                    return (
                      <WrapItem key={data.eventId}>
                        <Joincard
                          data={data}
                          userdata={userdata}
                          key={data.eventId}
                          user={user}
                          slot={slotCheck(data.eventDateTime)}
                        />
                      </WrapItem>
                    );
                  })}
                </Wrap>
                <Center>
                  {lastDoc !== undefined && joinMocks.length > 15 ? (
                    <Button
                      size={"sm"}
                      variant={"ghost"}
                      colorScheme={"gray"}
                      border={"1px solid #E2E8F0"}
                      mt={"38px"}
                      onClick={paginate}
                    >
                      Load More
                    </Button>
                  ) : null}
                </Center>
              </>
            ) : (
              <>
                <Box
                  w={"343px"}
                  boxShadow={
                    "0px -2px 12px -4px rgba(0, 0, 0, 0.08), 0px 4px 6px -2px rgba(45, 55, 72, 0.05), 0px 10px 15px -3px rgba(45, 55, 72, 0.1)"
                  }
                  borderRadius={"8px"}
                  p={"16px"}
                  display={joinload ? "none" : "block"}
                >
                  <Stack direction={"row"} spacing={"8px"} mb={"16px"}>
                    <SkeletonCircle size={"48px"} />
                    <Skeleton w={"250px"} />
                  </Stack>
                  <Divider />
                  <Stack direction={"column"} my={"16px"}>
                    <Skeleton w={"300px"} h={"80px"} />
                    <Skeleton w={"300px"} h={"80px"} />
                  </Stack>
                  <Divider />
                  <Skeleton w={"full"} h={"50px"} mt={"16px"} />
                </Box>
                <Center
                  fontWeight={"500"}
                  lineHeight={"28px"}
                  color={"gray.400"}
                  flexDirection={"column"}
                >
                  <Text fontSize={"20px"} mb={"24px"}>
                    High Demand 🔥
                  </Text>
                  <Text fontSize={"18px"} textAlign={'center'}>
                    All mock interviews booked. Please create a new mock interview by clicking the button above.
                  </Text>
                  <Text fontSize={"18px"} textAlign={"center"}>
                    {/* <Text
                      as={"u"}
                      color={"primary.500"}
                      cursor={"pointer"}
                      onClick={(e) => {
                        navigator.clipboard.writeText("https://pearmock.com");
                        toast({
                          title: "Copied to clipboard",
                          description:
                            "Website link is copied to your clipboard",
                          status: "success",
                          duration: 2000,
                          isClosable: true,
                        });
                      }}
                    >
                      Share
                    </Text>{" "} */}
                    You can also share your mock interview, invite your friends to Pearmock and practice together.
                  </Text>
                </Center>
              </>
            )}
          </TabPanel>
          <TabPanel p={0} py={"24px"}>
            {createdMocks.length ? (
              <Wrap spacing={"40px"}>
                {createdMocks.map((data) => {
                  return (
                    <WrapItem key={data.eventId}>
                      {" "}
                      <Created data={data} key={data.eventId} user={user} />
                    </WrapItem>
                  );
                })}
              </Wrap>
            ) : (
              <>
                <Box
                  w={"343px"}
                  boxShadow={
                    "0px -2px 12px -4px rgba(0, 0, 0, 0.08), 0px 4px 6px -2px rgba(45, 55, 72, 0.05), 0px 10px 15px -3px rgba(45, 55, 72, 0.1)"
                  }
                  borderRadius={"8px"}
                  p={"16px"}
                  display={createdload ? "none" : "block"}
                  mt={"5em"}
                >
                  <Stack direction={"column"} my={"16px"}>
                    <Skeleton w={"300px"} h={"80px"} />
                    <Skeleton w={"300px"} h={"80px"} />
                  </Stack>
                  <Divider />
                  <Skeleton w={"full"} h={"50px"} mt={"16px"} />
                </Box>
                <Center
                  fontWeight={"500"}
                  lineHeight={"28px"}
                  color={"gray.400"}
                  flexDirection={"column"}
                  pt={"32px"}
                >
                  <Text fontSize={"20px"}>
                    You don’t have any created mocks.
                  </Text>
                  <Text fontSize={"20px"} textAlign={"center"}>
                    Create a mock now!
                  </Text>
                </Center>
              </>
            )}
          </TabPanel>
          <TabPanel p={0} py={"24px"}>
            {upcomingMocks.length ? (
              <Wrap spacing={"40px"}>
                {upcomingMocks.map((data) => {
                  return (
                    <WrapItem key={data.eventId}>
                      <Upcomingcard
                        data={data}
                        key={data.eventId}
                        user={user}
                      />
                    </WrapItem>
                  );
                })}
              </Wrap>
            ) : (
              <>
                <Box
                  w={"343px"}
                  boxShadow={
                    "0px -2px 12px -4px rgba(0, 0, 0, 0.08), 0px 4px 6px -2px rgba(45, 55, 72, 0.05), 0px 10px 15px -3px rgba(45, 55, 72, 0.1)"
                  }
                  borderRadius={"8px"}
                  p={"16px"}
                  display={upcomingload ? "none" : "block"}
                >
                  <Stack direction={"row"} spacing={"8px"} mb={"16px"}>
                    <SkeletonCircle size={"48px"} />
                    <Skeleton w={"250px"} />
                  </Stack>
                  <Divider />
                  <Stack direction={"column"} my={"16px"}>
                    <Skeleton w={"300px"} h={"80px"} />
                    <Skeleton w={"300px"} h={"80px"} />
                  </Stack>
                  <Divider />
                  <Skeleton w={"full"} h={"50px"} mt={"16px"} />
                </Box>
                <Center
                  fontWeight={"500"}
                  lineHeight={"28px"}
                  color={"gray.400"}
                  flexDirection={"column"}
                  pt={"32px"}
                >
                  <Text fontSize={"20px"}>
                    You don’t have any upcoming mocks.
                  </Text>
                  <Text fontSize={"20px"} textAlign={"center"}>
                    Join a mock now!
                  </Text>
                </Center>
              </>
            )}
          </TabPanel>
          <TabPanel p={0} py={"24px"}>
            {pastMocks.length ? (
              <Wrap spacing={"40px"}>
                {pastMocks.map((data) => {
                  return (
                    <WrapItem key={data.eventId}>
                      <Pastcard data={data} key={data.eventId} user={user} />
                    </WrapItem>
                  );
                })}
              </Wrap>
            ) : (
              <>
                <Box
                  w={"343px"}
                  boxShadow={
                    "0px -2px 12px -4px rgba(0, 0, 0, 0.08), 0px 4px 6px -2px rgba(45, 55, 72, 0.05), 0px 10px 15px -3px rgba(45, 55, 72, 0.1)"
                  }
                  borderRadius={"8px"}
                  p={"16px"}
                  display={pastload ? "none" : "block"}
                >
                  <Stack direction={"row"} spacing={"8px"} mb={"16px"}>
                    <SkeletonCircle size={"48px"} />
                    <Skeleton w={"250px"} />
                  </Stack>
                  <Divider />
                  <Stack direction={"column"} my={"16px"}>
                    <Skeleton w={"300px"} h={"80px"} />
                    <Skeleton w={"300px"} h={"80px"} />
                  </Stack>
                  <Divider />
                  <Skeleton w={"full"} h={"50px"} mt={"16px"} />
                </Box>
                <Center
                  fontWeight={"500"}
                  lineHeight={"28px"}
                  color={"gray.400"}
                  flexDirection={"column"}
                  pt={"32px"}
                >
                  <Text fontSize={"20px"}>You don’t have any past mocks.</Text>
                </Center>
              </>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default Right;

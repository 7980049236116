import React from 'react';
import { Flex, Heading, Text } from '@chakra-ui/react';
import NetworkingLock from './NetworkingLock';
import Users from './Users';
export default function NetworkingZone({ user }) {
  if (!user) return <></>

  return (
    <Flex
      mt={'40px'}
      flexDirection={'column'}
      gap={'40px'}
    >
      <Flex
        gap={4}
        flexDirection={{base: 'column', md: 'row'}}
      >
        <Heading
          fontSize={{base:'32px', md:'48px'}}
          color={'gray.700'}
          fontWeight={700}
        >
          Networking Zone
        </Heading>
        <Text
          alignSelf={{md:'flex-end'}}
          color={'gray.700'}
          fontSize={'16px'}
          fontWeight={400}
          mb={{md:'10px'}}
        >
          v1.0
        </Text>
      </Flex>

      {user.level === 1 ? <NetworkingLock /> : <Users />}
    </Flex>


  )
}

import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Stack,
  Divider,
} from "@chakra-ui/react";
import Upcomingcancel from "./Upcomingcancel";
import Time from "../../common/Time";
import Partner from "../../common/Partner";
import Mockinfo from "../../common/Mockinfo";

function Upcomingcard({ data,user }) {

  console.log('Event Data', data);
  console.log('User Data', user);

  const [mock, setmock] = useState(false)
  const [open, setopen] = useState(false);
  const [buttonText, setbuttonText] = useState('Calculating...')

  useEffect(() => {
    const interval = setInterval(() => {
      const eventTime = data.eventDateTime.toDate();
      const currentTime = new Date();
      const diff = eventTime - currentTime;
      const diffHours = Math.floor((diff/1000)/3600)
      const diffDays = Math.floor(diffHours/24)
      if(diffDays>1){
        setbuttonText(`${diffDays} days to go`)
      }
      else if(diffDays === 1){
        setbuttonText(`${diffDays} day to go`)
      }
      else{
        if(diffHours>1){setbuttonText(`${diffHours} hours to go`)}
        else if(diffHours===1){setbuttonText(`${diffHours} hour to go`)}
        else{setmock(true)}
      }

    }, 1000);
    return () => clearInterval(interval);
  }, [data]);
  
  const info = {
    open: open,
    setopen: setopen,
    data: data,
    user: user
  };

  function joinmeet() {
    window.open(data.eventLink);
  }

  const timeprops = {
    datetime: data.eventDateTime.toDate(),
  };

  const partnerName = (data.creatorUid === user.uid)?(data.partnerFirstName):(data.creatorFirstName)
  const partnerEmail = (data.creatorUid === user.uid)?(data.partnerEmail):(data.creatorEmail)
  const partnerlevel = (data.creatorUid === user.uid)?(data.partnerLevel):(data.creatorLevel)
  const partnerLinkedin = (data.creatorUid === user.uid)?(data.partnerLinkedin):(data.creatorLinkedin)
  const partnerMocksCompleted = (data.creatorUid === user.uid)?(data.partnerMocksCompleted):(data.creatorMocksCompleted)
  // const partnerwork = (data.creatorUid === user.uid)?(data.partnerBio):(data.creatorBio)
  const partnerImage = (data.creatorUid === user.uid)?(data.partnerAvatarUrl):(data.creatorAvatarUrl)
  const partnerEducation = (data.creatorUid === user.uid)?(data.partnerEducationCollegeName):(data.creatorEducationCollegeName)
  const partnerCurrentJobRole = (data.creatorUid === user.uid)?(data.partnerCurrentJobRole):(data.creatorCurrentJobRole)
  const partnerCurrentCompany = (data.creatorUid === user.uid)?(data.partnerCurrentCompany):(data.creatorCurrentCompany)
  const partnerPhoneNumber = (data.creatorUid === user.uid)?(data.partnerPhoneNumber):(data.creatorPhoneNumber);
  // const partnerTargetCompanies = (data.creatorUid === user.uid)?(data.partnerTargetCompanies):(data.creatorTargetCompanies)
  // const partnerTargetProfile = (data.creatorUid === user.uid)?(data.partnerTargetProfile):(data.creatorTargetProfile)
  const partnerWorkExperience = (data.creatorUid === user.uid)?(data.partnerWorkExperience):(data.creatorWorkExperience)
  const youPracticeCaseType = (data.creatorUid === user.uid)?(data.creatorCaseType):(data.partnerCaseType)
  const youTakeCaseType = (data.creatorUid === user.uid)?(data.partnerCaseType):(data.creatorCaseType)
  

  const partnerprops = {
    partnerName: partnerName,
    partnerEmail: partnerEmail,
    partnerLinkedin: partnerLinkedin,
    partnerMocksCompleted: partnerMocksCompleted,
    partnerImage: partnerImage,
    partnerEducation: partnerEducation,
    partnerCurrentJobRole: partnerCurrentJobRole,
    partnerCurrentCompany:partnerCurrentCompany,
    partnerWorkExperience: partnerWorkExperience,
    partnerLevel: partnerlevel
  };

  const mockinfo = {
    topHeading: "You will practice",
    creatorCaseType: youPracticeCaseType,
    bottomHeading: "You will take",
    willTakeType: youTakeCaseType,
  }

  return (
    <>
      <Box
        w={"343px"}
        h={"470px"}
        borderRadius={'24px'}
        px={'24px'}
        py={'28px'}
        backgroundColor={"#F2F7D9"}
      >
        <>
          <Partner {...partnerprops} />
          <Divider borderColor={"white"} borderWidth={"2px"} my={'20px'}/>
          <Mockinfo {...mockinfo} />
          <Divider borderColor={"white"} borderWidth={"2px"} my={'20px'}/>
          <Stack direction={"column"}>
            <Time {...timeprops} />
            {mock ? (
              <Button size={"md"} onClick={joinmeet}>
                Start now!
              </Button>
            ) : (
              <Box display={"flex"} justifyContent={"space-between"}>
                <Button size={"md"} w={"full"} isDisabled>
                  {buttonText}
                </Button>
                <Button
                  size={"md"}
                  w={"full"}
                  variant={"ghost"}
                  colorScheme={"secondary"}
                  bg={"#E7F490"}
                  ml={"10px"}
                  onClick={() => {
                    setopen(true);
                  }}
                >
                  Cancel Mock
                </Button>
              </Box>
            )}
          </Stack>
        </>
      </Box>

      {open && <Upcomingcancel {...info} />}
    </>
  );
}

export default Upcomingcard;

import React from 'react'
import { Flex, Text, Image, VStack, Box } from '@chakra-ui/react';
import Institute from '../../../static/images/institute.svg';
import UserCompany from '../../../static/images/usercompany.svg';
import TimeSvg from '../../../static/images/time.svg'
import LinkedInSM from '../../../static/images/linkedinsm.svg';
import EmailSM from '../../../static/images/emailsm.svg';
import { Link } from '@chakra-ui/react';
const LevelNames = ['Budding Pear', 'Green Pear', 'Ripening Pear', 'Golden Pear', 'Pearfect']
const ContainerWithImageAndText = ({ image, text }) => {
    return (<Flex
        gap={'8px'}
        alignItems={'center'}
    >
        <Image
            src={image}
        />
        {text && <Text
            color={'#3E4706'}
            fontSize={'14px'}
            fontWeight={600}
        >
            {text}
        </Text>}
        {
            !text && <Text
                color={'#A8BB35'}
                fontSize={'14px'}
                fontWeight={400}
            >
                NOT ADDED
            </Text>
        }

    </Flex>)
}
const Footer = ({ email, linkedinUrl, points }) => {
    return (
        <Flex
            justifyContent={'space-between'}
           
        >
            <Flex
                gap={'8px'}
            >
                <Link
                    href={linkedinUrl}
                    isExternal
                >
                    <Image
                        src={LinkedInSM}
                        w={'32px'}
                        h={'32px'}
                    />
                </Link>
                <Link
                    href={`mailto:${email}`}
                    isExternal
                >
                    <Image
                        src={EmailSM}
                        w={'32px'}
                        h={'32px'}
                    />
                </Link>
            </Flex>
            <Flex
                w={'100px'}
                h={'32px'}
                borderRadius={'8px'}
                bg={'#CCE820'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Text
                    fontSize={'14px'}
                    fontWeight={700}
                    color={'#80921A'}
                >
                    {points} POINTS
                </Text>
            </Flex>
        </Flex>
    )
}
export default function SingleUserCard({ user }) {
    return (
        <Flex
            p={'24px'}
            flexDirection={'column'}
            gap={'16px'}
            borderRadius={'12px'}
            bg={'#EAF6A1'}
            w={{ base: '100%', md: '240px' }}
            h={'fit-content'}
        >
            {/* profile and name */}
            <Flex
                gap={'10px'}
                alignItems={'center'}
            >
                <Image
                    w={'48px'}
                    h={'48px'}
                    src={user.avatarUrl}
                    rounded={'full'}
                />
                <Flex
                    flexDir={'column'}
                    wordBreak={'break-all'}
                >
                    <Flex
                        flexWrap={'wrap'}
                    >
                        <Text
                            fontSize={'20px'}
                            fontWeight={700}
                            mr={user.lastName && '10px'}
                        >
                            {user.firstName}
                        </Text>
                        <Text
                            fontSize={'20px'}
                            fontWeight={700}
                        >
                            {user.lastName}
                        </Text>

                    </Flex>
                    <Text
                        fontSize={'16px'}
                        fontWeight={400}
                    >
                        {LevelNames[user.level-1]}
                    </Text>
                </Flex>
            </Flex>
            {/* bio */}
            <VStack
                gap={'16px'}
                alignItems={'flex-start'}
            >
                <ContainerWithImageAndText
                    image={Institute}
                    text={user.educationCollegeName}
                />
                <ContainerWithImageAndText
                    image={UserCompany}
                    text={user.currentCompany}
                />
                <ContainerWithImageAndText
                    image={TimeSvg}
                    text={user.workExperience}
                />

            </VStack>
            <Text
                fontSize={'14px'}
                fontWeight={700}
            >
                {user.mocksCompleted === 0 ? 'NO' : user.mocksCompleted} Mocks Completed
            </Text>
            <Footer
                points={user.levelMock}
                linkedinUrl={user.linkedinUrl}
                email={user.email}
    
            />
        </Flex>
    )
}

function Slotclash(eventTime,boundedTime) {
  var left_time = new Date(boundedTime.getTime() - 90*60000);
  var right_time = new Date(boundedTime.getTime() + 90*60000);
  if (
    eventTime > right_time ||
    eventTime < left_time
  ) {
    return true
  } else {
    return false
  }
  
}

export default Slotclash;

const education = [
    {value: 'Graduate', label:'Graduate'},
    {value: 'Undergraduate', label:'Undergraduate'},
]


const graduationYear = [
    {value:'1990',label:'1990'},
    {value:'1991',label:'1991'},
    {value:'1992',label:'1992'},
    {value:'1993',label:'1993'},
    {value:'1994',label:'1994'},
    {value:'1995',label:'1995'},
    {value:'1996',label:'1996'},
    {value:'1997',label:'1997'},
    {value:'1998',label:'1998'},
    {value:'1999',label:'1999'},
    {value:'2000',label:'2000'},
    {value:'2001',label:'2001'},
    {value:'2002',label:'2002'},
    {value:'2003',label:'2003'},
    {value:'2004',label:'2004'},
    {value:'2005',label:'2005'},
    {value:'2006',label:'2006'},
    {value:'2007',label:'2007'},
    {value:'2008',label:'2008'},
    {value:'2009',label:'2009'},
    {value:'2010',label:'2010'},
    {value:'2011',label:'2011'},
    {value:'2012',label:'2012'},
    {value:'2013',label:'2013'},
    {value:'2014',label:'2014'},
    {value:'2015',label:'2015'},
    {value:'2016',label:'2016'},
    {value:'2017',label:'2017'},
    {value:'2018',label:'2018'},
    {value:'2019',label:'2019'},
    {value:'2020',label:'2020'},
    {value:'2021',label:'2021'},
    {value:'2022',label:'2022'},
    {value:'2023',label:'2023'},
    {value:'2024',label:'2024'},
    {value:'2025',label:'2025'},
    {value:'2026',label:'2026'},
    {value:'2027',label:'2027'},
    {value:'2028',label:'2028'},
    {value:'2029',label:'2029'},
    {value:'2030',label:'2030'}
]

const graduationYears = graduationYear.reverse()

const Job = [
    {value:'0-1 year',label:'0-1 year'},
    {value:'1-2 years',label:'1-2 years'},
    {value:'2-3 years',label:'2-3 years'},
    {value:'3-4 years',label:'3-4 years'},
    {value:'4-5 years',label:'4-5 years'},
    {value:'5-6 years',label:'5-6 years'},
    {value:'6-7 years',label:'6-7 years'},
    {value:'7-8 years',label:'7-8 years'},
    {value:'8-9 years',label:'8-9 years'},
    {value:'9-10 years',label:'9-10 years'},
    {value:'10+ years',label:'10+ years'}
]

const intern = [
    {value: '1-3 months',label:'1-3 months'},
    {value: '3+ months',label:'3+ months'}
]

const Experience = [
  {value:'no experience',label:'no experience'},
  {
    label: "Internship Experience",
    options: intern,
  },
  {
    label: "Job Experience",
    options: Job,
  },
];


export {education,graduationYears,Experience}
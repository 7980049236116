import { Center, Stack, Box, Text, Image, HStack, VStack, useToast } from "@chakra-ui/react";
import logo from '../../static/images/Pearmock logo.svg'
import KuberanixLogo from '../../static/images/Kuberanix_logo.png'
function Footer() {
  const toast = useToast();
  return (
    <>
      <Center
        mb={{ base: "45px", sm: "92px" }}
        mt={{ base: "25px", sm: "100px" }}
      >
        <Stack
          direction={["column", "column", "row", "row", "row"]}
          spacing={[0, 0, "32px", "98px", "128px"]}
        >
          <Box w={["286px", "286px", "359px", "359px", "519px"]}>
            <Image
              src={logo}
              w={["274px", "274px", "328px", "328px", "507px"]}
              h={["91px", "91px", "108px", "108px", "168px"]}
            />
            <Text
              fontSize={"16px"}
              lineHeight={"28px"}
              fontWeight={"400"}
              mt={"32px"}
            >
              Pearmock is a community driven interview preparation platform for
              product management roles. Find your peers and start practising
              today!{" "}
            </Text>
            <HStack
              pt={'20px'}

            >
              <Image
                src={KuberanixLogo}
              />
              <Text fontSize={'16px'}>
                A Kuberanix Product
              </Text>
            </HStack>
          </Box>
          <Box w={"267px"} h={"100px"}>
            <HStack
              spacing={"64px"}
              mt={["32px", "32px", "121px", "121px", "156px"]}
            >
              <VStack spacing={"8px"} align={"flex-start"}>
                <Text
                  fontSize={"16px"}
                  lineHeight={"28px"}
                  fontWeight={"400"}
                  cursor={"pointer"}
                  onClick={(e) => {
                    navigator.clipboard.writeText("https://pearmock.com");
                    toast({
                      title: "Copied to clipboard",
                      description: "Website link is copied to your clipboard",
                      status: "success",
                      duration: 2000,
                      isClosable: true,
                    });
                  }}
                >
                  Share Pearmock
                </Text>
                <Text fontSize={"16px"} lineHeight={"28px"} fontWeight={"400"}>
                  About
                </Text>
                <Text
                  fontSize={"16px"}
                  lineHeight={"28px"}
                  fontWeight={"400"}
                  cursor={"pointer"}
                  onClick={() => {
                    window.open("https://faq.pearmock.com", "_blank");
                  }}
                >
                  FAQs
                </Text>
              </VStack>
              <VStack spacing={"8px"} align={"flex-start"}>
                <Text
                  fontSize={"16px"}
                  lineHeight={"28px"}
                  fontWeight={"400"}
                  cursor={"pointer"}
                  onClick={() => {
                    window.open("https://resources.pearmock.com", "_blank");
                  }}
                >
                  Resources
                </Text>
                <Text fontSize={"16px"} lineHeight={"28px"} fontWeight={"400"}>
                  Rule Book
                </Text>
                <Text fontSize={"16px"} lineHeight={"28px"} fontWeight={"400"}>
                  Contact us
                </Text>
              </VStack>
            </HStack>
          </Box>
        </Stack>
      </Center>
      <Center h={{ base: "76px", sm: "48px" }} bg={"#EFEFEF"}>
        <Stack
          w={["360px", "416px", "648px", "808px", "914px", "914px"]}
          direction={{ base: "row", sm: "row" }}
        >
          <Text
            fontSize={"14px"}
            fontWeight={"400"}
            lineHeight={"20px"}
            mr={{ base: "30px", sm: "40px" }}
          >
            &copy; 2024 Pearmock, All rights reserved{" "}
          </Text>
          <Text fontSize={"14px"} fontWeight={"400"} lineHeight={"20px"}>
            Terms of use
          </Text>
        </Stack>
      </Center>
    </>
  );
}

export default Footer;

import { Flex } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { db } from '../../firebase';
import { collection, query, where, getDocs, limit, orderBy } from 'firebase/firestore';
import TopPerformerUser from './TopPerformerUser';
import LevelContainer from './LevelContainer';
import Levels from './Levels';
import LevelCardSekeltons from './LevelCardSekeltons';
export default function LevelCards() {

    const [levelWiseUsers, setLevelWiseUsers] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const returnPromise = (level) => {
        const q = query(
            collection(db, 'users'),
            where('level', '==', level),
            orderBy('levelMock', 'desc'),
            // orderBy('avgRating', 'desc'),
            orderBy('verifiedAt', 'desc'),
            limit(1)
        );
        return getDocs(q)
            .then(querySnapshot => {
                const documents = [];
                querySnapshot.forEach(doc => {
                    // Push each document's data into the array
                    documents.push({ id: doc.id, ...doc.data() });
                });
                return documents[0];
            })
            .catch(error => {
                // Handle any errors
                console.error('Error fetching documents:', error);
                return undefined;
            });
    }

    const getTopPerfomerUsers = async () => {
        const _promises = [];
        for (let i = 1; i <= 5; i++) {
            _promises.push(returnPromise(i));
        }

        const result = await Promise.all(_promises);
        setLevelWiseUsers(result);
        setIsLoading(false);
    };
    useEffect(() => {
        getTopPerfomerUsers();
    }, []);
    return (
        <Flex
            gap={'24px'}
            flexDirection={'column'}
            mt={'60px'}
        >
            {
                isLoading && <LevelCardSekeltons />
            }
            {
             !isLoading &&   levelWiseUsers.slice().reverse().map((user, index) => {

                    // ! quick fix for level 5 (don't know why it is coming )
                    if (index > 4) return (<></>)
                    if (user === undefined)
                        return (
                            <LevelContainer
                                key={`${index}-levelcard`}
                                name={Levels[index]?.name}
                                desc={Levels[index]?.desc}
                                Logo={Levels[index]?.Logo}
                            />
                        )
                    return (
                        <TopPerformerUser
                            key={`${index}-${user.uid}`}
                            user={user}
                        />
                    )

                })
            }
        </Flex>
    )
}

import React from 'react';
import {
  Box,
  Text,
  HStack,
  Avatar,
  AvatarGroup,
} from '@chakra-ui/react';
import Avatar1 from '../../static/images/avatar_1.png';
import Avatar2 from '../../static/images/avatar_2.png';
import Avatar3 from '../../static/images/avatar_3.png';

export default function JoinedBy() {
  return (
    <Box   >
      <HStack alignItems="center">
        <AvatarGroup size="md" max={3}>
          {/* Add Avatar components for each profile image */}
          <Avatar name='Dan Abrahmov' src={Avatar3} zIndex={1} />
          <Avatar name='Kent Dodds' src={Avatar2} zIndex={2} marginLeft={'-.3rem'} />
          <Avatar name='Christian Nwamba' src={Avatar1}zIndex={3} marginLeft={'-.3rem'} />
          {/* ...more Avatar components if needed */}
        </AvatarGroup>
        <Text
          fontSize="14px" fontWeight="500">
          100+ Product Management Aspirants and Pros already joined!
        </Text>
      </HStack>
    </Box>
  )
}

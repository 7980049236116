import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Text,
  Input,
  HStack,
  Box,
  FormErrorMessage,
  useBreakpointValue,
  Circle,
  Switch
} from "@chakra-ui/react";

import { Select } from "chakra-react-select";
import ConfirmModal from "./ConfirmModal";
import { productmodal } from '../../../constants/category'
import TimeSelector from "../common/TimeSelector";
import Slotclash from '../../../utilFunctions/Slotclash'
import { db } from "../../../firebase";
import { collection, doc, updateDoc, setDoc } from 'firebase/firestore'
import { amplitude } from "../../../analytics/analytics";
const WeekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
const InitialSelectedDays = [false, false, false, false, false, false, false];
const StepIndicator = ({ step }) => {
  return (<Flex justifyContent={'center'} alignItems={'center'} my={'24px'}>
    <Circle border='2px' borderColor={'gray.400'} size={'38px'} bg={step >= 1 && '#EAF6A1'}>
      1
    </Circle>
    <Flex width={'140px'} height={'2px'} bg={'gray.400'}></Flex>
  
    <Circle border='2px' borderColor={'gray.400'} size={'38px'} bg={step >= 2 && '#EAF6A1'}>
      2
    </Circle>
  </Flex>)
}
const WeekDaySelector = ({ selectedWeekDays, handleWeekDaySelection }) => {

  return (
    <Flex gap={'10px'} py={'12px'}>
      {
        WeekDays.map((value, index) => <Circle fontWeight={500} _hover={{ cursor: 'pointer' }} key={index} onClick={() => handleWeekDaySelection(index)} border='2px' borderColor={'gray.400'} size={'38px'} bg={selectedWeekDays[index] === true && '#EAF6A1'}>
          {value}
        </Circle>)
      }

    </Flex>
  )
}
function Cratemock({ userdata, userUid, isOpen, onClose }) {
  const [loading, setloading] = useState(false);
  const [selectedWeekDays, setSelectedWeekDays] = useState(InitialSelectedDays);
  const [step, setStep] = useState(1);
  const [repeatCount, setRepeatCount] = useState(1);
  const [isOpen2, setisOpen2] = useState(false)
  const [firstcasetype, setfirstcasetype] = useState([]);
  const [secondcasetype, setsecondcasetype] = useState([]);
  const [date, setdate] = useState('')
  const [dateForInfo, setDateForInfo] = useState('');
  const [timeForInfo, setTimeForInfo] = useState('');
  const [time, settime] = useState('')
  const [isRecuring, setIsRecuring] = useState(false);
  const isInvalid = firstcasetype.length === 0 || secondcasetype.length === 0 || time === '' || date === '';
  const [slot, setslot] = useState(false);
  const [pastTime, setpastTime] = useState(false);
  const modalSize = useBreakpointValue({ base: "xl", md: "lg" });


  const info = {
    isOpen2: isOpen2,
    youPractice: {
      caseType: firstcasetype
    },
    youTake: {
      caseType: secondcasetype
    },
    datetime: new Date(`${dateForInfo}T${timeForInfo}`),
    setfirstcasetype: setfirstcasetype,
    setsecondcasetype: setsecondcasetype,
    setdate: setdate,
    settime: settime
  }

  const eventinfo = {
    eventId: '',
    eventStatus: 'unpaired',
    past: false,
    creatorUid: userUid,
    creatorLevel: userdata.level,
    ids: [userUid],
    creatorFirstName: userdata.firstName,
    creatorLastName: userdata.lastName,
    creatorAvatarUrl: userdata.avatarUrl,
    creatorEmail: userdata.email,
    creatorLinkedin: userdata.linkedinUrl,
    creatorPhoneNumber: userdata.phoneNumber ? userdata.phoneNumber : null,
    creatorWorkExperience: userdata.workExperience,
    creatorCurrentJobRole: userdata.currentJobRole,
    creatorCurrentCompany: userdata.currentCompany,
    creatorEducationDegree: userdata.educationDegree,
    creatorEducationCollegeName: userdata.educationCollegeName,
    creatorGraduationYear: userdata.graduationYear,
    creatorMocksCompleted: userdata.mocksCompleted,
    creatorCaseType: firstcasetype,
    willTakeType: secondcasetype,
  }


  const handleRepeatCount = (type) => {
    if (type === 'INC' && repeatCount < 6) setRepeatCount(prev => prev + 1);
    if (type === 'DEC' && repeatCount > 1) setRepeatCount(prev => prev - 1);
  }
  function mockTimings() {
    const timings = []
    Object.entries(userdata.createdMocks).map(mock => {
      const [key, value] = mock
      timings.push(value.toDate())
    })
    Object.entries(userdata.upcomingMocks).map(mock => {
      const [key, value] = mock
      timings.push(value.toDate())
    })
    return timings
  }

  function getTagObject(level) {
    var obj;
    if (level === 0 || level === 1) {
      obj = {
        T1: true,
        T2: true,
        T3: true,
        T4: true,
        T5: true
      }
    }
    else if (level === 2) {
      obj = {
        T1: false,
        T2: true,
        T3: true,
        T4: true,
        T5: true
      }
    }
    else if (level === 3) {
      obj = {
        T1: false,
        T2: false,
        T3: true,
        T4: true,
        T5: true
      }
    }
    else if (level === 4) {
      obj = {
        T1: false,
        T2: false,
        T3: false,
        T4: true,
        T5: true
      }
    }
    else if (level === 5) {
      obj = {
        T1: false,
        T2: false,
        T3: false,
        T4: false,
        T5: true
      }
    }

    return obj
  }

  function eventDataEntry(eventobject) {
    const tagObject = getTagObject(userdata.level)
    const eventRef = doc(collection(db, 'events'))
    eventobject.eventId = eventRef.id
    Object.assign(eventobject, tagObject)
    const userRef = doc(db, 'users', userUid)
    setDoc(eventRef, eventobject).then(() => {
      updateDoc(userRef, {
        [`createdMocks.${eventRef.id}`]: new Date(`${date}T${time}`)
      }, { merge: true }).then(() => {
        amplitude.track('mock created')
        setslot(false)
        setloading(false)
        setisOpen2(true)
        onClose(true)
        handleClose();
      })


    })
  }

  // select the date from calander and mark the week day as active in recuring tab
  const handleDateSelection = (value) => {
    setdate(value);
    setDateForInfo(value);
    const _date = new Date(value);
    const weekday = _date.getDay();
    const temp = [...InitialSelectedDays];
    temp[weekday] = true;
    setSelectedWeekDays(temp)
  }
  // if the recuring is only for first week we cannot select previous weekday
  const handleWeekDaySelection = (index) => {
    if (!date) return;
    const _date = new Date(date);
    const weekday = _date.getDay();
    setSelectedWeekDays(prev => {
      const temp = [...prev];
      temp[index] = weekday === index?true:!temp[index];
      return temp;
    });
  }
  const createDateFromSelectedWeekDays = () => {
    const scheduleOn = [];
    const currentWeekDay = new Date(date).getDay();
    let daysInFuture = 0;
    for (let week = 1; week <= repeatCount; week++) {
      for (let weekday = currentWeekDay; weekday <= currentWeekDay+6; weekday++) {
        const index = weekday%7;
        if(selectedWeekDays[index] === true){
          const futureDate = new Date(date);
          futureDate.setDate(futureDate.getDate()+daysInFuture);
          const year = futureDate.getFullYear();
          const month = ('0' + (futureDate.getMonth() + 1)).slice(-2); // Adding 1 because month is zero-based
          const day = ('0' + futureDate.getDate()).slice(-2);
          const formattedDate = year + '-' + month + '-' + day;
          scheduleOn.push(formattedDate);
        }
        daysInFuture++;
      }
    }
    return scheduleOn;
  }
  function create() {
    // setloading(true)
    const timings = mockTimings()
    const eventTime = new Date(`${date}T${time}`)
    if (eventTime < Date.now()) {
      setpastTime(true);
      setloading(false);
      return;
    }
    const scheduleOn = createDateFromSelectedWeekDays();
    //check slot clash
    let slotclash = false;
    for (const date of scheduleOn) {
      const _eventTime = new Date(`${date}T${time}`);
      for (const timeValue of timings) {
        if (Slotclash(_eventTime, timeValue)) continue;
        setslot(true)
        setloading(false)
        slotclash = true;
        break;
      }
    }
    // if slotclash
    if (slotclash) return;

    for (const date of scheduleOn) {
      const eventDateTime = new Date(`${date}T${time}`);
      const createdAT = new Date();
      eventDataEntry({ ...eventinfo, eventDateTime, createdAT })
    }
  }

  function firstcasetypeChange(options) {
    const cases = []
    options.map(option => {
      cases.push(option.value)
    })
    setfirstcasetype(cases);
  }

  function secondcasetypeChange(options) {
    const cases = []
    options.map(option => {
      cases.push(option.value)
    })
    setsecondcasetype(cases);
  }

  function handleClose() {
    onClose(true);
    setdate("");
    settime("");
    setStep(1);
    setSelectedWeekDays(InitialSelectedDays);
    setRepeatCount(1);
    setIsRecuring(false);
  }

  const handleSetTime = (value)=>{
    settime(value);
    setTimeForInfo(value);
  }
  const timerprops = {
    value: time,
    setvalue: handleSetTime,
    isInavlid: slot,
  };

  function today() {
    const date = new Date();
    const datestring =
      date.toLocaleDateString(undefined, { year: "numeric" }) +
      "-" +
      date.toLocaleDateString(undefined, { month: "2-digit" }) +
      "-" +
      date.toLocaleDateString(undefined, { day: "2-digit" });
    return datestring;
  }

  function twoMonth() {
    const date = new Date();
    date.setMonth(date.getMonth() + 2);
    const datestring =
      date.toLocaleDateString(undefined, { year: "numeric" }) +
      "-" +
      date.toLocaleDateString(undefined, { month: "2-digit" }) +
      "-" +
      date.toLocaleDateString(undefined, { day: "2-digit" });
    return datestring;
  }

  return (
    <>
      <Modal
        onClose={handleClose}
        isOpen={isOpen}
        blockScrollOnMount={true}
        closeOnOverlayClick={true}
        size={modalSize}
        boxShadow={
          "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
        }
      >
        <ModalOverlay />
        <ModalContent borderRadius={{ base: '16px', md: '16px' }}>
          <ModalHeader>
            <FormControl>
              <Text fontSize={"24px"} lineHeight={"32px"} fontWeight={700}>Create a mock interview</Text>
              <StepIndicator step={step} />
            </FormControl>
          </ModalHeader>

          <ModalCloseButton size={"sm"} onClick={handleClose} />
          <ModalBody>
            {
              step === 1 && (
                <>
                  <FormControl isRequired>
                    <Box>
                      <FormLabel fontSize={"18px"} lineHeight={"28px"} fontWeight={700}>
                        Choose interviewee cases
                      </FormLabel>
                    </Box>
                    <Box my={"8px"}>
                      <Text fontSize={"14px"} lineHeight={"20px"} fontWeight={400}>Select cases which you want to practice. Your partner will come prepared with one question from any of the case types you select. </Text>
                    </Box>
                    <Box>
                      <Select
                        isMulti
                        tabSelectsValue={false}
                        name="caseType"
                        options={productmodal}
                        placeholder="Select case type(s)"
                        closeMenuOnSelect={false}
                        onChange={firstcasetypeChange}
                      />
                    </Box>
                  </FormControl>
                  {/*Second form control for the modal */}
                  <FormControl isRequired>
                    <Box mt={"24px"}>
                      <FormLabel fontSize={"18px"} lineHeight={"28px"} fontWeight={700}>
                        Choose interviewer cases
                      </FormLabel>
                    </Box>
                    <Box my={'8px'}>
                      <Text fontWeight={"400"} fontSize={"14px"} lineHeight={"20px"}>
                        Select cases which you can take as an interviewer. Don’t worry! You don’t have to be a pro to take mock interviews.
                      </Text>
                    </Box>
                    <Box>
                      <Select
                        isMulti
                        tabSelectsValue={false}
                        name="secondcaseType"
                        options={productmodal}
                        placeholder="Select case type(s)"
                        closeMenuOnSelect={false}
                        onChange={secondcasetypeChange}
                      />
                    </Box>
                  </FormControl>
                </>
              )
            }

            {
              step === 2 && (
                <FormControl isRequired isInvalid={slot || pastTime} >
                  <Box >
                    <FormLabel fontSize={"18px"} lineHeight={"28px"} fontWeight={700}>Select the date and start time</FormLabel>
                  </Box>
                  <Text fontWeight={"400"} fontSize={"14px"} lineHeight={"20px"}>
                    While selecting, please make sure you will be available for the
                    mock interview on the specified date and time.{` `}
                    <Text as="span" decoration={"underline"}>
                      All mocks are of 90 minutes.
                    </Text>
                  </Text>
                  <Box mt={"8px"}>
                    <HStack>
                      <Input
                        type={"date"}
                        value={date}
                        onChange={(e) => {
                          handleDateSelection(e.target.value);
                        }}
                        isInvalid={slot}
                        min={today()}
                        max={twoMonth()}
                      />
                      <TimeSelector {...timerprops} />
                    </HStack>
                  </Box>
                  {slot ? (
                    <FormErrorMessage>
                      This slot is clashing with one of your other mock interviews.
                      Please select a different date-time combination and try again.
                    </FormErrorMessage>
                  ) : null}
                  {pastTime ? (
                    <FormErrorMessage>
                      You have selected a past time. Please Select a correct time.
                    </FormErrorMessage>
                  ) : null}
                </FormControl>
              )
            }
            {
              step === 2 && (
                <FormControl isInvalid={slot || pastTime} >
                  <Box mt={"24px"}>
                    <FormLabel fontSize={"18px"} lineHeight={"28px"} fontWeight={700}>Practice frequency</FormLabel>
                  </Box>
                  <Text fontWeight={"400"} fontSize={"14px"} lineHeight={"20px"}>
                    Please select how frequently you will practice this topic. We
                    recommend starting with at least two times a week for each topic.
                  </Text>
                  <Box>
                    <HStack alignItems={'center'} gap={'10px'} py={'12px'}>
                      <Switch id='recruing'
                        onChange={() => setIsRecuring(prev => !prev)}
                        sx={{ '.chakra-switch__track[data-checked]:not([data-theme])': { backgroundColor: '#B4D202', }, }}
                      />
                      <FormLabel htmlFor='recruing' mb='0'>
                        {isRecuring ? 'Recurring' : 'One Time'}
                      </FormLabel>
                    </HStack>
                  </Box>
                  {
                    isRecuring && (
                      <Box>
                        <WeekDaySelector selectedWeekDays={selectedWeekDays} handleWeekDaySelection={handleWeekDaySelection} />
                        <Flex alignItems={'center'} py={'12px'} gap={'10px'}>
                          <Text>
                            Repears for
                          </Text>
                          <Flex w={'60px'} h={'32px'} borderRadius={'4px'} justifyItems={'center'} alignItems={'center'} border={'1px'} borderColor={'#E6E6E6'}>

                            <Input
                              value={repeatCount}
                              type="number"
                              id="numberInput"
                              min={1}
                              max={6}
                              border={'none'}
                              bg={'gray.100'}
                              h={'32px'}
                              borderRadius={'none'}
                              readOnly
                              p={'0px'}
                              textAlign={'center'}
                            />
                            <Flex flexDirection={'column'} alignContent={'space-between'} bg={'gray.200'}>
                              <button onClick={() => handleRepeatCount("INC")}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" fill="none">
                                  <g clipPath="url(#clip0_5941_18250)">
                                    <path d="M7 10L12 5L17 10H7Z" fill="black" fillOpacity="0.54" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5941_18250">
                                      <rect width="24" height="16" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </button>
                              <button onClick={() => handleRepeatCount("DEC")}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16" fill="none">
                                  <g clipPath="url(#clip0_5941_18253)">
                                    <path d="M7 6L12 11L17 6H7Z" fill="black" fillOpacity="0.54" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_5941_18253">
                                      <rect width="24" height="16" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </button>

                            </Flex>
                          </Flex>
                          <Text>
                            weeks
                          </Text>
                        </Flex>
                      </Box>
                    )
                  }
                  {slot ? (
                    <FormErrorMessage>
                      This slot is clashing with one of your other mock interviews.
                      Please select a different date-time combination and try again.
                    </FormErrorMessage>
                  ) : null}
                  {pastTime ? (
                    <FormErrorMessage>
                      You have selected a past time. Please Select a correct time.
                    </FormErrorMessage>
                  ) : null}
                </FormControl>
              )
            }

          </ModalBody>
          <ModalFooter>
            <Button
              onClick={handleClose}
              mr={"12px"}
              size={"sm"}
              variant={"ghost"}
              color={"black"}
              border={"1px solid #E2E8F0"}
              w={"72px"}
              h={"32px"}
              display={loading ? "none" : "block"}
            >
              Cancel
            </Button>
            {step === 2 && <Button
              onClick={create}
              size={"sm"}
              w={"111px"}
              h={"32px"}
              isDisabled={isInvalid}
              isLoading={loading}
            >
              Create Mock
            </Button>}
            {
              step === 1 && <Button
                onClick={() => setStep(prev => prev + 1)}
                size={"sm"}
                w={"111px"}
                h={"32px"}
                isDisabled={firstcasetype.length === 0 || secondcasetype.length === 0}
              >
                Next
              </Button>
            }
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/*Confirmation Modal*/}
      {isOpen2 && <ConfirmModal {...info} />}
    </>
  );
}

export default Cratemock;

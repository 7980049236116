import { Icon } from "@chakra-ui/react";

export const LinkedinIcon = (props) => (
  <Icon viewBox="0 0 40 40" {...props}>
    
    <path
      d="M7 13.5828H12.4167V31.9995H7V13.5828ZM9.69208 11.4162H9.66175C8.04542 11.4162 7 10.2115 7 8.70676C7 7.16951 8.07792 5.99951 9.7235 5.99951C11.3713 5.99951 12.3863 7.16951 12.4167 8.70676C12.4167 10.2104 11.3712 11.4162 9.69208 11.4162ZM33 31.9995H27.5833V22.1423C27.5833 19.7611 26.2563 18.1361 24.1253 18.1361C22.4993 18.1361 21.6196 19.2324 21.1927 20.2919C21.0368 20.6711 21.0833 21.7198 21.0833 22.2495V31.9995H15.6667V13.5828H21.0833V16.4168C21.8644 15.2078 23.0875 13.5828 26.2162 13.5828C30.0923 13.5828 32.9989 16.0203 32.9989 21.463L33 31.9995Z"
      fill="#2B6CB0"
    />
    
  </Icon>
);
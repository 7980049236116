import { useEffect, useState } from "react";
import { CheckIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Circle,
  HStack,
  Text,
  Box
} from "@chakra-ui/react";
import Time from '../../common/Time'
import {db} from '../../../../firebase'
import {doc,updateDoc,setDoc, arrayUnion, deleteField} from 'firebase/firestore'
import { amplitude } from "../../../../analytics/analytics";

function Joinconfirm({
  isOpen2,
  youPractice,
  youTake,
  datetime,
  isOpenfunc,
  modalClose,
  name,
  data,
  user,
  userdata
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    if (isOpen2) {
      onOpen(true);
    }
  }, [isOpen2, onOpen]);

  const [confirm, setconfirm] = useState(false);
  const [loading, setloading] = useState(false)
  const timeprops = {
    datetime:datetime
  }

  const joininfo = {
    partnerUid: user.uid,
    partnerLevel: userdata.level,
    partnerFirstName: userdata.firstName,
    partnerLastName: userdata.lastName,
    partnerAvatarUrl: userdata.avatarUrl,
    partnerEmail: userdata.email,
    partnerLinkedin: userdata.linkedinUrl,
    partnerPhoneNumber:userdata.phoneNumber?userdata.phoneNumber:null,
    partnerWorkExperience: userdata.workExperience,
    partnerCurrentJobRole: userdata.currentJobRole,
    partnerCurrentCompany: userdata.currentCompany,
    partnerEducationDegree: userdata.educationDegree,
    partnerEducationCollegeName: userdata.educationCollegeName,
    partnerGraduationYear: userdata.graduationYear,
    partnerMocksCompleted: userdata.mocksCompleted,
    partnerCaseType: youPractice.caseType
  }

    

    
    function addEvent(){
        const startTime = data.eventDateTime.toDate();
        const endTime = new Date(startTime.getTime() + 90 * 60000);
        const requestOptions = {
          method: "POST",
          body: JSON.stringify({
            eventName: "Pearmock Interview invite",
            description:
              `<h2>Hey there,</h2>

              I want to share a few tips to help you make the most of your 90-minute mock interview session on Pearmock:
              
              1. <b>Preparation is Key:</b> Spend some time before the session to prepare your problem statement or case. The better prepared you are, the more you'll get out of the session.
              2. <b>Be Punctual:</b> Start on time. Respect your partner's time and ensure you're ready to go when the session starts.
              3. <b>Active Listening:</b> When it's your partner's turn to present, listen actively. This will help you provide constructive feedback later.
              4. <b>Constructive Feedback:</b> After each interview, provide constructive feedback to your partner. Be specific, be honest, and be kind. Remember, feedback is a gift.
              5. <b>Openness to Feedback:</b> When receiving feedback, adopt a "YES, AND" approach. This means accepting the feedback and building upon it with your own insights and ideas.
              6. <b>Reflect and Learn:</b> After the session, spend some time reflecting on the feedback you received and the areas you need to work on.
              
              Remember, the goal of these sessions is not just to practice, but to learn and improve. So, take every opportunity to learn from each mock interview.
              
              Keep rocking and happy practicing! 🚀
              
              Best,
              Patrice
              Pearmock`,
            startTime: `${startTime.toISOString()}`,
            endTime: `${endTime.toISOString()}`,
            creatorEmail: `${data.creatorEmail}`,
            partnerEmail: `${userdata.email}`,
          }),
        };
        fetch(
          "https://us-central1-pearmock-f75c3.cloudfunctions.net/addEventToCalendar/addEventToCalendar",
          requestOptions
        )
          .then((response) =>(response.json()))
          .then((event) => {
            const object = {
              eventLink: event.hangoutLink,
              pairedAt: new Date(),
              meetingId: event.id
            };
            Object.assign(joininfo, object);
            updateDoc(doc(db,'users',data.creatorUid),{
              [`createdMocks.${data.eventId}`]: deleteField(),
              [`upcomingMocks.${data.eventId}`]: data.eventDateTime.toDate()
            }).then(()=>{
              updateDoc(doc(db,'users',user.uid),{
                [`upcomingMocks.${data.eventId}`]: data.eventDateTime.toDate()
              }).then(()=>{
                setDoc(doc(db,'events',data.eventId),{
                  eventStatus: 'upcoming',
                  ids: arrayUnion(user.uid),
                  ...joininfo 
                },{merge:true}).then(()=>{
                  amplitude.track('mock joined')
                  setconfirm(true);
                })
              })
            })
          });
          
          

    }

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size={"md"}
        boxShadow={
          "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)"
        }
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent borderRadius={'16px'}>
          <ModalHeader>
            {confirm ? (
              <>
                <HStack>
                  <Circle w={10} h={10} bg={"green.500"} color={"white"}>
                    <CheckIcon />
                  </Circle>
                  <Text fontSize={"24px"} lineHeight={"32px"} fontWeight={"700"} color={'gray.900'}>Mock confirmed!</Text>
                </HStack>
              </>
            ) : (
              <Text fontSize={"24px"} lineHeight={"32px"} fontWeight={"700"} color={'gray.900'}>Confirm mock details</Text>
            )}
          </ModalHeader>
          <ModalCloseButton mt={"10px"} mr={"10px"} />
          <ModalBody>
            <Box>
              <Text
                fontWeight={"700"}
                fontSize={"18px"}
                lineHeight={"28px"}
                color={"gray.900"}
              >
                Partner
              </Text>

              <Box>
                {youTake.caseType.map((doc, index) => {
                  return index + 1 === youTake.caseType.length ? (
                    <Text
                      as={"span"}
                      key={doc}
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      fontWeight={"400"}
                      color={"blackAlpha.700"}
                    >
                      {doc}
                    </Text>
                  ) : (
                    <Text
                      as={"span"}
                      key={doc}
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      fontWeight={"400"}
                      color={"blackAlpha.700"}
                    >
                      {doc},{" "}
                    </Text>
                  );
                })}
              </Box>
            </Box>

            <Box mt={"16px"}>
              <Text
                fontWeight={"700"}
                fontSize={"18px"}
                lineHeight={"28px"}
                color={"gray.900"}
              >
                You
              </Text>

              <Box>
                {youPractice.caseType.map((doc, index) => {
                  return index + 1 === youPractice.caseType.length ? (
                    <Text
                    as={"span"}
                    key={doc}
                    fontSize={"14px"}
                    lineHeight={"20px"}
                    fontWeight={"400"}
                    color={"blackAlpha.700"}
                    >
                      {doc}
                    </Text>
                  ) : (
                    <Text
                    as={"span"}
                    key={doc}
                    fontSize={"14px"}
                    lineHeight={"20px"}
                    fontWeight={"400"}
                    color={"blackAlpha.700"}
                    >
                      {doc},{" "}
                    </Text>
                  );
                })}
              </Box>
            </Box>
            <HStack mt={'16px'}><Time {...timeprops} /></HStack>

            {confirm && (
              <>
                <Text fontSize={"14px"} lineHeight={"20px"}>
                  You have successfully joined this mock. Please join the
                  session on time and <Text as={'u'}>don’t forget to prepare a case</Text> for {name}
                  before your session! All the best and we hope you have an
                  awesome mock interview. 🚀
                </Text>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            {confirm ? (
              <>
                <Button onClick={onClose}>Done</Button>
              </>
            ) : (
              <>
                <Button
                  variant={"outline"}
                  colorScheme={"gray"}
                  mr={3}
                  display={loading?'none':'block'}
                  onClick={() => {
                    onClose(true);
                    isOpenfunc(false);
                  }}
                >
                  Back
                </Button>
                <Button
                  isLoading={loading}
                  onClick={() => {
                    modalClose(true);
                    setloading(true)
                    addEvent()
                  }}
                  w={"186px"}
                  fontSize={"14px"}
                  fontWeight={"600"}
                  lineHeight={"20px"}
                >
                  Confirm & join the mock
                </Button>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Joinconfirm;

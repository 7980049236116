import {useEffect,useState} from 'react';
import {useAuth} from '../context/AuthContext'
import Loader from '../static/Loader';
import {Button, Heading, VStack} from '@chakra-ui/react'
import {useNavigate} from 'react-router-dom'
import baseUrl from '../constants/urls';
import {db} from '../firebase'
import {onSnapshot,collection,query,where} from 'firebase/firestore'
import AdminCard from '../components/Admin/AdminCard';
function Admin() {
  const navigate = useNavigate()
  const {user} = useAuth()
  const [loading, setloading] = useState(true);
  const [data, setdata] = useState([]);
  useEffect(() => {
    if(user !== null){
        if(user.isAdmin){
            setloading(false)
        }
        else{
            navigate(`${baseUrl}`)
        }
    }
  }, [user]);
  
  function fetchData(){
    const usersRef = collection(db,'users')
    const q = query(usersRef,where('userStatus','==','submitted'))
    onSnapshot(q, (querySnap) => {
        setdata(
          querySnap.docs.map(doc=>{
            return {
                id: doc.id,
                ...doc.data()
            }
          })
        )
      });
  }

  // useEffect(() => {
  //   console.log(data)
  // }, [data]);
  
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <VStack>
            {data.length ? (
              <>
                <Heading mb={'30px'}>First Click on Admin if You want to make admin</Heading>
                {data.map((doc) => {
                  return <AdminCard data={doc} key={doc.id}/>;
                })}
              </>
            ) : (
              <Button onClick={fetchData}>Bring Data of Submitted Users</Button>
            )}
          </VStack>
        </>
      )}
    </>
  );
}

export default Admin;

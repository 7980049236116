import React from 'react'

export default function MasterySvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="160" height="112" viewBox="0 0 160 112" fill="none">
    <g clip-path="url(#clip0_5644_1990)">
      <path d="M135.093 77.7646C134.927 77.5814 134.803 77.3641 134.731 77.128C134.658 76.8919 134.638 76.6427 134.672 76.398C134.706 76.1533 134.793 75.919 134.927 75.7117C135.062 75.5043 135.24 75.329 135.449 75.1979L134.11 69.3438L137.031 70.4372L137.855 75.8288C138.052 76.1953 138.107 76.6212 138.011 77.0258C137.915 77.4305 137.674 77.7858 137.333 78.0244C136.993 78.263 136.577 78.3684 136.164 78.3205C135.751 78.2726 135.37 78.0748 135.093 77.7646Z" fill="#FFDEB8"/>
      <path d="M134.891 73.1174L132.322 68.1497L132.67 55.6406L132.835 55.9885C133.027 56.3938 137.533 65.9382 137.212 69.1334L137.727 72.0533L134.891 73.1174Z" fill="#0A4A3F"/>
      <path d="M133.216 109.834H135.275L136.255 101.887L133.216 101.887L133.216 109.834Z" fill="#FFDEB8"/>
      <path d="M132.691 109.16L136.746 109.16H136.747C137.432 109.16 138.09 109.433 138.574 109.918C139.059 110.402 139.331 111.06 139.331 111.746V111.83L132.691 111.83L132.691 109.16Z" fill="#151E2B"/>
      <path d="M112.869 108.266L114.853 108.818L117.925 101.424L114.996 100.609L112.869 108.266Z" fill="#FFDEB8"/>
      <path d="M112.543 107.477L116.45 108.564L116.45 108.564C117.111 108.747 117.671 109.186 118.008 109.783C118.345 110.38 118.431 111.087 118.248 111.748L118.225 111.829L111.828 110.049L112.543 107.477Z" fill="#151E2B"/>
      <path d="M131.163 106.733L127.007 76.9599L118.03 106.564L110.972 104.153L121.088 64.5117L121.155 64.507L131.752 63.7695L139.409 104.326L131.163 106.733Z" fill="#151E2B"/>
      <path d="M130.064 50.4828C130.064 50.4828 126.2 48.4661 120.993 49.3064C120.993 49.3064 118.221 55.9963 120.993 58.6595L121.749 65.356C121.749 65.356 128.804 69.5575 131.156 64.6838L130.82 59.1378C130.82 59.1378 132.499 56.478 131.156 54.5308C130.35 53.3413 129.966 51.9162 130.064 50.4828Z" fill="#C0D921"/>
      <path d="M130.553 80.9402L131.231 66.8623L129.312 50.115L129.4 50.1017C131.918 49.7219 132.725 54.0611 132.758 54.2459L137.14 79.0326L130.553 80.9402Z" fill="#0A4A3F"/>
      <path d="M122.01 81.0881L114.264 80.227L114.266 80.1423C114.293 78.9949 114.96 52.0181 116.828 50.1075C118.707 48.1856 122.254 49.0172 122.404 49.0537L122.479 49.0718L121.841 60.4868L122.01 81.0881Z" fill="#0A4A3F"/>
      <path d="M123.437 64.9649C123.202 65.042 122.954 65.0666 122.708 65.0371C122.463 65.0075 122.227 64.9246 122.018 64.7941C121.808 64.6635 121.63 64.4886 121.495 64.2816C121.36 64.0746 121.272 63.8405 121.238 63.5959L115.347 62.4412L117.533 60.2148L122.792 61.6527C123.206 61.6218 123.618 61.7441 123.948 61.9965C124.279 62.2489 124.505 62.6138 124.585 63.0221C124.664 63.4304 124.591 63.8537 124.38 64.2117C124.168 64.5698 123.833 64.8378 123.437 64.9649Z" fill="#FFDEB8"/>
      <path d="M120.293 63.6147L118.374 63.2018C115.541 64.0544 110.595 61.0528 108.596 59.7196C108.219 59.4688 108.097 58.9717 108.233 58.2425C108.433 57.2225 108.995 56.3093 109.815 55.6713L116.855 50.0864L118.632 49.7891L119.16 52.7815L114.675 57.6017L121.278 61.1976L120.293 63.6147Z" fill="#0A4A3F"/>
      <path d="M119.534 46.7736V42.8932C119.534 41.2515 120.186 39.677 121.346 38.5162C122.507 37.3553 124.08 36.7031 125.721 36.7031C127.362 36.7031 128.936 37.3553 130.096 38.5162C131.256 39.677 131.908 41.2515 131.908 42.8932V46.7736C131.908 46.9941 131.82 47.2054 131.665 47.3613C131.509 47.5172 131.297 47.6049 131.077 47.6051H120.365C120.145 47.6049 119.934 47.5172 119.778 47.3613C119.622 47.2054 119.534 46.9941 119.534 46.7736Z" fill="#151E2B"/>
      <path d="M125.473 47.565C127.978 47.565 130.009 45.5331 130.009 43.0267C130.009 40.5202 127.978 38.4883 125.473 38.4883C122.967 38.4883 120.937 40.5202 120.937 43.0267C120.937 45.5331 122.967 47.565 125.473 47.565Z" fill="#FFDEB8"/>
      <path d="M120.812 43.009C120.769 42.9589 120.737 42.9002 120.718 42.8366C120.699 42.7731 120.694 42.7063 120.704 42.6407L121.241 38.8971C121.252 38.8209 121.281 38.7486 121.327 38.6867C121.373 38.6248 121.433 38.5753 121.503 38.5425C124.245 37.2583 127.026 37.2567 129.769 38.5377C129.842 38.5722 129.904 38.625 129.951 38.6909C129.997 38.7569 130.025 38.8337 130.033 38.9139L130.392 42.6624C130.398 42.7264 130.39 42.7911 130.37 42.8522C130.35 42.9133 130.317 42.9695 130.274 43.0171C130.23 43.0648 130.178 43.1028 130.119 43.1289C130.06 43.1549 129.996 43.1683 129.932 43.1683H129.022C128.935 43.1681 128.849 43.143 128.774 43.0958C128.7 43.0486 128.641 42.9813 128.604 42.9018L128.211 42.0602C128.186 42.0057 128.143 41.9609 128.09 41.9327C128.037 41.9044 127.977 41.8942 127.917 41.9036C127.858 41.9129 127.803 41.9413 127.761 41.9845C127.719 42.0277 127.693 42.0833 127.685 42.143L127.608 42.7636C127.594 42.8753 127.539 42.978 127.455 43.0525C127.371 43.127 127.262 43.1682 127.15 43.1683H121.161C121.095 43.1683 121.029 43.1541 120.969 43.1266C120.909 43.0991 120.855 43.059 120.812 43.009Z" fill="#151E2B"/>
      <path d="M101.763 7.49609H0V70.8964H101.763V7.49609Z" fill="#E6E6E6"/>
      <path d="M25.4618 19.707H6.82397V31.8277H25.4618V19.707Z" fill="white"/>
      <path d="M48.6207 19.707H29.9829V31.8277H48.6207V19.707Z" fill="white"/>
      <path d="M71.7797 19.707H53.1418V31.8277H71.7797V19.707Z" fill="white"/>
      <path d="M94.9386 19.707H76.3008V31.8277H94.9386V19.707Z" fill="white"/>
      <path d="M25.4618 36.8633H6.82397V48.9839H25.4618V36.8633Z" fill="white"/>
      <path d="M48.6207 36.8633H29.9829V48.9839H48.6207V36.8633Z" fill="white"/>
      <path d="M71.7797 36.8633H53.1418V48.9839H71.7797V36.8633Z" fill="white"/>
      <path d="M94.9386 36.8633H76.3008V48.9839H94.9386V36.8633Z" fill="white"/>
      <path d="M25.4618 54.0195H6.82397V66.1402H25.4618V54.0195Z" fill="white"/>
      <path d="M48.6207 54.0195H29.9829V66.1402H48.6207V54.0195Z" fill="white"/>
      <path d="M71.7797 54.0195H53.1418V66.1402H71.7797V54.0195Z" fill="white"/>
      <path d="M94.9386 54.0195H76.3008V66.1402H94.9386V54.0195Z" fill="white"/>
      <path d="M26.6521 14.9535C28.1961 14.9535 29.4478 13.7012 29.4478 12.1564C29.4478 10.6117 28.1961 9.35938 26.6521 9.35938C25.1081 9.35938 23.8564 10.6117 23.8564 12.1564C23.8564 13.7012 25.1081 14.9535 26.6521 14.9535Z" fill="white"/>
      <path d="M72.8738 14.9535C74.4178 14.9535 75.6695 13.7012 75.6695 12.1564C75.6695 10.6117 74.4178 9.35938 72.8738 9.35938C71.3298 9.35938 70.0781 10.6117 70.0781 12.1564C70.0781 13.7012 71.3298 14.9535 72.8738 14.9535Z" fill="white"/>
      <path d="M27.7705 0.0351562H25.5339V12.9017H27.7705V0.0351562Z" fill="#E6E6E6"/>
      <path d="M73.9921 0.0351562H71.7556V12.9017H73.9921V0.0351562Z" fill="#E6E6E6"/>
      <path d="M89.8341 25.7675C89.8332 26.7802 89.467 27.7584 88.8029 28.5226C88.1388 29.2868 87.2214 29.7855 86.2192 29.9271C85.217 30.0688 84.1974 29.8438 83.3477 29.2936C82.498 28.7434 81.8753 27.9049 81.594 26.9322C81.3128 25.9594 81.3918 24.9178 81.8166 23.9987C82.2414 23.0795 82.9834 22.3447 83.9064 21.9291C84.8293 21.5135 85.8712 21.445 86.8405 21.7364C87.8099 22.0278 88.6416 22.6593 89.1828 23.515C89.609 24.1889 89.8349 24.9701 89.8341 25.7675Z" fill="#C0D921"/>
      <path d="M89.183 23.5146L85.0343 27.6647C84.7735 27.2608 83.3208 25.3838 83.3208 25.3838C83.507 25.1956 83.7056 25.0201 83.9155 24.8586L85.1201 26.4656L88.6972 22.8867C88.8786 23.0802 89.0413 23.2904 89.183 23.5146Z" fill="white"/>
      <path d="M43.5165 42.9238C43.5156 43.9364 43.1494 44.9147 42.4853 45.6789C41.8212 46.443 40.9037 46.9417 39.9016 47.0834C38.8994 47.225 37.8798 47.0001 37.0301 46.4499C36.1804 45.8997 35.5577 45.0611 35.2764 44.0884C34.9951 43.1157 35.0741 42.074 35.4989 41.1549C35.9238 40.2358 36.6658 39.5009 37.5888 39.0853C38.5117 38.6697 39.5536 38.6013 40.5229 38.8927C41.4922 39.184 42.3239 39.8155 42.8652 40.6712C43.2914 41.3451 43.5173 42.1263 43.5165 42.9238Z" fill="#C0D921"/>
      <path d="M42.8652 40.6709L38.7164 44.821C38.4556 44.4171 37.0029 42.5401 37.0029 42.5401C37.1891 42.3518 37.3878 42.1764 37.5976 42.0149L38.8022 43.6219L42.3793 40.043C42.5607 40.2365 42.7234 40.4467 42.8652 40.6709Z" fill="white"/>
      <path d="M20.3574 42.9238C20.3564 43.9364 19.9902 44.9147 19.3261 45.6789C18.662 46.4431 17.7446 46.9418 16.7424 47.0834C15.7402 47.2251 14.7206 47.0001 13.8709 46.4499C13.0212 45.8997 12.3985 45.0612 12.1172 44.0884C11.8359 43.1157 11.915 42.0741 12.3398 41.1549C12.7646 40.2358 13.5066 39.5009 14.4296 39.0853C15.3526 38.6697 16.3944 38.6013 17.3638 38.8927C18.3331 39.184 19.1648 39.8156 19.706 40.6713C20.1322 41.3451 20.3581 42.1263 20.3574 42.9238Z" fill="#C0D921"/>
      <path d="M19.7062 40.6709L15.5575 44.821C15.2967 44.4171 13.844 42.5401 13.844 42.5401C14.0302 42.3518 14.2288 42.1764 14.4386 42.0149L15.6433 43.6219L19.2204 40.043C19.4018 40.2365 19.5645 40.4467 19.7062 40.6709Z" fill="white"/>
      <path d="M159.815 111.963H89.4496C89.4006 111.963 89.3536 111.944 89.319 111.909C89.2844 111.875 89.2649 111.828 89.2649 111.779C89.2649 111.73 89.2844 111.683 89.319 111.648C89.3536 111.613 89.4006 111.594 89.4496 111.594H159.815C159.864 111.594 159.911 111.613 159.946 111.648C159.981 111.683 160 111.73 160 111.779C160 111.828 159.981 111.875 159.946 111.909C159.911 111.944 159.864 111.963 159.815 111.963Z" fill="#0A4A3F"/>
    </g>
    <defs>
      <clipPath id="clip0_5644_1990">
        <rect width="160" height="111.928" fill="white" transform="translate(0 0.0351562)"/>
      </clipPath>
    </defs>
  </svg>
  )
}

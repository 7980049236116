import { useState, useEffect } from "react";
import { Box, HStack, Text, Avatar, Button, Link, Tooltip, useDisclosure } from "@chakra-ui/react";
import recentmock from "../../../static/images/recentmock.svg";
function MostRecent({ data, user }) {


  const [mock, setmock] = useState(false)
  const partnerName =
    data.creatorUid !== user.uid
      ? data.creatorFirstName
      : data.partnerFirstName;
  const partnerLastName =
    data.creatorUid !== user.uid ? data.creatorLastName : data.partnerLastName;
  const partnerImage =
    data.creatorUid === user.uid
      ? data.partnerAvatarUrl
      : data.creatorAvatarUrl;
  const datetime = data.eventDateTime.toDate();
  const partnerPhoneNumber = user.uid === data.creatorUid ? data.partnerPhoneNumber : data.creatorPhoneNumber;
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [buttonText, setbuttonText] = useState('Calculating...');
  const [showWhatsapp, setshowWhatsapp] = useState(false);
  const { isOpen: isTollTipOpen, onOpen: onTollTipOpen, onToggle: onTollTipToggle, onClose: onTollTipClose } = useDisclosure();

  useEffect(() => {
    const interval = setInterval(() => {
      const eventTime = data.eventDateTime.toDate();
      const currentTime = new Date();
      const diff = eventTime - currentTime;
      const diffHours = Math.floor((diff / 1000) / 3600)
      const diffDays = Math.floor(diffHours / 24)
      if (diffDays > 1) {
        setbuttonText(`Starting in ${diffDays} days`)
      }
      else if (diffDays === 1) {
        setbuttonText(`Starting in ${diffDays} day`)
      }
      else {
        if (diffHours > 1) { setbuttonText(`Starting in ${diffHours} hours`) }
        else if (diffHours === 1) { setbuttonText(`Starting in ${diffHours} hour`) }
        else { setmock(true) }
        if (diffHours <= 3)
          setshowWhatsapp(true);
      }

    }, 1000);
    return () => clearInterval(interval);
  }, [data]);

  function getTime(time) {
    const t = time;
    const hour = t.getHours();
    const minute = t.getMinutes();
    if (hour <= 12) {
      if (hour < 10) {
        if (minute < 10) {
          return `0${hour}:0${minute} ${hour === 12 ? "PM" : "AM"}`;
        } else {
          return `0${hour}:${minute} ${hour === 12 ? "PM" : "AM"}`;
        }
      } else {
        if (minute < 10) {
          return `${hour}:0${minute} ${hour === 12 ? "PM" : "AM"}`;
        } else {
          return `${hour}:${minute} ${hour === 12 ? "PM" : "AM"}`;
        }
      }
    } else {
      if (hour - 12 < 10) {
        if (minute < 10) {
          return `0${hour - 12}:0${minute} PM`;
        } else {
          return `0${hour - 12}:${minute} PM`;
        }
      } else {
        if (minute < 10) {
          return `${hour - 12}:0${minute} PM`;
        } else {
          return `${hour - 12}:${minute} PM`;
        }
      }
    }
  }

  function joinmeet() {
    window.open(data.eventLink);
  }

  return (
    <Box
      borderRadius={"24px"}
      w={"328px"}
      h={"264px"}
      p={"24px"}
      backgroundImage={recentmock}
    >
      <Text
        fontSize={"30px"}
        lineHeight={"36px"}
        fontWeight={"700"}
        color={"#4F590B"}
      >
        Upcoming mock
      </Text>
      <HStack mt={"28px"}>
        <Avatar size={"md"} name={partnerName} src={partnerImage} mr={"16px"} />
        <Box>
          <Text
            fontSize={"18px"}
            lineHeight={"28px"}
            fontWeight={"600"}
            color={"#72820D"}
          >
            {partnerName} {partnerLastName}
          </Text>
          <Text
            fontSize={"18px"}
            lineHeight={"28px"}
            fontWeight={"600"}
            color={"#72820D"}
          >
            {monthNames[datetime.getMonth()]} {datetime.getDate()} @{" "}
            {getTime(datetime)}
          </Text>
        </Box>
      </HStack>
      <HStack h={"32px"} mt={"68px"}>
        {!showWhatsapp && <Button size="sm" bg={"#83950F"} mr={"12px"} colorScheme={'secondary'} onClick={() => { window.open("https://resources.pearmock.com", "_blank") }}>
          Prepare a case
        </Button>}
        {
          showWhatsapp && partnerPhoneNumber !== null &&
          (<Button
            size="sm"
            bg={"#83950F"}
            mr={"12px"}
            colorScheme={'secondary'}
            as={Link}
            isExternal
            href={`https://wa.me/${partnerPhoneNumber}`}
          >
            Ping on Whats App
          </Button>)
        }
        {
          showWhatsapp && partnerPhoneNumber === null &&
          (
            <Tooltip
              hasArrow
              bg={'gray.900'}
              color={'white'}
              p={'8px'}
              isOpen={isTollTipOpen}
              label='This partner has not added their phone number yet.We have notified the partner to add it. Please try contacting via email in the mean time.'
            >
              <Button
                size="sm"
                bg={"#83950F"}
                mr={"12px"}
                colorScheme={'secondary'}
                onMouseEnter={onTollTipOpen}
                onMouseLeave={onTollTipClose}
                onClick={onTollTipToggle}
              >
                Ping on Whats App
              </Button>

            </Tooltip>
          )
        }
        {mock ? (
          <Button size={"sm"} onClick={joinmeet}>
            Start now!
          </Button>
        ) : (
          <Button size="sm" variant="unstyled" color={"#83950F"}>
            {buttonText}
          </Button>
        )}
      </HStack>
    </Box>
  );
}

export default MostRecent;

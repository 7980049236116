import Level5SVG from './SVGS/Level5SVG';
import Level4SVG from './SVGS/Level4SVG';
import Level3SVG from './SVGS/Level3SVG';
import Level2SVG from './SVGS/Level2SVG';
import Level1SVG from './SVGS/Level1SVG';
const Levels = [
    { name: 'Pearfect', desc: 'Reach 600 points and become the first person to crack Pearfect!', Logo: Level5SVG },
    { name: 'Golden Pear', desc: 'Reach 360 points and become the first person to crack Golden Pear!', Logo: Level4SVG },
    { name: 'Ripening Pear', desc: 'Reach 180 points and become the first person to crack Ripening Pear!', Logo: Level3SVG },
    { name: 'Green Pear', desc: 'Reach 60 points and become the first person to crack Green Pear!', Logo: Level2SVG },
    { name: 'Budding Pear', desc: 'Reach 60 points and become the first person to crack Green Pear!', Logo: Level1SVG },
];
export default Levels;
import React from 'react'

export default function level3Svg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="78" height="100" viewBox="0 0 78 100" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M38.9966 12.9688C60.2594 12.9688 77.4966 30.4002 77.4966 51.9031C77.4966 73.4059 60.2594 90.8376 38.9966 90.8376C17.7337 90.8376 0.496582 73.4061 0.496582 51.9031C0.496582 30.4001 17.7332 12.9688 38.9966 12.9688Z" fill="#D6582F"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M39.137 9.00391C60.3222 9.00391 77.4962 26.3689 77.4962 47.7896C77.4962 69.2102 60.3222 86.5752 39.137 86.5752C17.9518 86.5752 0.777832 69.2102 0.777832 47.7896C0.777832 26.3689 17.9518 9.00391 39.137 9.00391Z" fill="#E48059"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M39.3931 17.7852C55.9234 17.7852 69.3239 31.2797 69.3239 47.926C69.3239 64.5723 55.9239 78.067 39.3931 78.067C22.8622 78.067 9.4624 64.5718 9.4624 47.9253C9.4624 31.2788 22.8629 17.7852 39.3931 17.7852Z" fill="#D6582F"/>
  <g filter="url(#filter0_d_5726_2088)">
    <path fillRule="evenodd" clipRule="evenodd" d="M41.9127 29.7199C38.0174 28.7791 34.1242 35.0657 33.2654 38.3679C31.3344 45.7934 30.5472 45.8081 27.9124 48.2512C19.9857 55.602 24.2829 71.0457 36.9714 73.3717C47.4645 75.2954 54.927 64.5492 54.2667 57.3112C53.3065 46.7966 47.0417 48.9729 45.4722 40.3577C44.2259 33.5202 46.17 30.7481 41.9127 29.7199Z" fill="#F77340"/>
  </g>
  <path fillRule="evenodd" clipRule="evenodd" d="M36.5597 73.3729C47.0527 75.2964 54.9263 64.5494 54.266 57.3123C53.3058 46.7976 47.041 49.3858 45.4715 40.7706C41.5558 39.4653 33.2642 45.5781 41.9127 52.7823C45.8303 56.0458 47.8833 66.4408 39.0303 69.6669C37.4773 70.2328 29.628 73.5104 24.6182 62.2543C26.9515 69.0489 30.9365 72.3421 36.5597 73.3729Z" fill="#CB3C0E"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M43.9717 29.3079C43.9717 29.3079 43.7452 23.9544 48.9129 23.9544C52.2689 23.9544 53.6279 22.2866 53.4425 23.5426C53.2572 24.7986 49.7365 33.0966 43.9717 29.3079Z" fill="#C83607"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M43.1479 28.4837C43.1479 28.4837 44.3216 25.0863 39.4419 24.7773C40.0802 29.328 42.1597 28.6485 43.1479 28.4837Z" fill="#C83607"/>
  <path opacity="0.25" fillRule="evenodd" clipRule="evenodd" d="M39.1373 9.00391C60.3225 9.00391 77.4965 26.3689 77.4965 47.7896C77.5079 52.9393 76.4962 58.0399 74.5201 62.7954C72.544 67.5508 69.643 71.8664 65.9853 75.4914C12.2931 20.4414 65.9978 75.4029 12.147 20.2294C15.6829 16.6703 19.8881 13.8461 24.5204 11.9195C29.1526 9.99287 34.1204 9.00199 39.1373 9.00391Z" fill="#C3C340"/>
  <defs>
    <filter id="filter0_d_5726_2088" x="21.9155" y="27.625" width="34.3918" height="47.9727" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset/>
      <feGaussianBlur stdDeviation="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.827451 0 0 0 0 0.360784 0 0 0 0 0.360784 0 0 0 0.73 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5726_2088"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5726_2088" result="shape"/>
    </filter>
  </defs>
</svg>
  )
}

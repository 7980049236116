import React from 'react'

export default function ProgressSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="160" height="106" viewBox="0 0 160 106" fill="none">
  <g clip-path="url(#clip0_5644_2035)">
    <path d="M19.8031 73.5939L6.28916 68.8718C4.80139 61.5018 5.135 53.8799 7.26104 46.668C12.3877 59.0117 28.3775 62.4628 37.2556 72.4537C40.0007 75.591 42.0048 79.3059 43.1192 83.323C44.2336 87.3402 44.4298 91.5567 43.6933 95.6601L46.2809 105.478C39.5955 104.712 33.1558 102.504 27.4055 99.0089C21.6551 95.5134 16.7308 90.8127 12.9719 85.2306C10.8942 82.0782 9.22169 78.6766 7.99385 75.1062C13.8273 74.6924 19.8031 73.5939 19.8031 73.5939Z" fill="#F2F2F2"/>
    <path d="M57.2909 91.4435C57.8697 86.7887 60.7541 82.2023 65.1927 80.6875C63.4422 85.4786 63.4426 90.7346 65.1938 95.5254C65.8758 97.3706 66.8265 99.3519 66.185 101.212C65.7858 102.369 64.8093 103.254 63.7177 103.808C62.6261 104.361 61.4147 104.625 60.2184 104.884L59.9829 105.078C58.0838 100.789 56.7121 96.0983 57.2909 91.4435Z" fill="#F0F0F0"/>
    <path d="M65.2366 80.7766C62.6517 83.6733 61.0452 87.3109 60.6451 91.1728C60.5442 92.0026 60.5747 92.8432 60.7356 93.6635C60.918 94.4575 61.3098 95.1882 61.8701 95.7796C62.3815 96.3416 62.9696 96.8571 63.3355 97.5336C63.5177 97.8827 63.624 98.2663 63.6475 98.6593C63.671 99.0523 63.6112 99.4459 63.472 99.8142C63.149 100.741 62.5123 101.496 61.8639 102.213C61.1441 103.009 60.3838 103.825 60.0779 104.88C60.0408 105.008 59.8446 104.943 59.8816 104.815C60.4139 102.979 62.1962 101.936 63.0461 100.282C63.4427 99.5105 63.6092 98.6146 63.2374 97.7999C62.9123 97.0876 62.3062 96.5554 61.7835 95.9909C61.2285 95.422 60.8211 94.7258 60.5971 93.9631C60.395 93.1536 60.3321 92.3156 60.4112 91.485C60.5593 89.6123 61.0009 87.7744 61.7199 86.0389C62.5294 84.0535 63.6725 82.2213 65.0998 80.6215C65.1882 80.5226 65.3244 80.6782 65.2366 80.7766Z" fill="white"/>
    <path d="M60.7008 89.9196C60.062 89.7645 59.4992 89.3873 59.1128 88.8555C58.7265 88.3237 58.5417 87.6719 58.5915 87.0164C58.602 86.8838 58.8085 86.8939 58.798 87.0267C58.7505 87.637 58.9227 88.2442 59.2835 88.7387C59.6443 89.2332 60.1699 89.5823 60.7656 89.7232C60.895 89.754 60.8294 89.9501 60.7008 89.9196Z" fill="white"/>
    <path d="M61.5733 95.5285C62.727 94.8396 63.5786 93.7411 63.9584 92.4521C63.9959 92.3243 64.1921 92.3893 64.1547 92.5169C63.7569 93.8576 62.8684 94.9989 61.6663 95.7132C61.5517 95.7812 61.4593 95.5962 61.5733 95.5285Z" fill="white"/>
    <path d="M62.7213 84.247C62.956 84.3586 63.2146 84.41 63.4741 84.3969C63.7335 84.3837 63.9856 84.3063 64.2078 84.1716C64.3215 84.1021 64.4137 84.2873 64.3007 84.3562C64.0546 84.5039 63.7761 84.5891 63.4895 84.6042C63.2029 84.6194 62.9169 84.5642 62.6565 84.4434C62.631 84.434 62.61 84.4153 62.5978 84.3911C62.5856 84.3668 62.5831 84.3388 62.5908 84.3128C62.5995 84.2868 62.6181 84.2654 62.6425 84.2531C62.667 84.2407 62.6953 84.2386 62.7213 84.247Z" fill="white"/>
    <path d="M78.7223 89.0027C78.6526 89.0481 78.5828 89.0935 78.513 89.1406C77.5782 89.7554 76.6949 90.4451 75.8718 91.2029C75.8072 91.2605 75.7426 91.3198 75.6798 91.3792C73.7163 93.2261 72.1126 95.4217 70.9504 97.8542C70.4888 98.8225 70.1015 99.8246 69.792 100.852C69.3645 102.27 69.0139 103.842 68.1678 105.003C68.0808 105.125 67.9864 105.241 67.8851 105.352H60.2387C60.2213 105.343 60.2039 105.336 60.1864 105.327L59.8811 105.341C59.8934 105.287 59.9073 105.231 59.9195 105.177C59.9265 105.146 59.9352 105.114 59.9422 105.083C59.9474 105.062 59.9527 105.041 59.9562 105.022C59.9579 105.015 59.9596 105.008 59.9613 105.003C59.9648 104.983 59.9701 104.966 59.9736 104.949C60.0504 104.636 60.1294 104.324 60.2108 104.012C60.2108 104.01 60.2108 104.01 60.2126 104.008C60.8389 101.63 61.6693 99.2832 62.8294 97.137C62.8643 97.0725 62.8992 97.0062 62.9376 96.9416C63.4659 95.9772 64.0721 95.0575 64.7502 94.1918C65.123 93.719 65.5195 93.2653 65.9382 92.8326C67.0222 91.7166 68.275 90.778 69.6506 90.0514C72.3931 88.6032 75.5682 88.0483 78.4991 88.933C78.574 88.9557 78.6474 88.9783 78.7223 89.0027Z" fill="#F0F0F0"/>
    <path d="M78.705 89.099C74.8974 89.8554 71.4249 91.7924 68.7806 94.635C68.2005 95.2368 67.7189 95.9263 67.3535 96.6782C67.0212 97.4221 66.8941 98.2414 66.9855 99.051C67.0555 99.8076 67.2148 100.573 67.0997 101.334C67.035 101.722 66.889 102.093 66.6711 102.421C66.4533 102.748 66.1686 103.027 65.8358 103.237C65.02 103.782 64.0569 104.002 63.1077 104.184C62.0537 104.386 60.9557 104.58 60.0761 105.238C59.9695 105.318 59.852 105.148 59.9584 105.068C61.4888 103.923 63.5397 104.163 65.214 103.354C65.9952 102.977 66.6675 102.362 66.861 101.487C67.0302 100.723 66.8667 99.933 66.7892 99.1675C66.6884 98.379 66.7823 97.5778 67.0625 96.834C67.3885 96.066 67.8428 95.359 68.4059 94.7435C69.6515 93.3374 71.1105 92.1359 72.7293 91.183C74.5708 90.0853 76.5865 89.3108 78.6891 88.8929C78.8193 88.867 78.8343 89.0734 78.705 89.099Z" fill="white"/>
    <path d="M69.5782 93.6703C69.1616 93.1619 68.9392 92.5219 68.9509 91.8646C68.9625 91.2073 69.2074 90.5755 69.6417 90.0822C69.73 89.9826 69.8887 90.1151 69.8004 90.2147C69.3951 90.6734 69.167 91.2619 69.1575 91.874C69.1479 92.4861 69.3574 93.0814 69.7481 93.5526C69.8329 93.6551 69.6625 93.7722 69.5782 93.6703Z" fill="white"/>
    <path d="M66.8995 98.6736C68.2353 98.8182 69.5766 98.4539 70.6558 97.6534C70.7627 97.574 70.8802 97.7441 70.7735 97.8234C69.6489 98.6543 68.2523 99.0306 66.8626 98.877C66.7301 98.8623 66.7678 98.659 66.8995 98.6736Z" fill="white"/>
    <path d="M74.6062 90.3568C74.7264 90.5871 74.902 90.784 75.117 90.9297C75.3321 91.0754 75.58 91.1654 75.8385 91.1916C75.9711 91.2047 75.9333 91.408 75.8016 91.395C75.5161 91.3647 75.2425 91.265 75.0045 91.1046C74.7665 90.9441 74.5714 90.7278 74.4363 90.4745C74.4216 90.4517 74.4161 90.4242 74.4209 90.3974C74.4258 90.3707 74.4406 90.3469 74.4624 90.3307C74.485 90.3152 74.5128 90.3093 74.5397 90.3142C74.5666 90.3191 74.5905 90.3344 74.6062 90.3568Z" fill="white"/>
    <path d="M83.0649 1.70703C82.7412 1.70739 82.4308 1.83619 82.2018 2.06516C81.9729 2.29413 81.8441 2.60458 81.8438 2.9284V54.5748C81.8441 54.8986 81.9729 55.209 82.2019 55.4379C82.4308 55.6669 82.7412 55.7957 83.0649 55.796H158.779C159.103 55.7957 159.413 55.6669 159.642 55.4379C159.871 55.209 160 54.8986 160 54.5748V2.9284C160 2.60459 159.871 2.29415 159.642 2.06518C159.413 1.83621 159.103 1.70741 158.779 1.70703H83.0649Z" fill="#E6E6E6"/>
    <path d="M84.8096 3.44922C84.4858 3.44958 84.1754 3.57838 83.9465 3.80735C83.7175 4.03632 83.5887 4.34677 83.5884 4.67059V52.8272C83.5887 53.1511 83.7175 53.4615 83.9465 53.6905C84.1754 53.9195 84.4858 54.0483 84.8096 54.0486H157.034C157.358 54.0482 157.668 53.9194 157.897 53.6905C158.126 53.4615 158.255 53.1511 158.255 52.8272V4.67059C158.255 4.34677 158.126 4.03633 157.897 3.80737C157.668 3.5784 157.358 3.4496 157.034 3.44922H84.8096Z" fill="white"/>
    <path opacity="0.1" d="M151.449 13.9855C149.788 13.337 147.997 14.9635 147.608 16.7037C147.219 18.444 147.717 20.2437 147.827 22.0236C147.936 23.8035 147.47 25.8703 145.882 26.6817C144.057 27.6147 141.915 26.4017 139.873 26.2169C138.854 26.136 137.83 26.3149 136.899 26.7369C135.968 27.1589 135.158 27.8102 134.547 28.63C132.863 30.9406 132.949 34.2958 130.956 36.346C129.225 38.1274 126.403 38.3593 123.981 37.8085C121.559 37.2576 119.332 36.0609 116.95 35.3554C114.569 34.6498 111.812 34.5025 109.771 35.9185C107.64 37.3968 106.692 40.2944 104.353 41.414C102.699 42.2057 100.741 41.9037 98.9712 41.4243C97.2013 40.9449 95.4166 40.2982 93.5894 40.452C91.7623 40.6059 89.8575 41.8668 89.7126 43.6949L152.423 43.5198C152.708 35.2414 152.993 26.963 153.277 18.6846C153.339 16.8862 153.125 14.6399 151.449 13.9855Z" fill="#C0D921"/>
    <path d="M57.5967 58.4002C57.4875 58.1125 57.4427 57.8042 57.4655 57.4973C57.4883 57.1903 57.5781 56.8921 57.7286 56.6236C57.879 56.3551 58.0866 56.1229 58.3365 55.9433C58.5864 55.7637 58.8727 55.6411 59.1751 55.5841L60.2768 48.1836L63.1716 50.7787L61.6817 57.4085C61.7437 57.923 61.6149 58.4423 61.3196 58.8682C61.0244 59.294 60.5832 59.5968 60.0796 59.719C59.5761 59.8413 59.0452 59.7745 58.5876 59.5315C58.13 59.2884 57.7774 58.8859 57.5967 58.4002Z" fill="#FFD0B6"/>
    <path d="M37.3961 59.5275C37.5841 59.2838 37.7164 59.0018 37.7836 58.7015C37.8509 58.4011 37.8515 58.0897 37.7854 57.7891C37.7193 57.4884 37.588 57.206 37.401 56.9616C37.2139 56.7172 36.9755 56.5167 36.7026 56.3744L37.7955 48.9727L34.2723 50.616L33.7746 57.3929C33.566 57.8673 33.5386 58.4017 33.6976 58.8949C33.8566 59.3881 34.191 59.8059 34.6374 60.069C35.0838 60.3321 35.6112 60.4223 36.1196 60.3225C36.6281 60.2227 37.0822 59.9398 37.3961 59.5275Z" fill="#FFD0B6"/>
    <path d="M52.8824 102.083L56.1511 102.083L57.7062 89.4727L52.8818 89.473L52.8824 102.083Z" fill="#FFD0B6"/>
    <path d="M52.0483 101.016L58.4859 101.016H58.4861C59.5742 101.016 60.6176 101.448 61.387 102.217C62.1563 102.987 62.5886 104.03 62.5886 105.119V105.252L52.0485 105.252L52.0483 101.016Z" fill="#151E2B"/>
    <path d="M35.4366 102.083L38.7053 102.083L40.2604 89.4727L35.436 89.473L35.4366 102.083Z" fill="#FFD0B6"/>
    <path d="M34.6028 101.016L41.0403 101.016H41.0406C42.1286 101.016 43.1721 101.448 43.9414 102.217C44.7108 102.987 45.143 104.03 45.1431 105.119V105.252L34.603 105.252L34.6028 101.016Z" fill="#151E2B"/>
    <path d="M50.82 16.0069C54.0812 16.0069 56.7249 13.3628 56.7249 10.1011C56.7249 6.83943 54.0812 4.19531 50.82 4.19531C47.5588 4.19531 44.915 6.83943 44.915 10.1011C44.915 13.3628 47.5588 16.0069 50.82 16.0069Z" fill="#FFD0B6"/>
    <path d="M36.842 52.6757C36.842 52.6757 36.9289 79.1033 36.5071 79.5252C36.0854 79.947 34.5881 95.7519 34.5881 95.7519H39.8218C40.1707 95.7519 49.0737 61.3235 49.0737 61.3235C49.0737 61.3235 52.8698 79.8846 52.2371 80.9392C51.6044 81.9938 52.0976 95.8188 52.448 96.9693C52.448 96.9693 56.8456 98.3768 57.2674 97.3222C57.6892 96.2676 58.3141 81.9679 58.3141 81.9679L59.5353 58.2385L59.562 52.043L36.842 52.6757Z" fill="#151E2B"/>
    <path d="M61.0944 22.3086H63.444C63.6166 22.3085 63.7842 22.3672 63.919 22.475C64.0539 22.5828 64.148 22.7333 64.186 22.9018C64.5485 24.5075 65.4829 29.0591 65.3122 32.6437C65.1013 37.0731 64.6795 44.0335 64.6795 44.6663C64.6795 45.299 62.5706 55.0014 62.5706 55.0014C62.5706 55.0014 61.1208 52.6182 59.5259 53.0716L59.829 44.4554L60.2508 40.8697L59.6181 34.5421L61.0944 22.3086Z" fill="#E4E4E4"/>
    <path d="M46.1211 18.4453C46.1211 18.4453 38.6345 20.9361 37.7909 23.0453C36.9474 25.1545 41.0429 40.4454 41.0429 40.4454C41.0429 40.4454 39.4728 47.4247 37.3691 54.0508C36.4118 57.0662 45.5478 54.4845 49.7657 53.0081C52.3792 52.1015 57.2133 53.4937 59.9345 52.9961C59.9345 52.9961 60.3563 47.3013 59.9345 46.2467C59.5127 45.1921 58.4582 45.8248 59.0909 44.3484C59.7236 42.8719 59.5127 42.661 59.3018 42.0282C59.0909 41.3955 59.7236 40.5518 59.7236 39.7081C59.7236 38.8644 59.5127 36.9661 59.5127 36.9661C59.5127 36.9661 65.2068 23.6781 63.9414 22.4125C62.6761 21.147 56.2351 18.7085 56.2351 18.7085C56.2351 18.7085 56.395 18.8098 46.1211 18.4453Z" fill="#E4E4E4"/>
    <path d="M39.1619 22.3086L38.4129 22.6831C38.1214 22.8289 37.8675 23.0402 37.6712 23.3005C37.4749 23.5608 37.3415 23.8629 37.2815 24.1833L34.3114 40.026L33.6787 55.0014C33.6787 55.0014 37.2639 53.9468 38.3183 55.0014C38.3183 55.0014 37.2639 51.8376 37.8965 50.783C38.5292 49.7284 38.5292 48.2519 38.5292 48.2519C38.5292 48.2519 38.1074 48.2519 39.1619 47.1973C40.2163 46.1427 40.4272 45.7209 40.2163 45.51C40.0055 45.2991 39.7946 43.4008 39.7946 43.1898C39.7946 42.9789 41.9035 28.8472 41.9035 28.8472L39.1619 22.3086Z" fill="#E4E4E4"/>
    <path d="M89.4129 43.4883C89.3203 43.4883 89.2316 43.4516 89.1662 43.3861C89.1007 43.3207 89.064 43.2319 89.064 43.1394C89.064 43.0468 89.1007 42.958 89.1662 42.8926C89.2316 42.8272 89.3203 42.7904 89.4129 42.7904C105.535 42.7904 120.911 39.5618 132.708 33.6993C143.769 28.202 150.818 20.7473 152.047 13.2467C152.054 13.2014 152.071 13.158 152.095 13.119C152.119 13.0799 152.15 13.046 152.187 13.0191C152.225 12.9923 152.267 12.973 152.311 12.9625C152.356 12.9519 152.402 12.9503 152.448 12.9576C152.493 12.965 152.536 12.9812 152.575 13.0053C152.614 13.0295 152.648 13.061 152.675 13.0983C152.702 13.1355 152.721 13.1777 152.731 13.2223C152.742 13.267 152.743 13.3133 152.736 13.3585L152.736 13.3595C152.098 17.2558 150.026 21.0777 146.578 24.7193C143.178 28.3109 138.616 31.5426 133.018 34.3243C121.127 40.2338 105.641 43.4883 89.4129 43.4883Z" fill="#C0D921"/>
    <path d="M155.639 43.5583H88.9966V10.6289H89.3455V43.2094H155.639V43.5583Z" fill="#E6E6E6"/>
    <path d="M45.2546 12.0908C44.9847 11.4282 44.9412 10.695 45.1311 10.0053C45.6134 11.2848 46.5717 10.8942 47.06 10.2942C47.6724 9.5417 47.4961 7.80391 48.463 7.72615C49.4299 7.64839 50.3967 7.8314 51.3495 8.01358C51.1916 7.74324 51.0337 7.47289 50.8759 7.20255C51.5651 7.24533 52.2543 7.28811 52.9435 7.33088C52.7384 6.9932 52.5331 6.65552 52.3279 6.31786C53.2648 6.64713 54.1218 7.17016 54.8431 7.85286C55.5645 8.53555 56.1338 9.3626 56.5142 10.2801C56.8717 9.70137 57.0944 9.04963 57.1658 8.37315C57.2372 7.69666 57.1555 7.01278 56.9268 6.37216C56.698 5.73154 56.3281 5.15059 55.8444 4.67238C55.3607 4.19416 54.7756 3.83092 54.1325 3.60957C54.4044 3.25282 54.6762 2.89607 54.9481 2.53933L49.4141 1.99317C49.4004 1.7264 49.4518 1.46029 49.564 1.21788C49.6762 0.975471 49.8458 0.76407 50.0581 0.601981C49.3334 0.438318 48.5648 0.683828 47.9491 1.09944C47.3333 1.51505 46.8436 2.08818 46.362 2.65392C45.6329 3.51054 44.895 4.38057 44.4178 5.3992C43.9282 6.48362 43.7473 7.6821 43.895 8.86272C44.0426 10.0433 44.5131 11.1603 45.2546 12.0908ZM45.3214 9.49188L45.3215 9.49156L45.3271 9.49058L45.3214 9.49188Z" fill="#151E2B"/>
    <path d="M113.132 105.451H0.207609C0.152622 105.451 0.0999431 105.429 0.0611176 105.39C0.0222922 105.351 0.000488281 105.298 0.000488281 105.243C0.000488281 105.188 0.0222922 105.135 0.0611176 105.096C0.0999431 105.057 0.152622 105.035 0.207609 105.035H113.132C113.187 105.035 113.24 105.057 113.278 105.096C113.317 105.135 113.339 105.188 113.339 105.243C113.339 105.298 113.317 105.351 113.278 105.39C113.24 105.429 113.187 105.451 113.132 105.451Z" fill="#CACACA"/>
  </g>
  <defs>
    <clipPath id="clip0_5644_2035">
      <rect width="160" height="104.898" fill="white" transform="translate(0 0.550781)"/>
    </clipPath>
  </defs>
</svg>
  )
}

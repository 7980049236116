import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { Box, Flex, Link, Text, Image } from '@chakra-ui/react';
import recentmock from "../../../static/images/recentmock.svg";
import { db } from '../../../firebase';
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
const BlogCard = ({ title, link, image }) => {
    return (
        <Link
            href={link}
            _hover={{
                textDecoration: 'none'
            }}
            isExternal
        >
            <Flex
                flexDirection={'column'}
                gap={2}
                alignItems={'center'}
                justifyContent={'space-evenly'}
                p={'24px'}
                bg={recentmock}
            >
                <Image
                    src={image}
                    alt={title}
                    w={'296px'}
                    h={'136px'}
                    objectFit={'cover'}
                    borderRadius={'12px'}
                />
                <Text
                    fontSize={'20px'}
                    color={'#4F590B'}
                    fontWeight={700}
                >
                    {title}
                </Text>
            </Flex>
        </Link>

    )
}
export default function SliderCard() {
    const [blogs, setBlogs] = useState([]);
    useEffect(() => {
        const getBlogs = async ()=>{
            try {     
                const q = query(collection(db, 'blogs'), orderBy('createdAt', 'desc'), limit(3));
                const unsubscribe =  onSnapshot(q, (querySnapshot) => {
                    const blogs = [];
                    querySnapshot.forEach((doc) => {
                        blogs.push({ ...doc.data(), id: doc.id });
                    });
                    setBlogs(blogs);
                });
                return unsubscribe;
            } catch (error) {
                console.log('Error getting blogs', error);
            }
        }
        getBlogs();
    }, []);
    return (
        <Box
            w={"328px"}
            h={"264px"}
            borderRadius={'24px'}
        >
            <Swiper
                style={
                    {
                        height: "100%",
                        width: "100%",
                        backgroundImage: `url(${recentmock})`,
                        borderRadius: '24px',
                        "--swiper-pagination-color": "#4A5568",
                    }}
                centeredSlides={true}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
            >
                {
                    blogs.map((item, index) => {
                        return (
                            <SwiperSlide
                                key={`${index}-${item.link}`}
                            >
                                <BlogCard
                                    title={item.title}
                                    link={item.link}
                                    image={item.imageUrl}
                                />
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </Box>
    )
}

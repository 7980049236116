import {
  Box,
  Text,
  Icon,
  useToast,
  Avatar,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from "@chakra-ui/react";
import { FaLinkedin } from "react-icons/fa";
import { level_name_map } from "../../../constants/level";
import { amplitude } from "../../../analytics/analytics";

function Partner({
  partnerName,
  partnerEmail,
  partnerLinkedin,
  partnerwork,
  partnerImage,
  partnerEducation,
  partnerCurrentJobRole,
  partnerCurrentCompany,
  partnerTargetCompanies,
  partnerWorkExperience,
  partnerLevel
}) {
  const toast = useToast();
  return (
    <>
      <Box h={'64px'}>
        <Box display={"inline-flex"}>
          <Popover placement="bottom-start">
            <PopoverTrigger>
              <Avatar
                size={"lg"}
                name={partnerName}
                src={partnerImage}
                onClick={()=>{amplitude.track('user info tooltip clicked')}}
                mr={"12px"}
                role={"button"}
                tabIndex={0}
              />
            </PopoverTrigger>
            <PopoverContent w={"280px"} borderRadius={"8px"}>
              <PopoverBody
                boxShadow={"0px 0px 8px 1px rgba(0, 0, 0, 0.3)"}
                p={"16px"}
                borderRadius={"8px"}
              >
                <Box display={"inline-flex"}>
                  <Avatar
                    size={"xs"}
                    name={partnerName}
                    src={partnerImage}
                    mr={"8px"}
                  />
                  <Text
                    fontSize={"16px"}
                    lineHeight={"24px"}
                    fontWeight={"500"}
                  >
                    {partnerName}
                  </Text>
                </Box>
                {/* <Text
                  mt={"4px"}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  fontWeight={"400"}
                >
                  {partnerwork}
                </Text> */}
                <Text
                  mt={"16px"}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  fontWeight={"700"}
                >
                  Reach out:{" "}
                  <Text
                    fontWeight={"500"}
                    color={"blue"}
                    as={"button"}
                    textDecoration={"underline"}
                    onClick={(e) => {
                      navigator.clipboard.writeText(partnerEmail);
                      toast({
                        title: "Copied to clipboard",
                        description:
                          "Partner Email is copied to your clipboard",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                      });
                    }}
                  >
                    Email
                  </Text>{" "}
                  <Text
                    as={"button"}
                    fontWeight={"500"}
                    color={"blue"}
                    textDecoration={"underline"}
                    onClick={(e) => {
                      amplitude.track('linkedin button clicked inside popover')
                      window.open(partnerLinkedin);
                    }}
                  >
                    Linkedin
                  </Text>
                </Text>
                <Text
                  mt={"8px"}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  fontWeight={"700"}
                >
                  Education:{" "}
                  <Text
                    fontSize={"12px"}
                    lineHeight={"16px"}
                    fontWeight={"400"}
                    as={"span"}
                  >
                    {partnerEducation}
                  </Text>
                </Text>
                {partnerCurrentJobRole === "" &&
                partnerCurrentCompany === "" ? null : partnerCurrentJobRole ===
                  "" ? (
                  <>
                    <Text
                      mt={"8px"}
                      fontSize={"12px"}
                      lineHeight={"16px"}
                      fontWeight={"700"}
                    >
                      Current Job:{" "}
                      <Text
                        fontSize={"12px"}
                        lineHeight={"16px"}
                        fontWeight={"400"}
                        as={"span"}
                      >
                        {partnerCurrentCompany}
                      </Text>
                    </Text>
                  </>
                ) : partnerCurrentCompany === "" ? (
                  <>
                    <Text
                      mt={"8px"}
                      fontSize={"12px"}
                      lineHeight={"16px"}
                      fontWeight={"700"}
                    >
                      Current Job:{" "}
                      <Text
                        fontSize={"12px"}
                        lineHeight={"16px"}
                        fontWeight={"400"}
                        as={"span"}
                      >
                        {partnerCurrentJobRole}
                      </Text>
                    </Text>
                  </>
                ) : (
                  <>
                    <Text
                      mt={"8px"}
                      fontSize={"12px"}
                      lineHeight={"16px"}
                      fontWeight={"700"}
                    >
                      Current Job:{" "}
                      <Text
                        fontSize={"12px"}
                        lineHeight={"16px"}
                        fontWeight={"400"}
                        as={"span"}
                      >
                        {partnerCurrentJobRole} @ {partnerCurrentCompany}
                      </Text>
                    </Text>
                  </>
                )}
                {/* <Box mt={"8px"} display={"inline"}>
                  <Text
                    fontSize={"12px"}
                    lineHeight={"16px"}
                    fontWeight={"700"}
                    mr={"2px"}
                  >
                    Target companies:{" "}
                  </Text>
                  {partnerTargetCompanies.map((doc, index) => {
                    return index + 1 === partnerTargetCompanies.length ? (
                      <Text
                        as={"span"}
                        fontSize={"12px"}
                        lineHeight={"16px"}
                        key={doc}
                        fontWeight={"400"}
                        color={"black"}
                      >
                        {doc}
                      </Text>
                    ) : (
                      <Text
                        as={"span"}
                        fontSize={"12px"}
                        key={doc}
                        lineHeight={"16px"}
                        fontWeight={"400"}
                        color={"black"}
                      >
                        {doc},{" "}
                      </Text>
                    );
                  })}
                </Box> */}
                <Text
                  mt={"8px"}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  fontWeight={"700"}
                >
                  Work Experience:{" "}
                  <Text
                    fontSize={"12px"}
                    lineHeight={"16px"}
                    fontWeight={"400"}
                    as={"span"}
                  >
                    {partnerWorkExperience}
                  </Text>
                </Text>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Box mt={"8px"}>
            <Box>
              <Text fontWeight={"700"} fontSize={"24px"} lineHeight={"20px"}>
                {partnerName}
              </Text>
              <Box display={"inline-flex"} mt={"12px"}>
              <Icon
                as={FaLinkedin}
                w={"18px"}
                h={"18px"}
                color={"blue.600"}
                cursor={"pointer"}
                onClick={(event) => {
                  amplitude.track('linkedin button clicked card')
                  window.open(partnerLinkedin)}}
              />
              <Text fontSize={"16px"} fontWeight={"400"} lineHeight={"120%"} ml={"11px"}>
                {level_name_map.get(partnerLevel)}
              </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* <Text
          fontSize={"16px"}
          fontWeight={"500"}
          lineHeight={"21.12px"}
          mt={"16px"}
        >
          {partnerwork}
        </Text> */}
      </Box>
    </>
  );
}

export default Partner;

import { useState,useEffect } from "react";
import {
  Box,
  Text,
  Button,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Radio,
  RadioGroup,
  FormControl,
  FormErrorMessage,
  Divider,
  HStack,
  Tooltip,
  Circle,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  AlertDialogHeader,
  Center,
  Spacer
} from "@chakra-ui/react";
import {
  CheckIcon,
  WarningTwoIcon,
  StarIcon
} from "@chakra-ui/icons";
import Time from '../../common/Time'
import Partner from "../../common/Partner"
import Rating from './Rating'
import {useNavigate} from 'react-router-dom'
import baseUrl from '../../../../constants/urls'
import {db,functions} from '../../../../firebase'
import {httpsCallable} from 'firebase/functions'
import {doc,setDoc,updateDoc,increment, getDoc} from 'firebase/firestore'
import Mockinfo from "../../common/Mockinfo";
import { ppm, level_point_map } from "../../../../constants/level";
import { amplitude } from "../../../../analytics/analytics";

function Pastcard({ data, user }) {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen:isOpenComplete, onOpen:onOpenComplete, onClose:onCloseComplete } = useDisclosure();
  const { isOpen:isOpenReview, onOpen:onOpenReview, onClose:onCloseReview } = useDisclosure();
  const { isOpen:isOpenReport, onOpen:onOpenReport, onClose:onCloseReport } = useDisclosure();
  const { isOpen:isOpenfeedback, onOpen:onOpenfeedback, onClose:onClosefeedback } = useDisclosure();
  const [value, setValue] = useState(null);
  const [error, seterror] = useState(false);
  const [feedback, setfeedback] = useState(null);
  const [behavior, setbehavior] = useState(null);
  const [punctual, setpunctual] = useState(null);
  const [completeError, setcompleteError] = useState(false);
  const [reviewDialog, setreviewDialog] = useState(false);
  const [flagDialog, setflagDialog] = useState(false);
  const [loading, setloading] = useState(false);
  const [completedBy, setcompletedBy] = useState(null);
  const [staticFeedback, setstaticFeedback] = useState(null);
  const [staticBehavior, setstaticBehavior] = useState(null);
  const [staticPunctual, setstaticPunctual] = useState(null);
  const [showRating, setshowRating] = useState(false);

  function updateLevel(levelMock){
    var level;
    for(var [key,value] of level_point_map){
      if(levelMock >= value){
        level = key;
      }
    }
    return level;
  }

  useEffect(() => {
    if(data.actionBy !== undefined){
      const peer = data.creatorUid === user.uid?"creator":"partner"
      if(peer === "creator" && data.creatorFeedback !== undefined){
        setstaticFeedback(data.creatorFeedback)
        setstaticBehavior(data.creatorBehavior)
        setstaticPunctual(data.creatorPunctual)
        setshowRating(true)
      }
      if(peer === "partner" && data.partnerFeedback !== undefined){
        setstaticFeedback(data.partnerFeedback)
        setstaticBehavior(data.partnerBehavior)
        setstaticPunctual(data.partnerPunctual)
        setshowRating(true)
      }
    }
  }, [data]);
  
  
  const timeprops = {
    datetime: data.eventDateTime.toDate(),
  };

  const partnerName = (data.creatorUid === user.uid)?(data.partnerFirstName):(data.creatorFirstName)
  const partnerEmail = (data.creatorUid === user.uid)?(data.partnerEmail):(data.creatorEmail)
  const partnerlevel = (data.creatorUid === user.uid)?(data.partnerLevel):(data.creatorLevel)
  const partnerLinkedin = (data.creatorUid === user.uid)?(data.partnerLinkedin):(data.creatorLinkedin)
  const partnerMocksCompleted = (data.creatorUid === user.uid)?(data.partnerMocksCompleted):(data.creatorMocksCompleted)
  // const partnerwork = (data.creatorUid === user.uid)?(data.partnerBio):(data.creatorBio)
  const partnerImage = (data.creatorUid === user.uid)?(data.partnerAvatarUrl):(data.creatorAvatarUrl)
  const partnerEducation = (data.creatorUid === user.uid)?(data.partnerEducationCollegeName):(data.creatorEducationCollegeName)
  const partnerCurrentJobRole = (data.creatorUid === user.uid)?(data.partnerCurrentJobRole):(data.creatorCurrentJobRole)
  const partnerCurrentCompany = (data.creatorUid === user.uid)?(data.partnerCurrentCompanyl):(data.creatorCurrentCompany)
  // const partnerTargetCompanies = (data.creatorUid === user.uid)?(data.partnerTargetCompanies):(data.creatorTargetCompanies)
  // const partnerTargetProfile = (data.creatorUid === user.uid)?(data.partnerTargetProfile):(data.creatorTargetProfile)
  const partnerWorkExperience = (data.creatorUid === user.uid)?(data.partnerWorkExperience):(data.creatorWorkExperience)
  const youPracticeCaseType = (data.creatorUid === user.uid)?(data.creatorCaseType):(data.partnerCaseType)
  const youTakeCaseType = (data.creatorUid === user.uid)?(data.partnerCaseType):(data.creatorCaseType)

  const partnerprops = {
    partnerName: partnerName,
    partnerEmail: partnerEmail,
    partnerLinkedin: partnerLinkedin,
    partnerMocksCompleted: partnerMocksCompleted,
    partnerImage: partnerImage,
    partnerEducation: partnerEducation,
    partnerCurrentJobRole: partnerCurrentJobRole,
    partnerCurrentCompany:partnerCurrentCompany,
    partnerWorkExperience: partnerWorkExperience,
    partnerLevel: partnerlevel
  };

  function getTagObject(level){
    var obj;
    if(level === 0 || level === 1){
      obj = {
        T1: true,
        T2: true,
        T3: true,
        T4: true,
        T5: true
      }
    }
    else if(level === 2){
      obj = {
        T1: false,
        T2: true,
        T3: true,
        T4: true,
        T5: true
      }
    }
    else if(level === 3){
      obj = {
        T1: false,
        T2: false,
        T3: true,
        T4: true,
        T5: true
      }
    }
    else if(level === 4){
      obj = {
        T1: false,
        T2: false,
        T3: false,
        T4: true,
        T5: true
      }
    }
    else if(level === 5){
      obj = {
        T1: false,
        T2: false,
        T3: false,
        T4: false,
        T5: true
      }
    }

    return obj
  }


  const mockinfo = {
    topHeading: "You solved",
    creatorCaseType: youPracticeCaseType,
    bottomHeading: "You asked",
    willTakeType: youTakeCaseType
  }

  function PastButtons({data, user}){
    const past = data.past
    if(past === "Complete"){
      return (
        <Button size={"sm"} w={"full"} variant={"unstyled"} color={"#8CB024"} fontSize={'20px'} lineHeight={'24px'} display={'inline-flex'}>
            <Text>Complete</Text><Text ml={'8px'}>✓</Text>
        </Button>
      )
    }
    else if(past === "notComplete"){
      return (
        <>
          {data.actionBy === user.uid ? (
            <Button
              w={"100%"}
              size={"sm"}
              bg={"yellow.200"}
              color={"yellow.500"}
              variant={"unstyled"}
              onClick={() => setreviewDialog(true)}
            >
              In review
            </Button>
          ) : (
            <Stack direction={"row"}>
              <Button h={"32px"} w={"full"} variant={"unstyled"}>
                Not complete
              </Button>
              <Button
                variant={"unstyled"}
                size={"sm"}
                w={"full"}
                bg={"secondary.200"}
                color={"secondary.700"}
                onClick={onOpenReport}
              >
                Report
              </Button>
            </Stack>
          )}
        </>
      );
    }
    else if(past === "inReview"){
      return (
        <>
        {data.actionBy === user.uid ? (
          <Button
            w={"100%"}
            size={"sm"}
            bg={"yellow.200"}
            color={"yellow.500"}
            variant={"unstyled"}
            onClick={() => setreviewDialog(true)}
          >
              In review
          </Button>
        ) : (
          <Stack direction={"row"}>
            <Button
              h={"32px"}
              size={"sm"}
              w={"full"}
              onClick={onOpenComplete}
            >
              Complete
            </Button>
            <Button
              size={"sm"}
              variant={"unstyled"}
              bg={'secondary.200'}
              color={'secondary.700'}
              w={"full"}
              onClick={onOpenReport}
            >
              Flag
            </Button>
          </Stack>
        )}
      </>
      )
    }
    else if (past === true){
      return (
        <Stack direction={"row"}>
          <Button
          w={"full"}
          h={"32px"}
          size={"sm"}
          onClick={onOpenComplete}
        >
          Mark complete
        </Button>
        <Button
          w={"full"}
          h={"32px"}
          size={"sm"}
          variant={"unstyled"}
          colorScheme={"gray"}
          onClick={onOpen}
        >
          Mark incomplete
        </Button>
      </Stack>
      )
    }
    else{
      return (
        <Button
        w={"100%"}
        bg={'orange.300'}
        size={'sm'}
        onClick={() => setflagDialog(true)}
      >
        Event Flagged
      </Button>
      )
    }
  }

  return (
    <>
      <Box
        w={"343px"}
        h={"473px"}
        borderRadius={"24px"}
        px={"24px"}
        py={"28px"}
        backgroundColor={"#F2F7D9"}
      >
        <>
          <Partner {...partnerprops} />
          <Divider borderColor={"white"} borderWidth={"2px"} my={'20px'}/>
          <Mockinfo {...mockinfo} />
          <HStack mt={'20px'}>
            <Time {...timeprops} />
            <Spacer/>
            <Box>
              {showRating ? (
                <>
                  <Box
                    cursor={"pointer"}
                    onClick={onOpenfeedback}
                    h={"37px"}
                    py={"8px"}
                    px={"14px"}
                    bg={"white"}
                    borderRadius={"8px"}
                    display={"inline-flex"}
                  >
                    <Text
                      fontSize={"16px"}
                      lineHeight={"21.12px"}
                      fontWeight={"700"}
                      color={"#72820D"}
                      as={"span"}
                      mr={"8px"}
                    >
                      {(
                        (staticBehavior + staticFeedback + staticPunctual) /
                        3
                      ).toFixed(1)}{" "}
                    </Text>
                    <StarIcon color={"#F6E05E"} w={"20px"} h={"20px"} />
                  </Box>
                </>
              ) : null}
            </Box>
          </HStack>
          <Divider borderColor={"white"} borderWidth={"2px"} my={"20px"}/>
          <PastButtons data={data} user={user} />
        </>
      </Box>
      {/* Incomplete Mock Modal */}
      <Modal onClose={onClose} isOpen={isOpen} isCentered size={"lg"}>
        <ModalOverlay />
        <ModalContent borderRadius={'16px'}>
          <ModalHeader>
            Report incomplete mock
            <Text
              fontSize={"14px"}
              lineHeight={"20px"}
              fontWeight={"400"}
              color={"gray.500"}
              mt={"12px"}
            >
              We are sorry to hear your mock interview could not happen. Help us
              in improving your experience on Pearmock by selecting one of the
              following reasons for your mock interview getting canceled.
            </Text>
          </ModalHeader>
          <ModalCloseButton mt={"8px"} />
          <ModalBody>
            <FormControl isInvalid={error}>
              <RadioGroup onChange={setValue} value={value}>
                <Stack direction="column">
                  <Radio
                    value="I could not show up for the mock interview"
                    size={"lg"}
                  >
                    <Text
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      fontWeight={"400"}
                      color={"gray.600"}
                    >
                      I could not show up for the mock interview
                    </Text>
                  </Radio>
                  <Radio
                    value="My partner did not show up for the mock interview"
                    size={"lg"}
                  >
                    <Text
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      fontWeight={"400"}
                      color={"gray.600"}
                    >
                      My partner did not show up for the mock interview
                    </Text>
                  </Radio>
                  <Radio
                    value="We had technical problems during the meeting"
                    size={"lg"}
                  >
                    <Text
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      fontWeight={"400"}
                      color={"gray.600"}
                    >
                      We had technical problems during the meeting
                    </Text>
                  </Radio>
                  <Radio
                    value="We started but the 90 minute meeting duration was not sufficient"
                    size={"lg"}
                  >
                    <Text
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      fontWeight={"400"}
                      color={"gray.600"}
                    >
                      We started but the 90 minute meeting duration was not
                      sufficient
                    </Text>
                  </Radio>
                  <Radio value="Other" size={"lg"}>
                    <Text
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      fontWeight={"400"}
                      color={"gray.600"}
                    >
                      Other
                    </Text>
                  </Radio>
                </Stack>
              </RadioGroup>
              {error ? (
                <FormErrorMessage>
                  Please select a reason for incomplete mock.
                </FormErrorMessage>
              ) : null}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              size={"sm"}
              variant={"outline"}
              colorScheme={"gray"}
              mr={"12px"}
            >
              Cancel
            </Button>
            <Button
              isLoading={loading}
              onClick={() => {
                if (value === null) {
                  seterror(true);
                } else {
                  setloading(true);
                  var obj;
                  if (data.creatorUid === user.uid) {
                    obj = {
                      actionBy: data.creatorUid,
                      past: "notComplete",
                      incompleteNote: value,
                    };
                  } else {
                    obj = {
                      actionBy: data.partnerUid,
                      past: "notComplete",
                      incompleteNote: value,
                    };
                  }
                  setDoc(doc(db, "events", data.eventId), obj, {
                    merge: true,
                  }).then(() => {
                    const sendemail = httpsCallable(functions, "sendemail");
                    sendemail({
                      to: `${partnerEmail}`,
                      subject: `Your Recent Pearmock Interview - Important Update`,
                      text: "Your Partner has marked the mock In Complete. You can review the reason and Report if you disagree with the reason",
                      html: `Hey there,
                      <br/>
                      We hope you're doing well. We're reaching out to let you know that your recent mock interview on Pearmock has been marked as incomplete.
                      <br/>
                      We understand that things don't always go as planned. But don't worry, we've got your back. Here's what you can do next:
                      <br/>
                      1. <b>Reach Out:</b> If possible, try reaching out to your partner to understand if there was a misunderstanding or a technical issue.
                      <br/>
                      2. <b>Reschedule:</b> If your partner is unable to complete the session, feel free to schedule a new mock interview with a different partner.
                      <br/>
                      Remember, every bump in the road is a chance to learn and grow. Keep practicing, keep improving, and keep rocking!
                      <br/>
                      If you have any questions or need further assistance, feel free to reply to this email. We're here to help!
                      <br/>
                      Best,
                      <br/>
                      Patrice
                      <br/>
                      Pearmock`,
                    });
                    onClose(true);
                  });
                }
              }}
              size={"sm"}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Complete Mock Modal*/}
      <Modal
        onClose={onCloseComplete}
        isOpen={isOpenComplete}
        isCentered
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent borderRadius={'16px'}>
          <ModalHeader>
            You are practicing hard. That’s great!
            <Text
              fontSize={"14px"}
              lineHeight={"20px"}
              fontWeight={"400"}
              color={"black"}
              mt={"12px"}
            >
              Glad to hear you completed your mock interview. Please rate your
              experience with your peer in order to mark this session as
              complete.
            </Text>
          </ModalHeader>
          <ModalCloseButton mt={"8px"} />
          <Box ml={"24px"} w={"464px"} bg={"gray.200"} h={"1px"} />
          <ModalBody>
            <Text
              fontSize={"18px"}
              lineHeight={"28px"}
              fontWeight={"600"}
              color={"gray.700"}
              mt={"12px"}
            >
              Rate your interview experience with your peer
            </Text>
            <FormControl isInvalid={completeError}>
              <HStack mt={"12px"}>
                <Tooltip
                  hasArrow
                  label="How meaningful, specific and actionable was the feedback provided by the interviewer?"
                  bg="black"
                  color="white"
                  textAlign={"center"}
                  p={"8px"}
                  w={"320px"}
                  borderRadius={"4px"}
                >
                  <Text
                    as={"u"}
                    fontSize={"16px"}
                    lineHeight={"24px"}
                    fontWeight={"500"}
                    mr={"37px"}
                  >
                    Feedback quality
                  </Text>
                </Tooltip>
                <Rating rating={feedback} setrating={setfeedback} />
              </HStack>
              <HStack mt={"12px"}>
                <Tooltip
                  hasArrow
                  label="How was your peer’s behavior during the mock interview session? Were you respectful?"
                  bg="black"
                  color="white"
                  textAlign={"center"}
                  p={"8px"}
                  w={"320px"}
                  borderRadius={"4px"}
                >
                  <Text
                    as={"u"}
                    fontSize={"16px"}
                    lineHeight={"24px"}
                    fontWeight={"500"}
                    mr={"102px"}
                  >
                    Behavior
                  </Text>
                </Tooltip>

                <Rating rating={behavior} setrating={setbehavior} />
              </HStack>
              <HStack mt={"12px"}>
                <Tooltip
                  hasArrow
                  label="Did your peer join the meeting on time?"
                  bg="black"
                  color="white"
                  textAlign={"center"}
                  p={"8px"}
                  w={"320px"}
                  borderRadius={"4px"}
                >
                  <Text
                    as={"u"}
                    fontSize={"16px"}
                    lineHeight={"24px"}
                    fontWeight={"500"}
                    mr={"84px"}
                  >
                    Punctuality
                  </Text>
                </Tooltip>

                <Rating rating={punctual} setrating={setpunctual} />
              </HStack>
              <Text
                fontSize={"12px"}
                lineHeight={"16px"}
                color={"blackAlpha.400"}
                mt={"32px"}
              >
                ⚠ This rating is visible to the interviewer.
              </Text>
              {completeError ? (
                <FormErrorMessage>
                  Please select a rating for the peer.
                </FormErrorMessage>
              ) : null}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                setcompleteError(false);
                setfeedback(null);
                setbehavior(null);
                setpunctual(null);
                onCloseComplete(true);
              }}
              size={"sm"}
              variant={"outline"}
              colorScheme={"gray"}
              mr={"12px"}
            >
              Cancel
            </Button>
            <Button
              isLoading={loading}
              onClick={() => {
                if (
                  feedback === null ||
                  behavior === null ||
                  punctual === null
                ) {
                  setcompleteError(true);
                } else {
                  setloading(true);
                  var obj;
                  var peer;
                  if (data.actionBy === undefined) {
                    peer = "peer1";
                    setcompletedBy(peer);
                  } else {
                    peer = "peer2";
                    setcompletedBy(peer);
                  }
                  const pastStatus = peer === "peer1" ? "inReview" : "Complete";
                  if (data.creatorUid === user.uid) {
                    obj = {
                      actionBy: data.creatorUid,
                      past: pastStatus,
                      partnerFeedback: feedback,
                      partnerBehavior: behavior,
                      partnerPunctual: punctual,
                    };
                  } else {
                    obj = {
                      actionBy: data.partnerUid,
                      past: pastStatus,
                      creatorFeedback: feedback,
                      creatorBehavior: behavior,
                      creatorPunctual: punctual,
                    };
                  }
                  setDoc(doc(db, "events", data.eventId), obj, {
                    merge: true,
                  }).then(() => {
                    const sendemail = httpsCallable(functions, "sendemail");
                    const html =
                      peer === "peer2"
                        ? `Hey there,
                        <br/>
                        Well done! 👏 You've just completed a mock interview on Pearmock. We're super proud of you for taking this step towards acing your case interviews.
                        <br/>
                        And guess what? You've earned <b>20 points</b> for this session! Keep up the good work and you'll be leveling up in no time.
                        <br/>
                        Remember, every interview is a learning opportunity. Keep creating, keep practicing, and keep growing. You're doing an amazing job!
                        <br/>
                        Ready for the next one? <a href="https://pearmock.com">Log in to your account</a> and schedule your next mock interview. Let's keep this momentum going!
                        <br/>
                        Keep rocking 🚀
                        <br/>
                        Best,
                        <br/>
                        Patrice`
                        :  `Hey there,
                        <br/>
                        Well done! 👏 Your partner have just marked Complete the mock interview on Pearmock. We're super proud of you for taking this case interviews.
                        <br/>
                        You can earn <b>20 points</b> for this session! after you mark the mock Complete from your end.
                        <br/>
                        Remember, every interview is a learning opportunity. Keep creating, keep practicing, and keep growing. You're doing an amazing job!
                        <br/>
                        Ready to gain points? <a href="https://pearmock.com">Log in to your account</a> and mark the mock interview Complete. Let's keep this momentum going!
                        <br/>
                        Keep rocking 🚀
                        <br/>
                        Best,
                        <br/>
                        Patrice`;
                    sendemail({
                      to: `${partnerEmail}`,
                      subject: "Your Recent Pearmock Interview - Important Update",
                      text: "Your Partner has marked the mock Complete. You can review and mark it complete or report",
                      html: html,
                    });
                  });
                  if (peer === "peer2") {
                    var peer1;
                    var peer2;
                    getDoc(doc(db, "users", data.creatorUid)).then(
                      (docSnap) => {
                        peer1 = docSnap.data();

                        getDoc(doc(db, "users", data.partnerUid)).then(
                          (docSnap) => {
                            peer2 = docSnap.data();
                            const peer1mocklevel = peer1.levelMock + 1*ppm;
                            const peer2mocklevel = peer2.levelMock + 1*ppm;
                            const peer1created = Object.keys(
                              peer1.createdMocks
                            );
                            const peer2created = Object.keys(
                              peer2.createdMocks
                            );
                            const peer1level = updateLevel(peer1mocklevel) < peer1.level ? peer1.level : updateLevel(peer1mocklevel);
                            const peer2level = updateLevel(peer2mocklevel) < peer2.level ? peer2.level : updateLevel(peer2mocklevel);
                            const peer1tag = getTagObject(peer1level);
                            const peer2tag = getTagObject(peer2level);
                            updateDoc(doc(db, "users", data.creatorUid), {
                              mocksCompleted: increment(1),
                              levelMock: increment(1*ppm),
                              level: peer1level,
                            })
                              .then(() => {
                                updateDoc(doc(db, "users", data.partnerUid), {
                                  mocksCompleted: increment(1),
                                  levelMock: increment(1*ppm),
                                  level: peer2level,
                                });
                              })
                              .then(() => {
                                if (peer1created.length !== 0) {
                                  peer1created.forEach((id) => {
                                    updateDoc(doc(db, "events", id), {
                                      creatorMocksCompleted: increment(1),
                                      creatorLevel: peer1level,
                                      ...peer1tag,
                                    });
                                  });
                                }
                                if (peer2created.length !== 0) {
                                  peer2created.forEach((id) => {
                                    updateDoc(doc(db, "events", id), {
                                      creatorMocksCompleted: increment(1),
                                      creatorLevel: peer2level,
                                      ...peer2tag,
                                    });
                                  });
                                }
                              })
                              .then(() => {
                                onCloseComplete(true);
                                onOpenReview(true);
                              });
                          }
                        );
                      }
                    );
                  } else {
                    onCloseComplete(true);
                    onOpenReview(true);
                  }
                  const eventproperties = {
                    event_id: data.eventId,
                    completed_by: peer
                  }
                  amplitude.track('mock marked complete',eventproperties)
                }
              }}
              size={"sm"}
            >
              Submit & Mark complete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Completed Mock Review Modal*/}
      <Modal
        onClose={onCloseReview}
        isOpen={isOpenReview}
        isCentered
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent borderRadius={'16px'}>
          <ModalHeader>
            <HStack spacing={"12px"}>
              <Circle size={7} bg={"green.500"} color={"white"}>
                <CheckIcon />
              </Circle>
              {completedBy !== "peer1" ? (
                <Text>Great job! Mock is complete.</Text>
              ) : (
                <Text>Mock in review. What’s next?</Text>
              )}
            </HStack>
          </ModalHeader>
          <ModalBody>
            {completedBy !== "peer1" ? (
              <Text
                fontSize={"14px"}
                lineHeight={"20px"}
                color={"gray.500"}
                fontWeight={"400"}
              >
                This mock session is now complete! But there’s still one more
                thing remaining. Reflecting on the feedback and the key
                takeaways is one of the most important parts of any learning
                process. Document your takeaways and revise them before the next
                interview!{" "}
              </Text>
            ) : (
              <>
                <Text
                  fontSize={"14px"}
                  lineHeight={"20px"}
                  color={"gray.500"}
                  fontWeight={"400"}
                  mb={"32px"}
                >
                  Great job! We’ve notified your peer about this mock session.
                  The session will be marked complete as soon as they confirm
                  it.
                </Text>
                <Text
                  fontSize={"14px"}
                  lineHeight={"20px"}
                  color={"gray.500"}
                  fontWeight={"400"}
                >
                  Reflecting on the feedback and the key takeaways is one of the
                  most important parts of any learning process. Document your
                  takeaways and revise them before the next interview!
                </Text>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onCloseReview}
              size={"sm"}
              variant={"outline"}
              colorScheme={"gray"}
              mr={"12px"}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                navigate(`${baseUrl}notes`);
              }}
              size={"sm"}
            >
              Take notes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Review Button click Alert Dialog */}
      <AlertDialog
        motionPreset="slideInBottom"
        onClose={() => {
          setreviewDialog(false);
        }}
        isOpen={reviewDialog}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Mock in review</AlertDialogHeader>
          <AlertDialogBody>
            <Text fontSize={"14px"} lineHeight={"20px"} color={"gray.500"}>
              We’ve notified your peer about this mock session. The session will
              be marked complete as soon as they confirm it.
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              variant={"ghost"}
              colorScheme={"black"}
              border={"1px solid #E2E8F0"}
              onClick={() => {
                setreviewDialog(false);
              }}
            >
              Close
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      {/* Report Mock Modal*/}
      <Modal
        onClose={onCloseReport}
        isOpen={isOpenReport}
        isCentered
        size={"lg"}
      >
        <ModalOverlay />
        <ModalContent borderRadius={'16px'}>
          <ModalHeader>
            <WarningTwoIcon
              color={"orange.400"}
              w={"30px"}
              h={"30px"}
              mr={"12px"}
              mt={"-4px"}
            />
            Report session
          </ModalHeader>
          <ModalBody>
            {data.past === "inReview" || data.past === "FlaggedComplete" ? (
              <Text
                fontSize={"14px"}
                lineHeight={"20px"}
                color={"gray.500"}
                fontWeight={"400"}
              >
                Your peer has marked this lesson as{" "}
                <Text fontWeight={"700"} as={"span"}>
                  complete.
                </Text>{" "}
                If you think this session was not completed or your partner did
                not show up, please report this session. Our team will reach out
                to you and resolve this issue.
              </Text>
            ) : null}
            {data.past === "notComplete" ||
            data.past === "FlaggedNotComplete" ? (
              <>
                <Text
                  fontSize={"14px"}
                  lineHeight={"20px"}
                  color={"gray.500"}
                  fontWeight={"400"}
                  mb={"32px"}
                >
                  Your peer has marked this lesson as{" "}
                  <Text fontWeight={"700"} as={"span"}>
                    incomplete.
                  </Text>
                </Text>
                <Text
                  fontSize={"14px"}
                  lineHeight={"20px"}
                  color={"gray.500"}
                  fontWeight={"400"}
                  mb={"32px"}
                >
                  <Text fontWeight={"700"} as={"span"}>
                    Reason:
                  </Text>{" "}
                  "{data.incompleteNote}"
                </Text>
                <Text
                  fontSize={"14px"}
                  lineHeight={"20px"}
                  color={"gray.500"}
                  fontWeight={"400"}
                >
                  If you think this session was not completed due to some other
                  reason, please report this mock interview. Our team will reach
                  out to you and resolve this issue.
                </Text>
              </>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={loading}
              onClick={() => {
                if (data.past === "inReview") {
                  updateDoc(doc(db, "events", data.eventId), {
                    past: "FlaggedComplete",
                  }).then(() => {
                    const sendemail = httpsCallable(functions, "sendemail");
                    sendemail({
                      to: `pearmockupdate@gmail.com,${data.partnerEmail},${data.creatorEmail}`,
                      subject: "Your Recent Pearmock Interview - Important Update",
                      text: "Event Flagged",
                      html: `Hey there,
                      <br/>
                      Hope you're doing well! We're reaching out to let you know that your recent mock interview on Pearmock has been flagged by your partner.
                      <br/>
                      We understand that sometimes things might not go as planned. Don't worry, we've got your back.
                      <br/>
                      To understand more about this, please <a href="https://pearmock.com">log in to your account</a> and visit your profile. You'll find all the details there.
                      <br/>
                      Remember, every experience is a learning opportunity. Keep creating, keep practicing, and keep growing. You're doing great!
                      <br/>
                      Take care,
                      <br/>
                      Patrice`,
                    });
                    onCloseReport(true);
                  });
                }
                if (data.past === "notComplete") {
                  updateDoc(doc(db, "events", data.eventId), {
                    past: "FlaggedNotComplete",
                  }).then(() => {
                    const sendemail = httpsCallable(functions, "sendemail");
                    sendemail({
                      to: `pearmockupdate@gmail.com,${data.partnerEmail},${data.creatorEmail}`,
                      subject: "Your Recent Pearmock Interview - Important Update",
                      text: "Event Flagged",
                      html: `Hey there,
                      <br/>
                      Hope you're doing well! We're reaching out to let you know that your recent mock interview on Pearmock has been flagged by your partner.
                      <br/>
                      We understand that sometimes things might not go as planned. Don't worry, we've got your back.
                      <br/>
                      To understand more about this, please <a href="https://pearmock.com">log in to your account</a> and visit your profile. You'll find all the details there.
                      <br/>
                      Remember, every experience is a learning opportunity. Keep creating, keep practicing, and keep growing. You're doing great!
                      <br/>
                      Take care,
                      <br/>
                      Patrice`,
                    });
                    onCloseReport(true);
                  });
                }
              }}
              size={"sm"}
              variant={"outline"}
              colorScheme={"gray"}
              mr={"12px"}
            >
              Report
            </Button>
            <Button onClick={onCloseReport} size={"sm"}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Event Flagged Alert Dialog Box*/}
      <AlertDialog
        motionPreset="slideInBottom"
        size={"lg"}
        onClose={() => {
          setflagDialog(false);
        }}
        isOpen={flagDialog}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            <WarningTwoIcon
              color={"orange.400"}
              w={"30px"}
              h={"30px"}
              mr={"12px"}
              mt={"-4px"}
            />
            Event Flagged
          </AlertDialogHeader>
          <AlertDialogBody mt={"-10px"}>
            <Text fontSize={"14px"} lineHeight={"20px"} color={"gray.500"}>
              You and your peer have submitted conflicting statuses for this
              mock interview. Therefore, this session is in review. We will
              resolve this session and update the status. If you need further
              assistance, please email to support@pearmock.com. We are always
              here to serve you.
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              variant={"ghost"}
              colorScheme={"black"}
              border={"1px solid #E2E8F0"}
              onClick={() => {
                setflagDialog(false);
              }}
            >
              Close
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      {/* Feedback Modal */}
      <Modal
        isOpen={isOpenfeedback}
        onClose={onClosefeedback}
        size={"xs"}
        isCentered
        blockScrollOnMount={true}
      >
        <ModalOverlay />
        <ModalContent pt={"16px"} borderRadius={'16px'} bg={"#F87777"} color={'white'}>
          <ModalBody>
            <Text
              fontSize={"18px"}
              lineHeight={"28px"}
              fontWeight={"600"}
              mb={"12px"}
            >
              Peer Rating
            </Text>
            <Text
              fontSize={"16px"}
              lineHeight={"24px"}
              fontWeight={"500"}
            >
              Your peer has rated their overall interviewing experience with
              you.
            </Text>
            <HStack mt={"12px"}>
              <Tooltip
                hasArrow
                label="How meaningful, specific and actionable was the feedback provided by the interviewer?"
                bg="black"
                color="white"
                textAlign={"center"}
                p={"8px"}
                w={"320px"}
                borderRadius={"4px"}
              >
                <Text
                  as={"u"}
                  fontSize={"16px"}
                  lineHeight={"24px"}
                  fontWeight={"500"}
                  mr={"40px"}
                >
                  Feedback quality
                </Text>
              </Tooltip>
              <Text
                fontSize={"16px"}
                lineHeight={"24px"}
                fontWeight={"500"}
                as={"span"}
              >
                {staticFeedback}.0 <StarIcon color={"yellow.200"} mt={"-3px"} />
              </Text>
            </HStack>
            <HStack mt={"12px"}>
              <Tooltip
                hasArrow
                label="How was your peer’s behavior during the mock interview session? Were you respectful?"
                bg="black"
                color="white"
                textAlign={"center"}
                p={"8px"}
                w={"320px"}
                borderRadius={"4px"}
              >
                <Text
                  as={"u"}
                  fontSize={"16px"}
                  lineHeight={"24px"}
                  fontWeight={"500"}
                  mr={"102px"}
                >
                  Behavior
                </Text>
              </Tooltip>
              <Text
                fontSize={"16px"}
                lineHeight={"24px"}
                fontWeight={"500"}
                as={"span"}
              >
                {staticBehavior}.0 <StarIcon color={"yellow.200"} mt={"-3px"} />
              </Text>
            </HStack>
            <HStack mt={"12px"}>
              <Tooltip
                hasArrow
                label="Did your peer join the meeting on time?"
                bg="black"
                color="white"
                textAlign={"center"}
                p={"8px"}
                w={"320px"}
                borderRadius={"4px"}
              >
                <Text
                  as={"u"}
                  fontSize={"16px"}
                  lineHeight={"24px"}
                  fontWeight={"500"}
                  mr={"84px"}
                >
                  Punctuality
                </Text>
              </Tooltip>
              <Text
                fontSize={"16px"}
                lineHeight={"24px"}
                fontWeight={"500"}
                as={"span"}
              >
                {staticPunctual}.0 <StarIcon color={"yellow.200"} mt={"-3px"} />
              </Text>
            </HStack>
          </ModalBody>

          <ModalFooter>
            <Button
              size={"sm"}
              onClick={onClosefeedback}
              colorScheme={"gray"}
              color={'black'}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Pastcard;

import React from 'react'

export default function level0Svg() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="78" height="100" viewBox="0 0 78 100" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M38.9966 12.9688C60.2594 12.9688 77.4966 30.4002 77.4966 51.9031C77.4966 73.4059 60.2594 90.8376 38.9966 90.8376C17.7337 90.8376 0.496582 73.4061 0.496582 51.9031C0.496582 30.4001 17.7332 12.9688 38.9966 12.9688Z" fill="#C03636" />
            <path fillRule="evenodd" clipRule="evenodd" d="M39.137 9.00391C60.3222 9.00391 77.4962 26.3689 77.4962 47.7896C77.4962 69.2102 60.3222 86.5752 39.137 86.5752C17.9518 86.5752 0.777832 69.2102 0.777832 47.7896C0.777832 26.3689 17.9518 9.00391 39.137 9.00391Z" fill="#E05F5F" />
            <path fillRule="evenodd" clipRule="evenodd" d="M39.3928 17.7852C55.9232 17.7852 69.3237 31.2797 69.3237 47.926C69.3237 64.5723 55.9237 78.067 39.3928 78.067C22.862 78.067 9.46216 64.5718 9.46216 47.9253C9.46216 31.2788 22.8627 17.7852 39.3928 17.7852Z" fill="#D04545" />
            <path fillRule="evenodd" clipRule="evenodd" d="M40.2779 63.9469C40.2779 63.9469 39.0199 53.2817 49.4249 52.505C56.1819 52.0009 58.6681 48.4634 58.4831 51.0014C58.2981 53.5394 52.4526 70.6524 40.2779 63.9469Z" fill="#C02E2E" />
            <path fillRule="evenodd" clipRule="evenodd" d="M38.3914 63.7631C38.3914 63.7631 39.6607 52.9846 29.158 52.1991C22.337 51.6891 19.8272 48.1139 20.014 50.6791C20.2009 53.2443 26.102 70.5393 38.3914 63.7631Z" fill="#C02E2E" />
            <path fillRule="evenodd" clipRule="evenodd" d="M39.0028 53.4985C32.9936 53.3318 23.3171 42.8472 38.2661 27C55.4138 41.8378 45.0118 53.6657 39.0028 53.4985Z" fill="#F57474" />
            <path fillRule="evenodd" clipRule="evenodd" d="M37.2269 52.4138C34.1006 49.6908 33.5436 39.8665 48.4503 37.9805C51.0768 53.3985 40.3534 55.137 37.2269 52.4138Z" fill="#FC6666" />
            <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M39.1371 9.00391C60.3222 9.00391 77.4962 26.3689 77.4962 47.7896C77.5077 52.9393 76.496 58.0399 74.5199 62.7954C72.5438 67.5508 69.6427 71.8664 65.9851 75.4914C12.2929 20.4414 65.9976 75.4029 12.1467 20.2294C15.6826 16.6703 19.8878 13.8461 24.5201 11.9195C29.1524 9.99287 34.1201 9.00199 39.1371 9.00391Z" fill="#C3C340" />
        </svg>
    )
}

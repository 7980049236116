import React from 'react'

export default function OpportunitesSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="160" height="181" viewBox="0 0 160 181" fill="none">
  <g clip-path="url(#clip0_5644_2068)">
    <path d="M102.865 133.979C103.224 134.171 103.633 134.247 104.037 134.199C104.442 134.15 104.821 133.979 105.125 133.709C105.428 133.438 105.641 133.081 105.734 132.686C105.827 132.291 105.796 131.877 105.645 131.5C105.685 131.3 105.714 131.156 105.755 130.955C106.531 130.233 107.507 129.758 108.556 129.592C109.606 129.425 110.681 129.575 111.645 130.021C112.608 130.468 113.416 131.191 113.964 132.098C114.513 133.005 114.777 134.055 114.723 135.113C114.477 141.077 114.772 147.513 110.794 152.322C128.95 137.536 137.229 112.349 131.684 89.6946C128.963 85.9166 127.694 80.8976 128.914 76.3494C130.445 77.8334 133.43 77.4347 132.974 74.8658C132.729 73.8505 132.476 72.8383 132.231 71.823C142.689 67.7565 145.419 83.252 133.032 89.561C134.037 93.868 134.587 98.2678 134.674 102.689C136.072 98.7024 138.4 95.1038 141.466 92.189C144.435 89.5253 148.36 87.959 152.012 86.1718C156.456 83.9914 161.388 88.3141 159.638 92.9287C156.68 92.5515 154.587 96.0752 157.271 97.9687C152.557 105.86 143.516 110.876 134.331 110.955C133.402 119.959 130.558 128.663 125.989 136.485C131.856 127.594 143.212 130.154 152.186 129.775C152.781 129.7 153.386 129.799 153.925 130.061C154.465 130.323 154.916 130.735 155.225 131.248C155.533 131.761 155.685 132.353 155.662 132.951C155.639 133.548 155.442 134.126 155.095 134.614C153.914 134.545 152.73 134.593 151.559 134.757C151.008 134.839 150.496 135.088 150.093 135.471C149.689 135.853 149.415 136.351 149.307 136.895C149.198 137.44 149.262 138.004 149.488 138.511C149.714 139.018 150.092 139.443 150.57 139.727C142.698 146.919 130.5 148.465 120.926 143.876C118.095 147.429 114.902 150.68 111.397 153.575L97.1636 146.814C97.1879 146.632 97.2198 146.447 97.2493 146.268C98.5273 146.976 99.8875 147.526 101.3 147.905C99.7812 142.136 98.0891 138.757 102.864 133.979H102.865Z" fill="#F2F2F2"/>
    <path d="M77.714 120.334C77.4538 120.646 77.1036 120.871 76.7109 120.978C76.3182 121.085 75.902 121.07 75.5186 120.933C75.1352 120.797 74.8031 120.546 74.5675 120.215C74.3318 119.884 74.2039 119.489 74.201 119.083C74.0877 118.913 74.0063 118.791 73.8929 118.62C72.9 118.243 71.8167 118.17 70.7818 118.41C69.7469 118.65 68.8076 119.193 68.0844 119.969C67.3611 120.745 66.8868 121.718 66.7223 122.765C66.5577 123.811 66.7105 124.882 67.1609 125.841C69.6462 131.273 71.8085 137.345 77.3126 140.303C54.9016 133.434 37.7012 113.219 34.2616 90.1543C35.3513 85.6326 34.627 80.5073 31.7756 76.7537C30.9193 78.7036 28.0043 79.4563 27.4544 76.9057C27.2967 75.8735 27.1481 74.8408 26.9904 73.8086C15.7665 73.9734 19.103 89.3506 32.963 90.5372C33.6625 94.9037 34.8181 99.1854 36.4111 103.312C33.6072 100.146 30.089 97.6886 26.146 96.1417C22.3885 94.7907 18.1602 94.8157 14.1016 94.5334C9.16088 94.1845 6.22952 100.042 9.59653 103.658C12.1934 102.196 15.4656 104.673 13.6962 107.436C21.0495 112.972 31.3205 114.218 39.8574 110.839C44.1257 118.829 50.0539 125.821 57.2467 131.348C48.4469 125.318 38.8992 131.958 30.4447 134.981C29.8649 135.135 29.3428 135.454 28.9421 135.9C28.5414 136.345 28.2793 136.897 28.1879 137.488C28.0964 138.079 28.1796 138.684 28.4272 139.228C28.6748 139.773 29.0761 140.234 29.5823 140.556C30.6495 140.048 31.7647 139.647 32.9114 139.359C33.4523 139.228 34.0209 139.266 34.5391 139.469C35.0573 139.672 35.4999 140.029 35.8062 140.493C36.1124 140.956 36.2674 141.503 36.2498 142.057C36.2321 142.612 36.0428 143.147 35.7078 143.591C45.7207 147.292 57.6032 144.139 64.7327 136.289C68.6991 138.516 72.887 140.326 77.2288 141.69L87.8516 130.077C87.7602 129.918 87.6608 129.759 87.5656 129.604C86.6501 130.74 85.5985 131.761 84.4341 132.643C83.657 126.729 83.9451 122.964 77.7144 120.334L77.714 120.334Z" fill="#F2F2F2"/>
    <path d="M80.4479 71.2658C80.853 71.225 81.2357 71.0611 81.5442 70.7963C81.8527 70.5315 82.072 70.1786 82.1725 69.7854C82.273 69.3922 82.2498 68.9777 82.1061 68.5981C81.9623 68.2185 81.7049 67.8921 81.3688 67.6632C81.2901 67.4743 81.2337 67.3386 81.1551 67.1497C81.3957 66.1182 81.9399 65.1818 82.718 64.4606C83.4961 63.7393 84.4726 63.2661 85.5223 63.1015C86.572 62.9369 87.6471 63.0885 88.6098 63.5368C89.5725 63.9851 90.379 64.7097 90.9257 65.6178C94.0586 70.7058 97.9048 75.8844 97.2926 82.0865C104.093 59.7243 96.8732 34.2151 79.5962 18.4898C75.224 16.8651 71.3625 13.4039 69.8308 8.95031C71.932 9.3315 74.1866 7.34224 72.3707 5.46306C71.5996 4.7562 70.8231 4.05661 70.052 3.34971C76.4578 -5.83658 87.3936 5.50993 80.6403 17.6301C83.8841 20.6471 86.8025 23.9938 89.348 27.6156C88.2785 23.5294 88.1979 19.2484 89.1125 15.125C90.0867 11.2643 92.469 7.78285 94.5007 4.27015C96.9698 -0.00900957 103.482 0.839097 104.612 5.64206C101.945 6.9726 102.179 11.0608 105.466 11.1412C105.967 20.3117 101.269 29.4986 93.6881 34.6684C97.9545 42.6592 100.463 51.4645 101.046 60.4975C100.942 49.8556 111.801 45.6713 119.039 40.3705C119.492 39.9775 120.049 39.7243 120.643 39.6417C121.238 39.559 121.843 39.6506 122.386 39.9051C122.93 40.1597 123.386 40.5662 123.702 41.0752C124.017 41.5841 124.177 42.1735 124.161 42.7714C123.143 43.3701 122.186 44.0681 121.306 44.8552C120.894 45.2287 120.609 45.7201 120.488 46.2618C120.367 46.8035 120.418 47.3692 120.633 47.8812C120.847 48.3932 121.216 48.8265 121.687 49.1217C122.159 49.4169 122.71 49.5595 123.266 49.5301C120.755 59.8747 111.493 67.9361 100.978 69.4459C100.616 73.9683 99.7838 78.4411 98.4941 82.7921L82.8955 85.0877C82.8139 84.9232 82.7372 84.7523 82.6613 84.587C84.1185 84.465 85.5553 84.1656 86.9395 83.6955C82.4516 79.7504 79.1567 77.8856 80.4475 71.2661L80.4479 71.2658Z" fill="#F2F2F2"/>
    <path d="M96.1365 66.2791C89.035 66.2791 85.1831 65.4142 83.8379 63.5682C82.6509 61.9394 83.4467 59.7902 84.3682 57.3019C85.0304 55.5133 85.7809 53.4861 85.7809 51.4798C85.7809 41.7214 90.746 36.7734 100.538 36.7734C105.41 36.7734 110.641 39.3526 114.531 43.6728C118.067 47.6003 119.829 52.3009 119.365 56.5691C118.983 60.0695 117.349 62.3715 114.219 63.814L114.036 63.8983L113.984 63.7042C113.714 62.6995 113.257 61.7544 112.636 60.9182C112.576 62.2309 112.244 63.5169 111.661 64.6956L111.628 64.7628L111.555 64.7835C108.014 65.7888 103.81 66.0612 100.544 66.1859C98.9546 66.2477 97.4853 66.2787 96.1365 66.2791Z" fill="#151E2B"/>
    <path d="M98.0719 79.8757C97.7997 79.0783 97.3406 78.3572 96.7327 77.772C96.1249 77.1869 95.3858 76.7547 94.5767 76.5112C93.7677 76.2678 92.912 76.2201 92.0808 76.3722C91.2496 76.5243 90.4667 76.8717 89.7972 77.3857L66.4384 91.3034L59.129 73.5297C59.7465 72.6269 60.0612 71.5525 60.0281 70.4605C59.995 69.3685 59.6156 68.315 58.9445 67.4511C58.2734 66.5872 57.3451 65.9573 56.2925 65.6517C55.24 65.3461 54.1174 65.3806 53.0858 65.7502C52.0541 66.1197 51.1664 66.8054 50.55 67.7088C49.9335 68.6123 49.62 69.6871 49.6544 70.779C49.6888 71.871 50.0693 72.9241 50.7414 73.7872C51.4134 74.6504 52.3425 75.2792 53.3954 75.5836C53.8459 80.709 56.3496 103.617 65.0596 102.785C72.3807 102.085 90.562 90.0177 96.2091 85.8338C97.1125 85.1651 97.7797 84.2279 98.1144 83.1572C98.4492 82.0864 98.4343 80.9374 98.0719 79.8757Z" fill="#FFD0B6"/>
    <path d="M115.675 180.965H95.344L91.9347 164.166L90.8288 180.965H71.9434C71.9506 167.772 72.6402 154.589 74.0093 141.467L74.2434 140.673L74.6132 139.402L74.8472 138.604L78.7553 125.23L79.064 124.168H102.597C103.951 125.436 105.2 126.81 106.332 128.279C106.715 128.766 107.116 129.293 107.526 129.861C107.811 130.263 108.106 130.686 108.401 131.129C111.024 135.019 112.984 139.314 114.202 143.841V143.844C114.409 144.618 114.592 145.41 114.752 146.221V146.224C114.894 146.944 115.02 147.674 115.118 148.422C115.193 148.966 115.257 149.517 115.305 150.075C115.588 153.165 115.489 156.279 115.01 159.345L115.675 180.965Z" fill="#151E2B"/>
    <path d="M7.88179 27.3573C9.20211 26.848 9.98422 25.693 9.62867 24.7777C9.27313 23.8624 7.91457 23.5333 6.59425 24.0426C5.27394 24.5519 4.49184 25.7069 4.84738 26.6222C5.20293 27.5375 6.56147 27.8667 7.88179 27.3573Z" fill="#E6E6E6"/>
    <path d="M24.5058 25.2323C25.8261 24.723 26.6082 23.568 26.2527 22.6527C25.8972 21.7374 24.5386 21.4083 23.2183 21.9176C21.898 22.4269 21.1159 23.5819 21.4714 24.4972C21.8269 25.4125 23.1855 25.7417 24.5058 25.2323Z" fill="#0A4A3F"/>
    <path d="M6.44015 41.494C7.76047 40.9847 8.54258 39.8298 8.18703 38.9144C7.83149 37.9991 6.47293 37.67 5.15261 38.1793C3.8323 38.6887 3.05019 39.8436 3.40573 40.7589C3.76127 41.6743 5.11983 42.0034 6.44015 41.494Z" fill="#FF6584"/>
    <path d="M1.63092 47.7274C2.23664 47.5845 2.5606 46.7653 2.35451 45.8975C2.14842 45.0298 1.49031 44.4422 0.88459 44.585C0.278872 44.7279 -0.0450868 45.5472 0.161005 46.4149C0.367098 47.2827 1.0252 47.8703 1.63092 47.7274Z" fill="#E6E6E6"/>
    <path d="M1.53204 58.2821C2.13776 58.1392 2.46172 57.32 2.25563 56.4522C2.04953 55.5845 1.39143 54.9969 0.785714 55.1397C0.179995 55.2826 -0.143964 56.1019 0.0621284 56.9696C0.268221 57.8374 0.926322 58.425 1.53204 58.2821Z" fill="#0A4A3F"/>
    <path d="M16.1224 36.4188C16.7281 36.2759 17.052 35.4567 16.846 34.5889C16.6399 33.7212 15.9818 33.1336 15.376 33.2765C14.7703 33.4193 14.4464 34.2386 14.6525 35.1063C14.8586 35.9741 15.5167 36.5617 16.1224 36.4188Z" fill="#E6E6E6"/>
    <path d="M7.367 34.2977C7.97272 34.1548 8.29668 33.3356 8.09059 32.4678C7.88449 31.6001 7.22639 31.0125 6.62067 31.1554C6.01496 31.2982 5.691 32.1175 5.89709 32.9852C6.10318 33.853 6.76128 34.4406 7.367 34.2977Z" fill="#FF6584"/>
    <path d="M16.647 24.579C17.2527 24.4361 17.5767 23.6168 17.3706 22.7491C17.1645 21.8813 16.5064 21.2937 15.9007 21.4366C15.295 21.5795 14.971 22.3988 15.1771 23.2665C15.3832 24.1342 16.0413 24.7218 16.647 24.579Z" fill="#0A4A3F"/>
    <path d="M20.9629 97.6382C21.6013 96.8908 21.2448 95.5435 20.1667 94.629C19.0886 93.7144 17.6971 93.579 17.0587 94.3264C16.4203 95.0738 16.7768 96.4211 17.8549 97.3356C18.933 98.2502 20.3245 98.3856 20.9629 97.6382Z" fill="#E6E6E6"/>
    <path d="M11.1721 84.0796C11.8105 83.3322 11.4541 81.9849 10.376 81.0704C9.29786 80.1558 7.90636 80.0204 7.26797 80.7678C6.62958 81.5152 6.98604 82.8625 8.06414 83.777C9.14225 84.6916 10.5337 84.827 11.1721 84.0796Z" fill="#0A4A3F"/>
    <path d="M34.1214 92.1695C34.7597 91.4221 34.4033 90.0748 33.3252 89.1602C32.2471 88.2457 30.8556 88.1102 30.2172 88.8576C29.5788 89.605 29.9353 90.9523 31.0134 91.8669C32.0915 92.7814 33.483 92.9169 34.1214 92.1695Z" fill="#FF6584"/>
    <path d="M40.6584 92.3195C41.326 91.7258 41.5311 90.8692 41.1166 90.4064C40.7022 89.9437 39.825 90.0498 39.1574 90.6436C38.4899 91.2374 38.2847 92.0939 38.6992 92.5567C39.1137 93.0195 39.9909 92.9133 40.6584 92.3195Z" fill="#E6E6E6"/>
    <path d="M50.0197 87.382C50.6873 86.7883 50.8925 85.9317 50.478 85.4689C50.0635 85.0061 49.1863 85.1123 48.5188 85.7061C47.8512 86.2999 47.6461 87.1564 48.0605 87.6192C48.475 88.082 49.3522 87.9758 50.0197 87.382Z" fill="#0A4A3F"/>
    <path d="M23.7805 85.0031C24.448 84.4093 24.6532 83.5528 24.2387 83.09C23.8242 82.6272 22.9471 82.7334 22.2795 83.3272C21.612 83.921 21.4068 84.7775 21.8213 85.2403C22.2358 85.7031 23.1129 85.5969 23.7805 85.0031Z" fill="#E6E6E6"/>
    <path d="M26.0798 93.6867C26.7474 93.0929 26.9525 92.2364 26.538 91.7736C26.1235 91.3108 25.2464 91.417 24.5788 92.0108C23.9113 92.6046 23.7061 93.4611 24.1206 93.9239C24.5351 94.3867 25.4123 94.2805 26.0798 93.6867Z" fill="#FF6584"/>
    <path d="M13.0844 90.1828C13.752 89.589 13.9571 88.7325 13.5427 88.2697C13.1282 87.8069 12.251 87.9131 11.5835 88.5069C10.9159 89.1007 10.7108 89.9572 11.1252 90.42C11.5397 90.8828 12.4169 90.7766 13.0844 90.1828Z" fill="#0A4A3F"/>
    <path d="M86.7367 10.6913C87.6205 10.2581 87.8304 8.8806 87.2056 7.61449C86.5808 6.34838 85.3578 5.67315 84.474 6.10632C83.5902 6.53948 83.3803 7.91701 84.0051 9.18311C84.6299 10.4492 85.8529 11.1245 86.7367 10.6913Z" fill="#E6E6E6"/>
    <path d="M90.3175 27.0077C91.2013 26.5745 91.4112 25.197 90.7864 23.9309C90.1616 22.6648 88.9386 21.9896 88.0548 22.4227C87.171 22.8559 86.9611 24.2334 87.5859 25.4995C88.2107 26.7656 89.4337 27.4409 90.3175 27.0077Z" fill="#0A4A3F"/>
    <path d="M72.4821 10.496C73.3659 10.0628 73.5758 8.68529 72.951 7.41918C72.3262 6.15308 71.1032 5.47783 70.2194 5.911C69.3356 6.34416 69.1257 7.7217 69.7505 8.98781C70.3753 10.2539 71.5983 10.9291 72.4821 10.496Z" fill="#FF6584"/>
    <path d="M64.9894 5.31944C65.8387 5.03881 66.3684 4.33401 66.1725 3.74521C65.9766 3.1564 65.1293 2.90657 64.28 3.18719C63.4307 3.46782 62.901 4.17264 63.0969 4.76144C63.2928 5.35024 64.1401 5.60007 64.9894 5.31944Z" fill="#E6E6E6"/>
    <path d="M54.4296 6.14757C55.2789 5.86694 55.8086 5.16213 55.6127 4.57333C55.4168 3.98452 54.5695 3.7347 53.7202 4.01532C52.8709 4.29595 52.3412 5.00076 52.5371 5.58956C52.733 6.17836 53.5802 6.42819 54.4296 6.14757Z" fill="#0A4A3F"/>
    <path d="M77.5621 18.714C78.4115 18.4333 78.9412 17.7285 78.7453 17.1397C78.5494 16.5509 77.7021 16.3011 76.8528 16.5817C76.0034 16.8624 75.4737 17.5672 75.6696 18.156C75.8655 18.7448 76.7128 18.9946 77.5621 18.714Z" fill="#E6E6E6"/>
    <path d="M78.9127 9.83506C79.762 9.55443 80.2917 8.84962 80.0959 8.26082C79.9 7.67202 79.0527 7.42219 78.2033 7.70282C77.354 7.98344 76.8243 8.68826 77.0202 9.27706C77.2161 9.86586 78.0634 10.1157 78.9127 9.83506Z" fill="#FF6584"/>
    <path d="M89.4418 18.1983C90.2911 17.9177 90.8208 17.2129 90.6249 16.6241C90.429 16.0353 89.5817 15.7855 88.7324 16.0661C87.8831 16.3467 87.3534 17.0515 87.5493 17.6403C87.7452 18.2291 88.5925 18.479 89.4418 18.1983Z" fill="#0A4A3F"/>
    <path d="M92.702 69.0008L103.902 68.4258L109.104 76.6178C109.104 76.6178 118.893 78.2486 118.214 89.7433C117.536 101.238 106.305 111.324 106.305 111.324L106.785 117.31L105.951 119.685L106.058 121.018L107.196 122.43L107.313 123.89L106.305 126.2L107.675 128.412L78.8625 125.63L77.8089 121.805L79.3458 119.751L78.4873 117.072L79.778 114.495L80.451 106.31C80.451 106.31 73.0881 95.2859 81.5405 86.9829L84.9329 81.2356L88.7287 76.6204L92.702 69.0008Z" fill="#C0D921"/>
    <path d="M98.2699 66.686C104.824 66.686 110.138 61.3907 110.138 54.8586C110.138 48.3265 104.824 43.0312 98.2699 43.0312C91.7153 43.0312 86.4019 48.3265 86.4019 54.8586C86.4019 61.3907 91.7153 66.686 98.2699 66.686Z" fill="#FFD0B6"/>
    <path d="M96.6807 54.1405C95.6581 54.1405 94.6359 54.1118 93.6143 54.0544L93.4318 54.0441L93.4558 53.8635C93.6063 52.7346 93.5216 51.5869 93.207 50.492C92.6728 51.7387 91.8779 52.8574 90.8754 53.7737L90.8188 53.8254L90.7426 53.8171C87.9681 53.5147 85.2189 53.015 82.5159 52.3219L82.4123 52.2955L82.3917 52.1912C82.1898 51.2032 82.0853 50.1979 82.0796 49.1897C82.0796 46.0402 83.2253 43.3894 85.306 41.7256C87.5705 39.9146 90.8168 39.3576 94.4499 40.1564H106.067C107.344 40.1578 108.569 40.6641 109.472 41.5641C110.375 42.4641 110.883 43.6844 110.884 44.9572V52.2887L110.758 52.3217C106.163 53.5252 101.432 54.1364 96.6807 54.1405Z" fill="#151E2B"/>
    <path d="M15.7001 40.9867C15.1801 41.1763 14.7567 41.5637 14.5229 42.0639C14.2891 42.5642 14.264 43.1364 14.453 43.6551L26.9856 77.9256C27.1758 78.4438 27.5645 78.8658 28.0665 79.0988C28.5684 79.3318 29.1426 79.3569 29.6631 79.1685L78.3042 61.5023C78.8243 61.3127 79.2476 60.9253 79.4814 60.4251C79.7153 59.9248 79.7404 59.3526 79.5514 58.8339L67.0188 24.5634C66.8286 24.0452 66.4399 23.6232 65.9379 23.3902C65.4359 23.1572 64.8617 23.1321 64.3412 23.3205L15.7001 40.9867Z" fill="#CCCCCC"/>
    <path d="M60.201 66.1842L30.4276 76.9977C29.8674 77.2005 29.2494 77.1735 28.7092 76.9227C28.1689 76.6719 27.7505 76.2178 27.5459 75.66L16.1789 44.577C15.9754 44.0187 16.0025 43.4028 16.2541 42.8644C16.5058 42.326 16.9614 41.909 17.5212 41.705L63.4137 25.0371C63.9739 24.8343 64.5919 24.8613 65.1322 25.1121C65.6724 25.3629 66.0908 25.817 66.2955 26.3748L71.7879 41.3938C73.544 46.2128 73.3103 51.5292 71.1381 56.1767C68.9659 60.8242 65.0325 64.4232 60.201 66.1842Z" fill="white"/>
    <path d="M38.7773 43.7298L31.7022 46.2995C31.6134 46.3317 31.5192 46.3462 31.4249 46.3422C31.3305 46.3381 31.2379 46.3155 31.1523 46.2758C31.0667 46.2361 30.9898 46.1799 30.9259 46.1106C30.8621 46.0412 30.8126 45.96 30.7803 45.8716C30.748 45.7831 30.7334 45.6892 30.7375 45.5952C30.7416 45.5012 30.7642 45.4089 30.8041 45.3236C30.8439 45.2383 30.9003 45.1616 30.9699 45.098C31.0395 45.0344 31.121 44.9851 31.2097 44.9528L38.2849 42.3832C38.464 42.3181 38.6618 42.3266 38.8347 42.4069C39.0076 42.4871 39.1414 42.6325 39.2067 42.8111C39.2721 42.9897 39.2635 43.1868 39.183 43.3591C39.1024 43.5314 38.9565 43.6648 38.7773 43.7298Z" fill="#C0D921"/>
    <path d="M55.6449 40.7259L32.7096 49.056C32.6208 49.0883 32.5265 49.1029 32.4321 49.0989C32.3377 49.095 32.245 49.0725 32.1593 49.0327C32.0736 48.993 31.9966 48.9369 31.9327 48.8675C31.8688 48.7981 31.8192 48.7168 31.7869 48.6283C31.7545 48.5398 31.74 48.4458 31.7441 48.3517C31.7482 48.2576 31.7709 48.1653 31.8108 48.0799C31.8508 47.9946 31.9073 47.9179 31.977 47.8543C32.0467 47.7907 32.1283 47.7414 32.2171 47.7093L55.1525 39.3793C55.2412 39.347 55.3354 39.3325 55.4298 39.3366C55.5241 39.3407 55.6167 39.3632 55.7023 39.403C55.7879 39.4427 55.8649 39.4988 55.9287 39.5682C55.9925 39.6376 56.042 39.7188 56.0744 39.8072C56.1067 39.8956 56.1212 39.9895 56.1172 40.0836C56.1131 40.1776 56.0905 40.2699 56.0506 40.3552C56.0107 40.4405 55.9544 40.5172 55.8848 40.5808C55.8152 40.6444 55.7337 40.6937 55.6449 40.7259Z" fill="#C0D921"/>
    <path d="M56.8006 43.8858L33.8653 52.2158C33.7765 52.248 33.6823 52.2625 33.588 52.2585C33.4936 52.2544 33.401 52.2319 33.3154 52.1921C33.2298 52.1524 33.1529 52.0962 33.089 52.0269C33.0252 51.9575 32.9757 51.8763 32.9434 51.7879C32.911 51.6994 32.8965 51.6055 32.9006 51.5115C32.9047 51.4175 32.9273 51.3252 32.9672 51.2399C33.007 51.1546 33.0634 51.0779 33.133 51.0143C33.2026 50.9507 33.2841 50.9014 33.3728 50.8691L56.3081 42.5391C56.4873 42.4743 56.6849 42.483 56.8576 42.5633C57.0303 42.6436 57.1639 42.7889 57.2292 42.9674C57.2944 43.1458 57.286 43.3427 57.2056 43.515C57.1252 43.6872 56.9796 43.8205 56.8006 43.8858Z" fill="#C0D921"/>
    <path d="M57.9567 47.0462L35.0214 55.3763C34.9326 55.4086 34.8383 55.4232 34.7439 55.4193C34.6495 55.4153 34.5568 55.3928 34.4711 55.3531C34.3854 55.3133 34.3084 55.2572 34.2445 55.1878C34.1806 55.1184 34.131 55.0371 34.0986 54.9486C34.0663 54.8601 34.0517 54.7662 34.0559 54.6721C34.06 54.578 34.0827 54.4856 34.1226 54.4002C34.1626 54.3149 34.219 54.2382 34.2887 54.1746C34.3584 54.111 34.44 54.0617 34.5289 54.0296L57.4642 45.6996C57.553 45.6674 57.6472 45.6529 57.7415 45.6569C57.8359 45.661 57.9285 45.6835 58.0141 45.7233C58.0997 45.763 58.1766 45.8192 58.2405 45.8885C58.3043 45.9579 58.3538 46.0391 58.3861 46.1275C58.4185 46.2159 58.433 46.3098 58.4289 46.4039C58.4248 46.4979 58.4022 46.5902 58.3624 46.6755C58.3225 46.7608 58.2661 46.8375 58.1965 46.9011C58.1269 46.9647 58.0454 47.014 57.9567 47.0462Z" fill="#C0D921"/>
    <path d="M59.1129 50.2103L36.1775 58.5403C35.9983 58.6054 35.8005 58.5969 35.6276 58.5166C35.4547 58.4364 35.3209 58.291 35.2556 58.1124C35.1903 57.9338 35.1989 57.7367 35.2794 57.5644C35.3599 57.3921 35.5058 57.2587 35.685 57.1937L58.6204 48.8636C58.7091 48.8314 58.8034 48.8169 58.8977 48.821C58.9921 48.825 59.0847 48.8476 59.1703 48.8873C59.2559 48.9271 59.3328 48.9832 59.3966 49.0526C59.4605 49.1219 59.51 49.2032 59.5423 49.2916C59.5746 49.38 59.5892 49.4739 59.5851 49.5679C59.581 49.662 59.5584 49.7543 59.5185 49.8396C59.4786 49.9249 59.4223 50.0015 59.3527 50.0651C59.2831 50.1288 59.2016 50.1781 59.1129 50.2103Z" fill="#C0D921"/>
    <path d="M131.887 58.9871L119.329 88.9339C118.794 90.2011 117.779 91.2075 116.506 91.736C115.232 92.2646 113.8 92.2728 112.521 91.7589C111.859 91.4931 111.258 91.0956 110.755 90.5909C110.252 90.0861 109.857 89.4845 109.595 88.8228C109.333 88.1611 109.209 87.4531 109.231 86.7421C109.252 86.0311 109.419 85.3318 109.72 84.687L123.721 53.9781L131.439 34.9608C130.772 34.2979 130.326 33.4465 130.162 32.5221C129.997 31.5978 130.122 30.6455 130.52 29.7944C130.918 28.9434 131.568 28.235 132.384 27.7654C133.2 27.2958 134.141 27.0879 135.079 27.1698C136.017 27.2517 136.908 27.6195 137.629 28.2232C138.35 28.8269 138.868 29.6373 139.111 30.5442C139.354 31.4512 139.311 32.4107 138.988 33.2925C138.664 34.1742 138.077 34.9354 137.304 35.4727L131.887 58.9871Z" fill="#FFD0B6"/>
  </g>
  <defs>
    <clipPath id="clip0_5644_2068">
      <rect width="160" height="180.966" fill="white"/>
    </clipPath>
  </defs>
</svg>
  )
}

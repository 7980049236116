import React from 'react'
import { Flex, Skeleton } from '@chakra-ui/react';
const CustomSkeleton = () => {
    return (
        <Flex
            px={'25px'}
            py={'16px'}
            gap={'20px'}
            h={{ base: '140px', md: '90px' }}
            bg={'gray.200'}
            borderRadius={'16px'}
            border={'8px solid #EDF2F7'}
            alignItems={'center'}
            w={{ base: '100%', md: '1136px' }}

        >
            <Skeleton
                w='100%'
                h={'100%'}
                borderRadius={'16px'}
             
            >
            </Skeleton>
        </Flex>
    )
}
export default function LevelCardSekeltons() {
    return (
        <>
            <CustomSkeleton />
            <CustomSkeleton />
            <CustomSkeleton />
            <CustomSkeleton />
            <CustomSkeleton />
        </>
    )
}

import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";


var ppm;
getDoc(doc(db, "constants", "points_per_mock")).then((docsnap) => {
    ppm = docsnap.data().points;
})

var level_name_map, level_point_map;
getDoc(doc(db, "constants", "levels")).then((docsnap) => {
    const levelArr = Object.entries(docsnap.data());
    level_name_map = new Map(levelArr.map((value)=>[value[1].level, value[0]]));
    level_point_map = new Map(levelArr.map((value)=>[value[1].level, value[1].threshold]));
    level_point_map = new Map([...level_point_map].sort())
})


export {ppm, level_name_map, level_point_map}
import { useCheckbox, chakra, Text, Wrap, WrapItem } from "@chakra-ui/react";

function CustomCheckbox(props) {
  const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } =
    useCheckbox(props)
  const fontSize = props.size === "lg" ? "16px" : props.size === "sm" ? "14px" : "12px"
  const lineHeight = props.size === "lg" ? "24px" : props.size === "sm" ? "20px" : "16px"
  const px = props.size === "lg" ? "12px" : props.size === "sm" ? "8px" : "6px"
  return (
    <chakra.label
      bg={state.isChecked?'gray.300':'gray.200'}
      borderRadius={"6px"}
      px={px}
      py={"8px"}
      cursor='pointer'
      {...htmlProps}
    >
      <input {...getInputProps()} hidden />
      <Text fontSize={fontSize} lineHeight={lineHeight} fontWeight={"500"} color={state.isChecked?"gray.600":"blackAlpha.400"} {...getLabelProps()} {...getCheckboxProps()}>{props.value}</Text>
    </chakra.label>
  )
}

function CheckboxComponent({ cases, func, size}) {
  return(
    <>
      <Wrap spacing={'10px'}>
      {cases.map((casetype)=>{
        return <WrapItem key={casetype}><CustomCheckbox {...func({ value: `${casetype}` })} key={casetype} size={size}/></WrapItem>
      })}
      </Wrap>
    </>
)
}

export default CheckboxComponent;

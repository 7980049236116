import { useState, useEffect } from "react";
import { doc, setDoc } from "firebase/firestore";
import {
  Box,
  Button,
  FormControl,
  Img,
  Input,
  Text,
  Textarea,
  FormErrorMessage,
  Square,
  Image,
  Center,
  Show,
  Flex,
  Tooltip,
  useDisclosure
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import source from "../static/images/profileinfoIllustration.svg";
import logo from '../static/images/Pearmock logo.svg'
import { Select, CreatableSelect } from "chakra-react-select";
import {
  education,
  graduationYears,
  Experience,
} from "../constants/profileOptions";
import { db } from '../firebase'
import baseUrl from '../constants/urls'
import { LinkedinIcon } from '../static/icons/LinkedinIcon'
import { useAuth } from '../context/AuthContext'
import Loader from "../static/Loader";
import { amplitude } from "../analytics/analytics";

function Profileinfo() {
  const { currentUser, userStatus } = useAuth()
  const [pageLoad, setpageLoad] = useState(true);
  const linkedinregex = /((https?:\/\/)((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))/
  const [loading, setloading] = useState(false)
  const navigate = useNavigate();
  const [linkedinUrl, setlinkedinUrl] = useState('')
  const [linkedinstate, setlinkedinstate] = useState(false)
  const [bio, setbio] = useState('')
  const [biostate, setbiostate] = useState(true)
  const [targetCompanies, settargetCompanies] = useState([])
  const [companystate, setcompanystate] = useState(true)
  const [workExperience, setworkExperience] = useState('')
  const [experiencestate, setexperiencestate] = useState(false)
  const [currentJobRole, setcurrentJobRole] = useState('')
  const [currentComapny, setcurrentComapny] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneNumberError, setPhoneNumberError] = useState(false)
  const [city, setCity] = useState('');
  const [cityError, setCityError] = useState(false)
  const [educationDegree, seteducationDegree] = useState('')
  const [degreestate, setdegreestate] = useState(true)
  const [educationCollegeName, seteducationCollegeName] = useState('')
  const [collegestate, setcollegestate] = useState(false)
  const [graduationYear, setgraduationYear] = useState('')
  const [graduationstate, setgraduationstate] = useState(false)
  var formstate = linkedinstate || biostate || companystate || experiencestate || degreestate || collegestate || graduationstate
  const [firstname, lastname] = currentUser ? currentUser.displayName.split(" ") : [null, null]
  const userInfo = {
    firstName: firstname,
    lastName: lastname?lastname:'',
    email: currentUser ? currentUser.email : null,
    avatarUrl: currentUser ? currentUser.photoURL : null,
    userStatus: 'submitted',
    upcomingMocks: {},
    createdMocks: {},
    linkedinUrl: linkedinUrl,
    bio: bio,
    targetCompanies: targetCompanies,
    workExperience: workExperience.value,
    currentJobRole: currentJobRole,
    currentCompany: currentComapny,
    educationDegree: null,
    educationCollegeName: educationCollegeName,
    graduationYear: graduationYear.value,
    isAdmin: false,
    phoneNumber: phoneNumber,
    city: city,
    mocksCompleted: 0,
    noShowups: 0,
    cancelledMocks: 0,
    pastMocks: 0
  }

  const { isOpen: isTollTipOpen, onOpen: onTollTipOpen, onToggle: onTollTipToggle, onClose: onTollTipClose } = useDisclosure();

  // function targetcompanies(options) {
  //   var target = [];
  //   options.map((doc) => target.push(doc.value));
  //   settargetCompanies(target);
  // }

  function validate() {
    // if (educationDegree === "") {
    //   setdegreestate(true);
    // } else {
    //   setdegreestate(false);
    // }
    if (educationCollegeName === "") {
      setcollegestate(true);
    } else {
      setcollegestate(false);
    }
    if (phoneNumber === '' || phoneNumber.trim().length !== 10)
      setPhoneNumberError(true)
    else setPhoneNumberError(false)
    if (city === '' || city.trim().length === 0)
      setCityError(true)
    else setCityError(false)
    if (graduationYear === "") {
      setgraduationstate(true);
    } else {
      setgraduationstate(false);
    }
    if (workExperience === "") {
      setexperiencestate(true);
    } else {
      setexperiencestate(false);
    }
    // if (targetCompanies.length === 0) {
    //   setcompanystate(true);
    // } else {
    //   setcompanystate(false);
    // }
    if (linkedinUrl === "" || !linkedinregex.test(linkedinUrl)) {
      setlinkedinstate(true);
    } else {
      setlinkedinstate(false);
    }
    // if (bio === "" || !/\S/.test(bio)) {
    //   setbiostate(true);
    // } else {
    //   setbiostate(false);
    // }
  }

  useEffect(() => {
    if (userStatus !== null) {
      if (userStatus === 'submitted') {
        navigate(`${baseUrl}submitted`)
      }
      else if (userStatus === 'unverified') {
        setpageLoad(false)
      }
      else if (userStatus === 'verified') {
        navigate(`${baseUrl}dashboard`)
      }
    }
    if (!localStorage.getItem('user')) {
      navigate(`${baseUrl}`)
    }


    return () => {
      setpageLoad(true)
    }
  }, [localStorage.getItem('userStatus'), userStatus]);




  return (
    <>
      {pageLoad ? (
        <Loader />
      ) : (
        <>
          <Box
            h={"72px"}
            bg={"white"}
            boxShadow={"0px 2px 15px rgba(0, 0, 0, 0.05)"}
            position={"sticky"}
            py={"10px"}
            zIndex={500}
          >
            <Image
              src={logo}
              w={"150px"}
              h={"50px"}
              ml={"32px"}
              cursor={"pointer"}
              onClick={() => {
                navigate(`${baseUrl}profileinfo`);
              }}
            />
          </Box>
          <Center
            justifyContent={"space-between"}
            alignItems={"top"}
            px={["36px", "40px", "170px", "56px", "72px", "200px"]}
            mt={{ base: "56px", sm: "72px" }}
          >
            <Box w={{ base: "100%", sm: "424px" }}>
              <Text
                fontSize={"30px"}
                lineHeight={"36px"}
                fontWeight={"600"}
                mb={"24px"}
              >
                Almost there, {firstname}!{" "}
              </Text>
              <Text
                fontSize={"14px"}
                lineHeight={"20px"}
                fontWeight={"500"}
                mb={"24px"}
                color={"blackAlpha.800"}
              >
                Hey! 👋 Thanks for signing up. Fill out this short form and get
                ready to start your interview preparation.{" "}
              </Text>
              <Show breakpoint="(max-width: 768px)">
                <Center>
                  <Img
                    src={source}
                    w={["274px", "274px", "395px", "395px", "624px", "624px"]}
                    h={["187px", "187px", "269px", "269px", "425px", "425px"]}
                    mb={'60px'}
                  />
                </Center>
              </Show>
              <FormControl isInvalid={formstate}>
                <Input
                  type={"text"}
                  defaultValue={firstname}
                  w={{ base: "full", sm: "180px" }}
                  mb={{ base: "16px", sm: "0px" }}
                  isDisabled
                  isInvalid={false}
                />
                <Input
                  type={"text"}
                  defaultValue={lastname}
                  w={{ base: "full", sm: "180px" }}
                  ml={{ base: "0px", sm: "8px" }}
                  isDisabled
                  isInvalid={false}
                />
                <Input
                  type={"email"}
                  defaultValue={currentUser.email}
                  mt={"16px"}
                  isDisabled
                  isInvalid={false}
                />
                {/* <Box mt={"16px"}>
                  <Select
                    options={education}
                    placeholder={"Highest education"}
                    tabSelectsValue={false}
                    onChange={seteducationDegree}
                    isInvalid={degreestate}
                  />
                  {degreestate ? (
                    <FormErrorMessage>
                      Please select your degree
                    </FormErrorMessage>
                  ) : null}
                </Box> */}
                <Box mt={"16px"}>
                  <Input
                    placeholder={"Type your University/College name"}
                    onChange={(e) => { seteducationCollegeName(e.target.value) }}
                    isInvalid={collegestate}
                  />
                  {collegestate ? (
                    <FormErrorMessage>
                      Please select your college
                    </FormErrorMessage>
                  ) : null}
                </Box>
                <Box mt={"16px"}>
                  <Select
                    options={graduationYears}
                    placeholder={"Graduation year"}
                    tabSelectsValue={false}
                    onChange={setgraduationYear}
                    isInvalid={graduationstate}
                  />
                  {graduationstate ? (
                    <FormErrorMessage>
                      Please select your graduation year
                    </FormErrorMessage>
                  ) : null}
                </Box>
                <Box mt={"16px"}>
                  <Select
                    options={Experience}
                    placeholder={"Work experience"}
                    tabSelectsValue={false}
                    onChange={setworkExperience}
                    isInvalid={experiencestate}
                  />
                  {experiencestate ? (
                    <FormErrorMessage>
                      Please select your work experience
                    </FormErrorMessage>
                  ) : null}
                </Box>
                <Input
                  type={"text"}
                  placeholder={"Current job role (optional)"}
                  mt={"16px"}
                  onChange={(e) => setcurrentJobRole(e.target.value)}
                  isInvalid={false}
                />
                <Input
                  type={"text"}
                  placeholder={"Current company (optional)"}
                  mt={"16px"}
                  onChange={(e) => setcurrentComapny(e.target.value)}
                  isInvalid={false}
                />
                <Input
                  type={"text"}
                  mt={"16px"}
                  placeholder={"City"}
                  isRequired
                  onChange={(e) => setCity(e.target.value)}
                  isInvalid={cityError}
                />
                {cityError ? (
                  <FormErrorMessage>
                    Please enter your city
                  </FormErrorMessage>
                ) : null}
                <Box w={'100%'}>
                  <Flex
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    mt={"16px"}
                  >
                    <Input
                      type={"number"}
                      placeholder={"Phone Number"}
                      isRequired
                      name="phoneNumber"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      isInvalid={phoneNumberError}
                      value={phoneNumber}
                    />

                    <Tooltip
                      hasArrow
                      bg={'gray.900'}
                      color={'white'}
                      p={'8px'}
                      isOpen={isTollTipOpen}
                      label='Only exposed with people you connect with and only for 1.5 hours before the call.'
                    >
                      <Flex
                        w={'40px'}
                        h={'40px'}
                        borderRadius={'full'}
                        border={'2px solid #E2E8F0'}
                        flexShrink={0}
                        alignItems={'center'}
                        justifyContent={'center'}
                        onMouseEnter={onTollTipOpen}
                        onMouseLeave={onTollTipClose}
                        onClick={onTollTipToggle}
                        cursor={'pointer'}
                      >
                        <Text
                          fontWeight={500}
                          color={'gray.500'}
                          fontSize={'23px'}
                        >
                          i
                        </Text>
                      </Flex>
                    </Tooltip>
                  </Flex>
                  {phoneNumberError ? (
                    <FormErrorMessage>
                      Please enter your phone number
                    </FormErrorMessage>
                  ) : null}
                </Box>


                {/* <Box mt={"16px"}>
                  <CreatableSelect
                    isMulti
                    placeholder={"Enter all your Target company(ies)"}
                    tabSelectsValue={false}
                    onChange={targetcompanies}
                    closeMenuOnSelect={false}
                    isInvalid={companystate}
                  />
                  {companystate ? (
                    <FormErrorMessage>
                      Please select some company(ies)
                    </FormErrorMessage>
                  ) : null}
                </Box> */}
                <Box display={"inline-flex"} mt={"16px"} justifyContent={'space-between'} width={'100%'}>
                  <Input
                    type={"text"}
                    w={{ base: "100%", sm: "376px" }}
                    placeholder={"Include https in Linkedin profile Url"}
                    onChange={(e) => setlinkedinUrl(e.target.value)}
                    isInvalid={linkedinstate}
                  />
                  <Square
                    w={"40px"}
                    h={"40px"}
                    ml={"8px"}
                    border={"1px solid #E2E8F0"}
                    borderRadius={"7px"}
                    cursor
                  >
                    <LinkedinIcon
                      w={"40px"}
                      h={"40px"}
                      cursor={"pointer"}
                      onClick={(e) => {
                        window.open("https://www.linkedin.com");
                      }}
                    />
                  </Square>
                </Box>
                {linkedinstate ? (
                  <FormErrorMessage>
                    Please enter your linkedin url or check if its correct
                  </FormErrorMessage>
                ) : null}

                {/* <Textarea
                  placeholder={"An awesome bio"}
                  mt={"16px"}
                  onChange={(e) => setbio(e.target.value)}
                  isInvalid={biostate}
                  maxLength={50}
                />
                {biostate ? (
                  <FormErrorMessage>
                    Please enter a bio which helps us know about you
                  </FormErrorMessage>
                ) : null} */}
              </FormControl>
              <Button
                isLoading={loading}
                mt={"24px"}
                mb={"104px"}
                width={"full"}
                onClick={() => {
                  setloading(true);
                  validate();
                  if (
                    educationCollegeName !== "" &&
                    graduationYear !== "" &&
                    workExperience !== "" &&
                    linkedinUrl !== "" &&
                    linkedinregex.test(linkedinUrl)
                  ) {
                    const info = {
                      submittedAt: new Date(),
                      ...userInfo,
                    };
                    setDoc(doc(db, "users", currentUser.uid), info, {
                      merge: true,
                    }).then(() => {
                      amplitude.track('profile submitted')
                      navigate(`${baseUrl}submitted`);
                    });
                  } else {
                    setloading(false);
                  }
                }}
              >
                Submit
              </Button>
            </Box>
            <Show breakpoint="(min-width: 769px)">
              <Img
                src={source}
                w={["274px", "274px", "395px", "395px", "624px", "624px"]}
                h={["187px", "187px", "269px", "269px", "425px", "425px"]}
                mt={"60px"}
              />
            </Show>
          </Center>
        </>
      )}
    </>
  );
}

export default Profileinfo;

import React from 'react'

export default function Level1SVG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M29.998 7.7807C42.7557 7.7807 53.098 18.2396 53.098 31.1413C53.098 44.043 42.7557 54.502 29.998 54.502C17.2403 54.502 6.89801 44.0431 6.89801 31.1413C6.89801 18.2395 17.24 7.7807 29.998 7.7807Z" fill="#C03636"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M30.0823 5.40149C42.7934 5.40149 53.0978 15.8205 53.0978 28.6729C53.0978 41.5253 42.7934 51.9443 30.0823 51.9443C17.3712 51.9443 7.06683 41.5253 7.06683 28.6729C7.06683 15.8205 17.3712 5.40149 30.0823 5.40149Z" fill="#E05F5F"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M30.2358 10.67C40.154 10.67 48.1943 18.7667 48.1943 28.7545C48.1943 38.7423 40.1543 46.8391 30.2358 46.8391C20.3173 46.8391 12.2774 38.742 12.2774 28.7541C12.2774 18.7662 20.3177 10.67 30.2358 10.67Z" fill="#D04545"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M30.7668 38.368C30.7668 38.368 30.012 31.9689 36.255 31.5029C40.3092 31.2004 41.8009 29.0779 41.6899 30.6007C41.5789 32.1235 38.0716 42.3913 30.7668 38.368Z" fill="#C02E2E"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M29.6348 38.2589C29.6348 38.2589 30.3964 31.7918 24.0948 31.3205C20.0022 31.0145 18.4963 28.8694 18.6084 30.4085C18.7205 31.9476 22.2612 42.3246 29.6348 38.2589Z" fill="#C02E2E"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M30.0016 32.0991C26.3961 31.9991 20.5902 25.7083 29.5596 16.2C39.8482 25.1027 33.607 32.1994 30.0016 32.0991Z" fill="#F57474"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M28.9362 31.4492C27.0604 29.8154 26.7262 23.9208 35.6702 22.7892C37.2461 32.04 30.8121 33.0831 28.9362 31.4492Z" fill="#FC6666"/>
  <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M30.0823 5.40149C42.7934 5.40149 53.0978 15.8205 53.0978 28.6729C53.1047 31.7627 52.4977 34.8231 51.312 37.6764C50.1264 40.5296 48.3857 43.119 46.1911 45.294C13.9758 12.264 46.1986 45.2409 13.8881 12.1368C16.0097 10.0013 18.5328 8.30679 21.3122 7.15083C24.0915 5.99487 27.0722 5.40034 30.0823 5.40149Z" fill="#FFFFF0"/>
</svg>
  )
}

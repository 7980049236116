import { Flex, Box, Text, Avatar, Link, Progress, Image } from '@chakra-ui/react';
import Levels from './Levels';
import Level1Badge from '../../static/badges/level1.svg';
import Level2Badge from '../../static/badges/level2.svg';
import Level3Badge from '../../static/badges/level3.svg';
import Level4Badge from '../../static/badges/level4.svg';
import Level5Badge from '../../static/badges/level5.svg';
const Badges = [Level1Badge, Level2Badge, Level3Badge, Level4Badge, Level5Badge];
const LevelNames = ['Budding Pear', 'Green Pear', 'Ripening Pear', 'Golden Pear', 'Pearfect']
const findProgress = (value) => {
    return Math.round((value / 600) * 100);

}
const ProgressContainer = ({ value, levelMock }) => {
    return (
        <Flex
            width={'100%'}
            flexDirection={'column'}
            gap={'16px'}
        >
            <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                gap={{base: '8px', md: '16px'}}
                alignItems={{base:'flex-start', md: 'center'}}
            >
            <Text
                fontSize={{ base: '20px', md: '32px' }}
                fontWeight={800}
                color={'#4C560D'}
            >
                {levelMock} points
            </Text>
            <Text
                 fontSize={{ base: '14px', md: '18px' }}
                 fontWeight={400}
                 color={'#4C560D'}
            >
                out of 600 total
            </Text>
            </Flex>

            <Progress value={value} colorScheme='red' bg={'red.100'} borderRadius={'4px'} />

        </Flex>
    )
}
const ConnectionLinksContainer = ({ linkedinUrl, email }) => {
    return (
        <Flex
            gap={2}
        >
            <Link
                href={linkedinUrl}
                isExternal
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <g clipPath="url(#clip0_5735_18006)">
                        <path d="M16 0C7.1625 0 0 7.1625 0 16C0 24.8375 7.1625 32 16 32C24.8375 32 32 24.8375 32 16C32 7.1625 24.8375 0 16 0ZM25.9875 25.9813C24.6875 27.2812 23.1812 28.3 21.5 29.0062C19.7625 29.7437 17.9125 30.1125 16 30.1125C14.0938 30.1125 12.2437 29.7375 10.5 29.0062C8.81875 28.2937 7.30625 27.275 6.0125 25.9813C4.7125 24.6813 3.69375 23.175 2.9875 21.4937C2.25 19.7563 1.875 17.9062 1.875 16C1.875 14.0938 2.25 12.2437 2.98125 10.5C3.69375 8.81875 4.7125 7.30625 6.00625 6.0125C7.3125 4.7125 8.81875 3.69375 10.5 2.98125C12.2437 2.25 14.0938 1.875 16 1.875C17.9062 1.875 19.7563 2.25 21.5 2.98125C23.1812 3.69375 24.6937 4.7125 25.9875 6.00625C27.2875 7.30625 28.3063 8.8125 29.0125 10.4937C29.75 12.2312 30.1187 14.0812 30.1187 15.9937C30.1187 17.9 29.7438 19.75 29.0125 21.4937C28.3 23.175 27.2812 24.6875 25.9875 25.9813Z" fill="#80921A" />
                        <path d="M11.525 12.7125H8.40625V22.6938H11.525V12.7125Z" fill="#80921A" />
                        <path d="M9.89999 8C8.74999 8 7.99374 8.74375 7.99374 9.73125C7.99374 10.7 8.72499 11.4625 9.85624 11.4625H9.88124C11.0562 11.4625 11.7875 10.6938 11.7812 9.73125C11.7562 8.74375 11.05 8 9.89999 8Z" fill="#80921A" />
                        <path d="M20.0437 12.6188C18.2562 12.6188 17.1375 13.5938 16.9312 14.2813V12.7125H13.425C13.4687 13.5438 13.425 22.6938 13.425 22.6938H16.9312V17.3C16.9312 16.9938 16.9187 16.6938 17.0062 16.4813C17.2437 15.8813 17.7625 15.2563 18.6937 15.2563C19.9125 15.2563 20.4625 16.1813 20.4625 17.5313V22.6938H24V17.1438C24 14.0563 22.2625 12.6188 20.0437 12.6188Z" fill="#80921A" />
                    </g>
                    <defs>
                        <clipPath id="clip0_5735_18006">
                            <rect width="32" height="32" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </Link>
            <Link
                href={`mailto:${email}`}
                isExternal
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <g clipPath="url(#clip0_5735_18012)">
                        <path d="M23.492 11.5004V11.5C23.492 11.4802 23.4913 11.4605 23.49 11.4408L22.6528 11.9314L16.2528 15.6814L16 15.8295L15.7472 15.6814L9.34723 11.9314L8.50233 11.4364C8.50078 11.4575 8.5 11.4787 8.5 11.5V20.5C8.5 21.019 8.96525 21.5 9.6 21.5H22.4C23.0347 21.5 23.4999 21.0191 23.5 20.5002C23.5 20.5001 23.5 20.5001 23.5 20.5L23.492 11.5004ZM22.4 11H23.3438C23.1592 10.7083 22.8168 10.5 22.4 10.5H9.6C9.18165 10.5 8.83693 10.709 8.6505 11H9.6H22.4ZM22.1472 12.5686L22.9 12.1275V13V20.5V21H22.4H9.6H9.1V20.5V13V12.1275L9.85277 12.5686L16 16.1705L22.1472 12.5686Z" fill="#80921A" stroke="#80921A" />
                        <circle cx="16" cy="16" r="15" stroke="#80921A" strokeWidth="2" />
                    </g>
                    <defs>
                        <clipPath id="clip0_5735_18012">
                            <rect width="32" height="32" fill="white" transform="matrix(-1 0 0 1 32 0)" />
                        </clipPath>
                    </defs>
                </svg>
            </Link>
        </Flex>
    )
}
const RatingContainer = ({ avgRating, level }) => {
    return (
        <Flex
            gap={'12px'}
            flexDirection={{ base: 'column', md: 'row' }}
        >
            <Text
                fontSize={{ base: '20px', md: '24px' }}
                fontWeight={500}

            >
                {LevelNames[level - 1]}
            </Text>
            {/* <Flex

                alignItems={'center'}
                justifyContent={'center'}
                gap={'8px'}
                bg={'#4C560D'}
                p={'8px'}
                borderRadius={'8px'}
                color={'white'}
                w={'72px'}
                h={'36px'}
                fontWeight={700}
            >
                {avgRating}.0
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
                    <path d="M10.5263 16.0737L17.0316 20L15.3053 12.6L21.0526 7.62105L13.4842 6.97895L10.5263 0L7.56842 6.97895L0 7.62105L5.74737 12.6L4.02105 20L10.5263 16.0737Z" fill="#F6E05E" />
                </svg>
            </Flex> */}
        </Flex>
    )
}
const TopPerformerUser = ({ user }) => {

    const value = findProgress(user.levelMock, user.level);


    return (
        <Flex
            w={'100%'}
            h={'auto'}
            border={'4px solid #C0D921'}
            borderRadius={'16px'}
            p={{ base: '12px 12px', md: '32px 48px' }}
            bg={'#FDFFED'}
            flexDir={'column'}
            gap={'48px'}
        >
            <Flex
                gap={{ base: '12px', md: '20px' }}
            >

                {/* avatar */}
                <Box
                    w={'88px'}
                    h={'88px'}
                >

                    <Avatar
                        src={user.photoURL}
                        position={'relative'}
                    >
                        <Image
                            position={'absolute'}
                            w={'48px'}
                            h={'48px'}
                            bottom={'-25px'}
                            right={'-25px'}
                            src={Badges[user.level - 1]}
                        />
                    </Avatar>
                </Box>

                {/* information */}
                <Flex
                    flexDirection={'column'}
                    gap={'40px'}
                >
                    <Flex
                        flexDir={'column'}
                        gap={{ base: '12px', md: '20px' }}
                    >
                        <Text
                            fontSize={{ base: '24px', md: '32px' }}
                            fontWeight={700}
                        >
                            {user.firstName} {user.lastName}
                        </Text>
                        {/* Rating */}
                        <RatingContainer
                            avgRating={user.avgRating}
                            level={user.level}
                        />
                        {/* links */}
                        <ConnectionLinksContainer
                            linkedinUrl={user.linkedinUrl}
                            email={user.email}
                        />
                    </Flex>
                </Flex>
            </Flex>
            <ProgressContainer
                value={value}
                h='20px'
                levelMock={user.levelMock}
            />

        </Flex>

    )
};

export default TopPerformerUser;
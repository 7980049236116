import {Spinner, Center} from '@chakra-ui/react'
function Loader() {
    return (
        <Center height={"100vh"} bg={"gray.200"} zIndex={9999}>
            <Spinner size='xl' color='teal'/>
        </Center>
    )
}

export default Loader

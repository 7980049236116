import {useState,useEffect} from 'react';
import {useAuth} from '../context/AuthContext'
import {useNavigate} from 'react-router-dom'
import Loader from '../static/Loader';
import baseUrl from '../constants/urls';
import Anonymous from '../components/AppBar/Anonymous'
import {Text, Box, Link, Img, Stack, Center, Image} from '@chakra-ui/react'
import source from '../static/images/submitted.svg'
import freeBox from '../static/images/Frame 55 1.svg'

function Submitted() {
  const {userStatus,currentUser} = useAuth()
  const [pageLoad, setpageLoad] = useState(true);
  const [firstname,lastname] = currentUser?currentUser.displayName.split(" "):[null,null]
  const navigate = useNavigate()
  useEffect(() => {
    if(userStatus !== null){
      if(userStatus === 'submitted'){
        setpageLoad(false)
      }
      else if(userStatus === 'unverified'){
        navigate(`${baseUrl}`,{replace:true})
      }
      else if(userStatus === 'verified'){
        navigate(`${baseUrl}dashboard`,{replace:true})
      }
    }
    if(!localStorage.getItem('user')){
      navigate(`${baseUrl}`,{replace:true})
    }
    
    return ()=>{
      setpageLoad(true)
    }
  }, [userStatus]);

  return (
    <>
      {pageLoad ? (
        <Loader />
      ) : (
        <>
          <Anonymous />
          <Center mt={['56px','56px','72px','72px','100px','200px']}>
          <Stack direction={['column-reverse','column-reverse','column-reverse','column-reverse','row','row']} spacing={'40px'}>
          <Box w={{base: "343px", sm:"486px"}}>
            <Text
              fontSize={"36px"}
              lineHeight={"40px"}
              fontWeight={"600"}
              color={"blackAlpha.900"}
              mb={"24px"}
            >
              Great job, {firstname}! 🎉
            </Text>
            <Text
              fontSize={"16px"}
              lineHeight={"24px"}
              fontWeight={"400"}
              color={"blackAlpha.900"}
              mb={"24px"}
            >
              You have successfully submitted your profile information. Our team
              will now verify your profile in the next few hours. You can start
              practicing as soon as your account is verified.
            </Text>
            <Text
              fontSize={"16px"}
              lineHeight={"24px"}
              fontWeight={"400"}
              color={"blackAlpha.900"}
            >
              In case you need any further support, feel free to reach out at{" "}
              <Link
                color={"red"}
                href={"mailto:support@pearmock.com?subject=Help Needed"}
              >
                support@pearmock.com
              </Link>
              . We are always here to serve you.
            </Text>
            <Image src={freeBox} w={'360px'} h={'186px'} mt={'24px'} cursor={'pointer'} onClick={()=>{window.open('https://resources.pearmock.com/solvedcases','_blank')}}/>
          </Box>
          <Img src={source} w={{base: "343px", sm: "548px"}} h={'auto'}/>
          </Stack>
          </Center>
        </>
      )}
    </>
  );
}

export default Submitted;

import { useState } from "react";
import {
  Box,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Divider,
  useToast,
  IconButton,
  Spacer,
  HStack,
  VStack,
  Input,
  Flex,
  Center,
  Stack
} from "@chakra-ui/react";
import Time from "../../common/Time";
import Mockinfo from "../../common/Mockinfo";
import { db } from "../../../../firebase";
import { doc, updateDoc, deleteField } from "firebase/firestore";
import { Delete } from "../../../../static/icons/Delete";
import { ShareIcon } from "../../../../static/icons/ShareIcon";
import { functions } from "../../../../firebase";
import { httpsCallable } from "firebase/functions";
import { amplitude } from "../../../../analytics/analytics";

function Created({ data, user }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: shareIsOpen,
    onOpen: shareOnOpen,
    onClose: shareOnClose,
  } = useDisclosure();
  const toast = useToast();
  const [loading, setloading] = useState(false);
  const [email, setemail] = useState("");

  const timeprops = {
    datetime: data.eventDateTime.toDate(),
  };

  const mockinfo = {
    topHeading: "You want to solve",
    creatorCaseType: data.creatorCaseType,
    bottomHeading: "You will ask",
    willTakeType: data.willTakeType,
  };

  return (
    <>
      <Box
        w={"343px"}
        h={"320px"}
        backgroundColor={"#F2F7D9"}
        borderRadius={"24px"}
        p={"24px"}
        position={"relative"}
      >
        <Mockinfo {...mockinfo} />
        <Divider
          borderColor={"white"}
          borderWidth={"2px"}
          mt={"20px"}
          my={"20px"}
        />
        <Flex>
          <Time {...timeprops} />
          <Spacer />
          <HStack spacing={"16px"} p={"8px"}>
            <IconButton
              icon={<ShareIcon h={"24px"} w={"24px"} color={"#72820D"} />}
              variant={"unstyled"}
              size={"xs"}
              onClick={shareOnOpen}
            />
            <IconButton
              icon={<Delete h={"24px"} w={"24px"} color={"#72820D"} />}
              variant={"unstyled"}
              size={"xs"}
              onClick={onOpen}
            />
          </HStack>
        </Flex>
      </Box>
      {/*Cancel Modal*/}
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius={"16px"}>
          <ModalHeader>Cancel mock</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              fontWeight={"400"}
              fontSize={"16px"}
              lineHeight={"24px"}
              color={"gray.700"}
            >
              Are you sure you want to cancel this mock? You can’t undo this
              action.{" "}
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              variant={"ghost"}
              colorScheme={"gray"}
              display={loading ? "none" : "block"}
              size={"sm"}
              mr={"8px"}
            >
              Close
            </Button>
            <Button
              size={"sm"}
              isLoading={loading}
              onClick={() => {
                setloading(true);
                updateDoc(doc(db, "users", user.uid), {
                  [`createdMocks.${data.eventId}`]: deleteField(),
                }).then(() => {
                  updateDoc(doc(db, "events", data.eventId), {
                    past: "cancelled",
                  }).then(() => {
                    amplitude.track('created mock cancelled',data.eventId)
                    onClose(true);
                    toast({
                      title: "Event Cancelled",
                      status: "success",
                      duration: 2000,
                      isClosable: true,
                    });
                  });
                });
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/*Share Modal*/}
      <Modal size={"xl"} onClose={shareOnClose} isOpen={shareIsOpen} isCentered>
        <ModalOverlay />
        <ModalContent p={"16px"}>
          <ModalHeader
            fontSize={"24px"}
            lineHeight={"32px"}
            fontWeight={"700"}
            color={"gray.900"}
          >
            Invite a partner to join your mock!
          </ModalHeader>
          <ModalBody>
            <Center
              borderRadius={"12px"}
              border={"2px solid #E6E6E6"}
              background={"whiteAlpha.900"}
              p={"16px"}
            >
              <Stack spacing={'16px'} direction={'column'}>
              <Text fontSize={'14px'} lineHeight={'20px'} fontWeight={'400'}>Hi Folks!</Text>
              <Text fontSize={'14px'} lineHeight={'20px'} fontWeight={'400'}>{`I'm looking to sharpen my problem solving skills and thought it would be fun to do some mock interviews. I am available on Pearmock where we can practice together. Interested in joining me? Join the mock interview using the link below ->`}</Text>
              <Text fontSize={'14px'} lineHeight={'20px'} fontWeight={'400'}>{`https://pearmock.com/dashboard?id=${data.eventId}`}</Text>
              </Stack>
            </Center>
            <Button
              size="xs"
              my={"24px"}
              onClick={() => {
                navigator.clipboard
                  .writeText(
                    `Hi Folks!\nI'm looking to sharpen my problem solving skills and thought it would be fun to do some mock interviews. I am available on Pearmock where we can practice together. Interested in joining me? Join the mock interview using the link below \u2192 \nhttps://pearmock.com/dashboard?id=${data.eventId}`
                  )
                  .then(() => {
                    shareOnClose(true);
                    toast({
                      title: "Text Copied Successfully",
                      status: "success",
                      duration: 2000,
                      isClosable: true,
                    });
                  })
                  .catch((error) => {
                    console.error("Failed to copy text to clipboard:", error);
                  });
              }}
            >
              Copy Text
            </Button>
            <VStack
              p={"16px"}
              bg={"gray.50"}
              borderRadius={"16px"}
              spacing={"16px"}
            >
              <Text
                fontSize={"14px"}
                lineHeight={"20px"}
                fontWeight={"400"}
                color={"gray.700"}
              >
                💡 You can invite any partner of your choice to join this mock
                interview. They can be a current user of Pearmock or someone
                totally new!
              </Text>
              <Text
                fontSize={"14px"}
                lineHeight={"20px"}
                fontWeight={"400"}
                color={"gray.700"}
              >
                💡 Users can join a created session on a first come basis.
                Hence, we advice that you ask your partner to join the mock
                quickly so that someone else does not join it.
              </Text>
            </VStack>
            {/* <HStack mt={"24px"}>
              <Input
                w={"320px"}
                type="email"
                placeholder="Enter a google account email address"
                onChange={(e) => {
                  setemail(e.target.value);
                }}
              />
              <Spacer />
              <Button
                size="md"
                onClick={() => {
                  const sendemail = httpsCallable(functions, "sendemail");
                  sendemail({
                    to: `${email}`,
                    subject: `${data.creatorFirstName} invites you to Pearmock`,
                    text: `Mock Link: http://pearmock.com/dashboard?id=${data.eventId}`,
                    html: `Hey there!,
                    <br/>
                    ${data.creatorFirstName} has invited you to join their mock interview to practice product management cases. 
                    <br/>
                    Please sign in to pearmock.com from your browser and then click the button below to join the mock interview.
                    <br/>
                    <a href="http://pearmock.com/dashboard?id=${data.eventId}">Join Mock</a>
                    <br/>
                    💡 Pro tip: Partners join the mock on first come first serve basis. Join the mock fast so that someone else does not join this same mock!
                    <br/>
                    Sent with ❤️ from Pearmock
                    `,
                  });
                  shareOnClose(true);
                  toast({
                    title: "Mock Shared Successfully!",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                  });
                }}
              >
                Send Invitation
              </Button>
            </HStack> */}
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={shareOnClose}
              variant={"outline"}
              colorScheme={"gray"}
              size={"md"}
            >
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Created;

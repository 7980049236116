
import React from 'react';
import { useAuth } from '../context/AuthContext'
import Loader from "../static/Loader";
import Authenticated from "../components/AppBar/Authenticated";
import { Heading, Box } from '@chakra-ui/react';
import LevelCards from '../components/Learboard/LevelCards';
import NetworkingZone from '../components/Learboard/Networking/NetworkingZone';
import Footer from '../components/footer/Footer';
const LeaderBoard = () => {
    const [loading, setLoading] = React.useState(true);
    const { currentUser, user, userStatus } = useAuth();

    if (loading && !user && userStatus !== 'verified')
        return <Loader />;
    return (
        <>
            <Authenticated setloading={setLoading} />
            <Box
                px={{ base: '20px', md: '200px' }}
                py={{ base: '20px', md: '60px' }}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Heading
                    fontSize={'48px'}
                    fontWeight={700}
                    color={'gray.700'}
                    alignSelf={'flex-start'}
                >
                    Leaderboard
                </Heading>
                <LevelCards/>
                <NetworkingZone
                    user={user}   
                />
            </Box>
            <Footer />
        </>
    )
};
export default LeaderBoard;

import React from 'react'
import { Button, Flex, Image, Text, VStack } from '@chakra-ui/react'
import NetworkingLockImage from '../../../static/images/networking_lock.png'
import { Link } from 'react-router-dom'
export default function NetworkingLock() {
    return (
        <Flex
            w={'100%'}
            h={'100vh'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
            gap={'60px'}
        >
            <Image
                src={NetworkingLockImage}
                alt={'Networking Lock'}
                w={'340px'}
                h={'212px'}
            />
            <VStack
                w={{ base: '100%', md: '836px' }}
                gap={2}
                textAlign={'center'}
                mt={'40px'}
            >
                <Text
                    fontSize={{base:'14px', md:'20px'}}
                    fontWeight={400}
                >
                    Woops! Out of bounds. Please practice a few problems,
                    develop your product mindset so that when you start networking,
                    you have great things to discuss! Networking Zone is exclusively
                    open for pears above Green Pear Level. Please get your first 60 points quickly and start networking!
                </Text>
                <Text
                    fontSize={{base:'14px', md:'20px'}}
                    fontWeight={400}
                >
                    Networking Zone is exclusively open for pears above Green Pear Level. Please get your first 60 points quickly and start networking!
                </Text>
            </VStack>
            <Button
                colorScheme='red'
                p={'10px 24px'}
                as={Link}
                to={'/dashboard'}
            >
                Join a mock interview
            </Button>

        </Flex>
    )
}
